import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import MessageBoard from './MessageBoard';
import ThreadDetail from './ThreadDetail';
import { UserInteraction } from './UserInteraction';
import { toast } from 'react-toastify';
import { getThreadList, initializeMessageBoard } from '../../libs/db-lib';
import { Tag, Thread, Vote } from './types';
import { Text, TextOnly } from '../../components/Text';
import { TermsAndConditionsMB } from './TermsAndConditionsMB';
import { Loading } from '../../components/Loading';
import './MessageBoard.css'
import config from '../../config';
import { Header } from '../../components/Header';

const MatrixBoard = (props: any) => {
  const shopID = props.currentShop.shopID;
  const userAgreed = props.user.application['MESSAGE_BOARD'].mbTaC === 'AGREED';
  // #region Variables
  const errorReturn = (
    <>
      <Header title={TextOnly('messageBoard')} />
      <p>{TextOnly('errorTryAgain')}</p>
    </>
  )
  //#endregion

  // #region State Variables
  const [allTags, setAllTags] = useState<Tag[] | null>();

  const [featuredThreadList, setFeaturedThreadList] = useState<Thread[] | null>();

  const [userVotes, setUserVotes] = useState({});
  const [mainNewestThreadList, setMainNewestThreadList] = useState<Thread[]>(
    []
  );
  const [lekNewest, setLekNewest] = useState<any>(null);
  const [mainRatedThreadList, setMainRatedThreadList] = useState<Thread[]>([]);
  const [lekRated, setLekRated] = useState<any>(null);
  const [mainNotedThreadList, setMainNotedThreadList] = useState<Thread[]>([]);
  const [lekNoted, setLekNoted] = useState<any>(null);

  const [searchThread, setSearchThread] = useState<string>('');
  const [filteredThreads, setFilteredThreads] = useState<Thread[]>([]);
  const [refetchThreadList, setRefetchThreadList] = useState<boolean>(false);
  const [threadList, setThreadList] = useState<Thread[]>([]);
  const [threadQuery, setThreadQuery] = useState<string>('time');
  const [lekQuery, setLekQuery] = useState<string>('lekNewest');
  const [isLoadingThread, setIsLoadingThread] = useState<boolean>(false);
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [showNotifyModal, setShowNotifyModal] = useState<boolean>(props.user.application['MESSAGE_BOARD'].notifyUser !== 'NONE');

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [errorInit, setErrorInit] = useState<boolean | null>(null);
  const [errorThreadList, setErrorThreadList] = useState<boolean | null>(null);
  // #endregion

  // #region UseEffects
  const initMB = async () => {
    const response = await initializeMessageBoard(shopID);
    if (!response?.error) {
      const infoMB = response.data;
      //!tags
      const sortedTags = infoMB.tags.sort((a: Tag, b: Tag) =>
        a.name.localeCompare(b.name)
      );
      setAllTags(sortedTags);
      //! userVotes
      const userVotesAux: any = {};
      userVotesAux.list = infoMB.userVotes;
      userVotesAux.list.forEach((vote: Vote) => {
        userVotesAux[vote.contentID] = vote;
      });
      setUserVotes(userVotesAux);
      //! Featured Threads
      setFeaturedThreadList(infoMB.threadsFeat);
      setErrorInit(false);
    } else {
      setFeaturedThreadList(null);
      setAllTags(null);
      setErrorInit(true);
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    initMB();
  }, []);

  const fetchThreads = async () => {
    setIsLoadingThread(true);
    let response;
    switch (threadQuery) {
      case 'likes':
        setLekQuery(lekRated);
        if (lekRated === undefined) {
          console.log('max reached');
          break;
        }
        response = await getThreadList(
          lekRated,
          threadQuery,
          searchThread,
          shopID,
        );
        if (!response?.error) {
          setMainRatedThreadList([...mainRatedThreadList, ...response.threads]);
          setThreadList([...mainRatedThreadList, ...response.threads]);
          setLekRated(response.lastEvaluatedKey);
          setLekQuery(response.lastEvaluatedKey);
          setErrorThreadList(false);
        } else {
          toast.error(
            response.error,
            {
              containerId: 'standard',
              autoClose: false,
            }
          );
          setErrorThreadList(true);
        }
        break;

      case 'time':
        setLekQuery(lekNewest);
        if (lekNewest === undefined) {
          console.log('max reached');
          break;
        }
        response = await getThreadList(
          lekNewest,
          threadQuery,
          searchThread,
          shopID,
        );
        if (!response?.error) {
          setMainNewestThreadList([
            ...mainNewestThreadList,
            ...response.threads,
          ]);
          setThreadList([...mainNewestThreadList, ...response.threads]);
          setLekNewest(response.lastEvaluatedKey);
          setLekQuery(response.lastEvaluatedKey);
          setErrorThreadList(false);
        } else {
          toast.error(
            response.error,
            {
              containerId: 'standard',
              autoClose: false,
            }
          );
          setErrorThreadList(true);
        }
        break;

      default:
        break;
    }
    setIsLoadingThread(false);
    setIsSyncing(false);
  };
  useEffect(() => {
    fetchThreads();
  }, [refetchThreadList]);
  // #endregion

  // #region Handle Functions
  // #endregion

  if (!config.MESSAGE_BOARD_STATE || !(config.MESSAGE_BOARD_STATE === "ACTIVE")) {
    return <Redirect to="/myActivity"></Redirect>;
  }
  else if (isLoading ||
    errorThreadList === null ||
    errorInit === null
    ){
    return <Loading />
  }
  else if (
    errorThreadList === true ||
    errorInit === true
    ){
    return errorReturn;
  }
  else if(userAgreed) {
    return (
      <>
        { props.currentShop?.shopType!=="PLUS" &&
          <div className='temp-banner awsGood'>
            <Text
              tid="mbBaner"
              sub={{
                upgradePlus: (
                  <a
                    href="/upgradeToPlus/comparePlans"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {TextOnly('upgradeToStandardPlus')}
                  </a>
                ),
              }}
            />
          </div>
        }
        <Switch>
          <Route exact path="/messageBoard">
            <MessageBoard
              allTags={allTags}
              featuredThreadList={featuredThreadList}
              user={props.user}
              userVotes={userVotes}
              setUserVotes={setUserVotes}
              showNotifyModal={showNotifyModal}
              setShowNotifyModal={setShowNotifyModal}
              threadFeat={{
                mainNotedThreadList,
                setMainNotedThreadList,
                lekNoted,
                setLekNoted,
                mainNewestThreadList,
                setMainNewestThreadList,
                lekNewest,
                setLekNewest,
                mainRatedThreadList,
                setMainRatedThreadList,
                lekRated,
                setLekRated,
              }}
              stateVar={{
                refetchThreadList,
                setRefetchThreadList,
                threadList,
                setThreadList,
                threadQuery,
                setThreadQuery,
                lekQuery,
                setLekQuery,
                isLoadingThread,
                setIsLoadingThread,
                isSyncing,
                setIsSyncing,
                setIsDeleting,
                searchThread,
                setSearchThread,
                filteredThreads,
                setFilteredThreads,
              }}
              currentShop={props.currentShop}
            />
          </Route>
          <Route path="/messageBoard/userName/:userName?">
            <UserInteraction
              user={props.user}
              userVotes={userVotes}
              setIsDeleting={setIsDeleting}
              setUserVotes={setUserVotes}
              currentShop={props.currentShop}
            />
          </Route>
          <Route path="/messageBoard/thread/:threadID?">
            <ThreadDetail
              user={props.user}
              userVotes={userVotes}
              setUserVotes={setUserVotes}
              threadFeat={{
                mainNotedThreadList,
                setMainNotedThreadList,
                lekNoted,
                setLekNoted,
                mainNewestThreadList,
                setMainNewestThreadList,
                lekNewest,
                setLekNewest,
                mainRatedThreadList,
                setMainRatedThreadList,
                lekRated,
                setLekRated,
              }}
              stateVar={{
                refetchThreadList,
                setRefetchThreadList,
                threadList,
                setThreadList,
                threadQuery,
                setThreadQuery,
                lekQuery,
                setLekQuery,
                isLoadingThread,
                setIsLoadingThread,
                isSyncing,
                setIsSyncing,
                isDeleting,
                setIsDeleting,
              }}
              currentShop={props.currentShop}
            />
          </Route>
        </Switch>
      </>
    );
  } else {
    return <TermsAndConditionsMB currentShop={props.currentShop} user={props.user} updateUserInfo={props.updateUserInfo}/>
  }
};

export default MatrixBoard;
