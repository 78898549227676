import React, { useEffect, useState } from "react";
import { TextOnly } from "../components/Text";
import { ChildProps } from "../types";

import D1PromoImage from "../assets/images/ownerVerificationPromo.png";
import LoaderButton from "../components/LoaderButton";

export default function VehicleOwnerVerificationVerifyIdentity(props: ChildProps) {
  const { history } = props;
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    // Initial setup
    window.addEventListener('resize', handleResize);
    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="position-relative">
      <div className="display-flex-justify-content-center">
        <img
          id="vehicleOwnerVerification"
          className="promo-image"
          src={D1PromoImage}
          alt={TextOnly("shopActions")}
          style={innerWidth < 1400 ? {minWidth: '100%'} : {}}
        />
      </div>
      <div className={`sticky ${innerWidth < 960 ? 'left-0' : 'left-160px'}`}>
        <div className="sticky-text">
          <h1>
            {TextOnly('becomeAutoAuthCertified')}
          </h1>
          <h2>
            {TextOnly('becomeAutoAuthCertifiedToCreateVehicleVerifications')}
          </h2>
          <div className="display-flex-justify-content-center">
            <LoaderButton
              text={TextOnly('userProfile')}
              onClick={()=> history.push(`/userProfile`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
