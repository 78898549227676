import React, { useState } from "react";
import { LangDictKey, Text, TextOnly } from "../components/Text";
import { Dialog } from "@reach/dialog";
import { Header } from "../components/Header";
import { ChildProps } from "../types";

import StatsPromoImage from "../assets/images/statsAndTrendsPromo.png";
import ShopReportsPromo from "../assets/images/shopReportsPromo.png";
import ToolReportsPromo from "../assets/images/toolReportsPromo.png";
import logoPlus from "../assets/images/aa-logo-plus.svg";

export default function StatsAndTrendsPromo(props: ChildProps) {
  const [showPromoModal, setShopPromoModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalTitle, setModalTitle] = useState("statsAndTrends" as LangDictKey);

  function handleImageClick(
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) {
    let target = event?.target as Element;
    switch (target.id) {
      case "statsAndTrends":
        setModalImage(StatsPromoImage);
        setModalTitle("statsAndTrends" as LangDictKey);
        break;
      case "shopReports":
        setModalImage(ShopReportsPromo);
        setModalTitle("shopActions" as LangDictKey);
        break;
      case "toolReports":
        setModalImage(ToolReportsPromo);
        setModalTitle("toolActions" as LangDictKey);
        break;
      default:
        break;
    }
    setShopPromoModal(true);
  }

  function handleCancelModal() {
    setShopPromoModal(false);
  }

  function handleRouteToPlus() {
    props.history.push(`/upgradeToPlus/comparePlans`);
  }

  return (
    <>
      {props.isAuthenticated && (
        <>
          <Header
            context={TextOnly("viewReports")}
            title={TextOnly("statsAndTrends")}
          />

          <div className="l-full-width">
            <div className="c-box c-box--promo-plus">
              <h2 className="c-box__title">
                <Text tid="upgradeToStandardPlus" />
              </h2>
              <div className="l-flex-wrap u-margin-none">
                <p className="u-margin-none">
                  <Text tid="upgradeToStandardPlusDescription" />
                </p>
                <button
                  className="c-box__cta-btn c-btn"
                  onClick={handleRouteToPlus}
                >
                  <Text tid="upgradeNow" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="l-view-layout__promo u-margin-top-xlarge">
        <div className="l-container-sm u-margin-none">
          <div className="c-card u-margin-right-none">
            <div className="c-logo u-margin-top-large">
              <img src={logoPlus} className="c-logo__image" alt="logo" />
            </div>
            <h2 className="c-card__title u-padding-top-large">
              <Text tid="upgradeForStatsAndTrends" />
            </h2>
            <p className="c-card__description">
              <Text tid="givesYouValuableInsights" />:
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              {TextOnly("toolAndUserTotalsAtAGlance").charAt(0).toUpperCase() +
                TextOnly("toolAndUserTotalsAtAGlance").slice(1)}
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              {TextOnly("totalUnlocksOverTime").charAt(0).toUpperCase() +
                TextOnly("totalUnlocksOverTime").slice(1)}
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              {TextOnly("vehiclesComingIntoYourShopInAGivenTimeFrame")
                .charAt(0)
                .toUpperCase() +
                TextOnly("vehiclesComingIntoYourShopInAGivenTimeFrame").slice(
                  1
                )}
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              {TextOnly("whichTechAreDoingMostAndLeastUnlocks")
                .charAt(0)
                .toUpperCase() +
                TextOnly("whichTechAreDoingMostAndLeastUnlocks").slice(1)}
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              {TextOnly("whichToolsAreBeingUsedTheMost")
                .charAt(0)
                .toUpperCase() +
                TextOnly("whichToolsAreBeingUsedTheMost").slice(1)}
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="l-container-med-plus promo-image-shadow u-margin-none">
          <img
            id="statsAndTrends"
            className="promo-image"
            src={StatsPromoImage}
            onClick={handleImageClick}
            alt={TextOnly("shopActions")}
          />
        </div>
      </div>

      {window.location.href.indexOf("/upgradeToPlus/statsAndTrendsPromo") ===
        -1 && (
        <>
          <div className="l-view-layout__promo u-margin-top-xlarge">
            <div className="l-container-sm u-margin-none">
              <div className="c-card u-margin-right-none">
                <h2 className="c-card__title u-padding-top-large">
                  <Text tid="upgradeNowAndGetAccessToShopReport" />
                </h2>
                <p className="c-card__description">
                  <i className="fa fa-plus u-text-success" />{" "}
                  <Text tid="shopReportIsSearchableAndFilterable" />
                  <br />
                  <br />
                  <i className="fa fa-plus u-text-success" />{" "}
                  <Text tid="youCanFindAnyAction" />
                  <br />
                  <br />
                  <i className="fa fa-plus u-text-success" />{" "}
                  <Text tid="seeWhichUsersHaveRespondedToAnInvite" />
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="l-container-med-plus promo-image-shadow u-margin-none">
              <img
                id="shopReports"
                className="promo-image"
                src={ShopReportsPromo}
                onClick={handleImageClick}
                alt={TextOnly("shopActions")}
              />
            </div>
          </div>
          <div className="l-view-layout__promo u-margin-top-xlarge">
            <div className="l-container-sm u-margin-none">
              <div className="c-card u-margin-right-none">
                <h2 className="c-card__title u-padding-top-large">
                  <Text tid="upgradeNowAndGetAccessToToolReport" />
                </h2>
                <p className="c-card__description">
                  <i className="fa fa-plus u-text-success" />{" "}
                  <Text tid="toolReportIsSearchableAndFilterable" />
                  <br />
                  <br />
                  <i className="fa fa-plus u-text-success" />{" "}
                  <Text tid="seeWhichToolsAndTechsAreHavingErrors" />
                  <br />
                  <br />
                  <i className="fa fa-plus u-text-success" />{" "}
                  <Text tid="seeWhichTechsAreUsingToolsAfterHours" />
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="l-container-med-plus promo-image-shadow u-margin-none">
              <img
                id="toolReports"
                className="promo-image"
                src={ToolReportsPromo}
                onClick={handleImageClick}
                alt={TextOnly("shopActions")}
              />
            </div>
          </div>
        </>
      )}
      <Dialog
        isOpen={showPromoModal}
        onDismiss={handleCancelModal}
        className="c-modal--wide"
        aria-label={TextOnly("statsAndTrends")}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__header">
          <Text tid={modalTitle} />
        </h1>
        <div className="c-modal__body l-container">
          <img src={modalImage} alt={TextOnly("shopActions")} />
        </div>
      </Dialog>
    </>
  );
}
