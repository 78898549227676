import React from 'react';
import { TechAvatar } from '../../components/TechAvatar';
import { useUser } from '../../context/User';
import { TextOnly } from '../../components/Text';

const EventCard: React.FC<any> = ({event}) => {

  const userContext = useUser();
  const userLanguage = userContext?.user?.language;
  const { startDate, title, order } = event;

  return (
    <div className={'event-li display-flex flex-space-between'}>
      <div className="display-flex u-flex-expand">
        <div className='event-li-time u-margin-right-xlarge u-font-weight-bold textwrap-nowrap align-self-center l-flex-end'>
          <div>
            {
              new Date(startDate).toLocaleString(userLanguage || 'en', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })
            }
          </div>
        </div>
        <div className='event-li-content u-margin-right'>
          <div className='event-li-header'>
            <span className='h2'>{title}</span>
          </div>
          <div className='event-li-content-bottom flex-space-between'>
            <span>
              <i className="fa fa-file-text" aria-hidden="true"></i> {TextOnly('order')} #{order?.displayOrderNumber}
            </span>
            <span>
              <i className="fa fa-car"></i> {`${order?.vehicle?.make} ${order?.vehicle?.model} ${order?.vehicle?.year}`}
            </span>
            <span>
              <i className="fa fa-user"></i> {`${order?.customer?.firstName} ${order?.customer?.lastName}`}
            </span>
          </div>
        </div>
      </div>
        { order?.technician ?
          <div className='event-li-end'>
            <span className='u-margin-top'><i className="fa fa-wrench"></i></span>
            <div className='event-li-tech-avatar-wrapper'>
              <div className='u-margin-left u-margin-bottom'><TechAvatar tech={order?.technician}/></div>
            </div>
          </div>
        : <div className='event-li-end-empty'></div> }
    </div>
  );
}

export default EventCard;

