import React from 'react';
import aaGiftCard from '../../assets/images/aa-gift-card.svg';
import { Text } from '../../components/Text';

const PromoBox = ({link}) => {
  return (
    <div className="c-box" style={{backgroundColor: '#0088CC'}}>
        <div className="l-flex-between">
            <section className="c-section-alt">
            <div className="c-section-alt__content">
            <img
                src={aaGiftCard}
                alt="gift card icon"
            />
            </div>
            </section>
            <section className="c-section-alt" style={{margin: '0 32px'}}>
            <div className="c-section-alt__content">
                <h2 style={{color: 'white'}}>
                  <Text tid={"helpImproveAutoAuth"} />
                </h2>
            </div>
            </section>
            <section className="c-section-alt">
            <div className="c-section-alt__content">
                <button
                    className="c-btn-full c-btn-outline-bg-white"
                    style={{width: 'auto', padding:'0 25px'}}
                    onClick={()=>window.open(link,'_blank')}
                    >
                <span style={{color: '#0088CC', fontWeight: 400}}>
                    Choose&nbsp;a&nbsp;Time&nbsp;for&nbsp;a&nbsp;Short&nbsp;Interview
                </span>
                </button>
            </div>
            </section>
        </div>
    </div>
  );
};

export default PromoBox;