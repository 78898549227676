import React from 'react';
import { Text, TextOnly } from './Text';
import HelpTooltip from '../components/HelpTooltip';

interface PriceBreakdownProps {
  config: any;
  shop?: any;
  renewalTier: string;
  numberOems?: number;
  signup?: boolean;
}

const PriceBreakdown: React.FC<PriceBreakdownProps> = (props) => {
  const { shop, signup, config, numberOems } = props;
  const prices = config.prices;
  const renewalShopType = props.renewalTier.slice(9).toLowerCase();
  const isDowngrading = shop ? shop.downgradeToStandardPending : false;

  // NEXT RENEWAL PERIOD:

  // Subscription base price
  const subBasePrice = prices[`${renewalShopType}Shop`];

  // OEM values and prices
  const baseOems = 1;
  const numOems = shop ? (shop.oemIDs ? shop.oemIDs.length : 0)
  - (shop.removedOemIDs ? shop.removedOemIDs.length : 0) : numberOems;
  const oemAddons = numOems && (numOems - baseOems) > 0 ? numOems - baseOems : 0;
  const perOemRate = prices.oemRegionAddon;
  const oemUserUpcharge = prices.oemRegionUserUpcharge;

  // User values and prices
  const baseUsers = config.shopBaseValues[`${renewalShopType}BaseUsers`];
  const maxUsers = config.shopMaxValues[`${renewalShopType}MaxUsers`];
  const userAddons = shop && renewalShopType !== 'standard'
    ? shop.shopMaxUsers - baseUsers
    : 0;
  let perUserRate = 0;
  if (renewalShopType !== 'standard') {
    perUserRate = oemAddons
      ? prices[`${renewalShopType}UserAddon`] + (oemUserUpcharge * oemAddons)
      : prices[`${renewalShopType}UserAddon`];
  }

  // Cert values and prices
  const baseCerts = config.shopBaseValues[`${renewalShopType}BaseTechCerts`] || 0;
  const certAddons = shop && isDowngrading ?
    (shop.shopMaxTechCerts - config.shopBaseValues.plusBaseTechCerts) || 0
    : shop && (shop.shopMaxTechCerts - baseCerts) || 0;
  const perCertRate = prices[`${renewalShopType}TechCertAddon`] || 0;

  // Grand total
  const grandTotal = subBasePrice
    + (userAddons * perUserRate)
    + (oemAddons * perOemRate)
    + (certAddons * perCertRate);

  return (
    <div className={maxUsers !== baseUsers ? 'pb-grid' : 'pb-grid-no-users'}>
      <div className='pb-price pb-col-heading'>
        <Text tid="unitPrice"/>
      </div>
      <div className='pb-qty pb-col-heading'>
        <Text tid="quantity"/>
      </div>
      <div className='pb-total pb-col-heading'>
      <Text tid="total"/>
      </div>

      <div className="pb-sub u-font-weight-bold u-text-action-blue-700">
        {props.renewalTier} <Text tid="subscription"/>
      </div>
      <div className="pb-subTotal u-justify-self-center">
        ${subBasePrice.toFixed(2)}
      </div>

      <div className="pb-oems pb-grid-section">
        <div className="pb-section-title u-font-weight-bold u-text-action-blue-700">
          <Text tid="activeOems"/>
        </div>
        <div className="pb-section-included left-padding-20">
          <Text tid="included"/>
        </div>
        <div className="pb-section-price1 u-justify-self-center">
          <Text tid="freeCaps"/>
        </div>
        <div className="pb-section-qty1 u-justify-self-center">
          {baseOems}
        </div>
        <div className="pb-section-total1 u-justify-self-center">
          <Text tid="freeCaps"/>
        </div>
        <div className={!oemAddons ? 'hide-element' : "pb-section-added left-padding-20"}>
          <Text tid="added"/>
        </div>
        <div className={!oemAddons ? 'hide-element' : "pb-section-price2 u-justify-self-center"}>
          ${perOemRate.toFixed(2)}
        </div>
        <div className={!oemAddons ? 'hide-element' : "pb-section-qty2 u-justify-self-center"}>
          {oemAddons}
        </div>
        <div className={!oemAddons ? 'hide-element' : "pb-section-total2 u-justify-self-center"}>
          ${(perOemRate * oemAddons).toFixed(2)}
        </div>
      </div>

      <div className={maxUsers === baseUsers ? 'hide-element' : "pb-usrs pb-grid-section"}>
        <div className="pb-section-title u-font-weight-bold u-text-action-blue-700 l-display-inline-flex l-flex-align-center">
          <div className="right-padding-10">
            <Text tid="shopUsersTools"/>
          </div>
          <HelpTooltip
            label={TextOnly('userUpchargeExplain', {
              base: prices[`${renewalShopType}UserAddon`],
              upcharge: oemUserUpcharge
            })}>
            <i className={!userAddons || !oemAddons ? 'hide-element' : "c-btn-icon fal fa-info-circle"}
            />
          </HelpTooltip>
        </div>
        <div className="pb-section-included left-padding-20">
          <Text tid="included"/>
        </div>
        <div className="pb-section-price1 u-justify-self-center">
          <Text tid="freeCaps"/>
        </div>
        <div className="pb-section-qty1 u-justify-self-center">
          {baseUsers}
        </div>
        <div className="pb-section-total1 u-justify-self-center">
          <Text tid="freeCaps"/>
        </div>
        <div className={!userAddons ? 'hide-element' : "pb-section-added left-padding-20"}>
          <Text tid="added"/>
        </div>
        <div className={!userAddons ? 'hide-element' : "pb-section-price2 u-justify-self-center"}>
          ${perUserRate.toFixed(2)}
        </div>
        <div className={!userAddons ? 'hide-element' : "pb-section-qty2 u-justify-self-center"}>
          {userAddons}
        </div>
        <div className={!userAddons ? 'hide-element' : "pb-section-total2 u-justify-self-center"}>
          ${(perUserRate * userAddons).toFixed(2)}
        </div>
      </div>

      <div className={!baseCerts && !certAddons ? "hide-element" : "pb-certs pb-grid-section"}>
        <div className="pb-section-title u-font-weight-bold u-text-action-blue-700 l-display-inline-flex l-flex-align-center">
          <div className="right-padding-10">
            <Text tid="techCerts"/>
          </div>
        </div>
        <div className={!baseCerts ? "hide-element" : "pb-section-included left-padding-20"}>
          <Text tid="included"/>
        </div>
        <div className={!baseCerts ? "hide-element" : "pb-section-price1 u-justify-self-center"}>
          <Text tid="freeCaps"/>
        </div>
        <div className={!baseCerts ? "hide-element" : "pb-section-qty1 u-justify-self-center"}>
          {baseCerts}
        </div>
        <div className={!baseCerts ? "hide-element" : "pb-section-total1 u-justify-self-center"}>
          <Text tid="freeCaps"/>
        </div>
        <div className={!certAddons ? 'hide-element' : "pb-section-added left-padding-20"}>
          <Text tid="added"/>
        </div>
        <div className={!certAddons ? 'hide-element' : "pb-section-price2 u-justify-self-center"}>
          ${perCertRate.toFixed(2)}
        </div>
        <div className={!certAddons ? 'hide-element' : "pb-section-qty2 u-justify-self-center"}>
          {certAddons}
        </div>
        <div className={!certAddons ? 'hide-element' : "pb-section-total2 u-justify-self-center"}>
          ${(perCertRate * certAddons).toFixed(2)}
        </div>
      </div>

      <div className="pb-line u-justify-self-center">
        ------------
      </div>

      <div className="pb-renewal u-font-weight-bold u-text-action-blue-700">
        <Text tid={signup ? "subscriptionPrice" : "renewalPrice"}/>
      </div>
      <div className="pb-renewalTotal u-justify-self-center u-font-weight-bold">
        ${grandTotal.toFixed(2)}/{TextOnly('year')}
      </div>
    </div>
  )
}

export default PriceBreakdown;