import React from 'react';
import ToolTip from './ToolTip';

export const TechAvatar = ({tech}: any) => {
  return (
    <ToolTip text={`${tech?.firstName} ${tech?.lastName}`}>
      <div className='tech-profile-avatar'>
        {tech?.firstName[0]}{tech?.lastName[0]}
      </div>
    </ToolTip>
  );
};
