import React, { Component } from 'react';
import LoaderButton from '../components/LoaderButton';
import AlertModal from '../components/AlertModal';
import { Text, TextOnly } from '../components/Text';
import { upgradeShopType, getSystemConfig } from '../libs/db-lib';
import { SHOP_TYPES } from '../CONSTANTS';
import { PricingTable } from '../components/PricingTable';
import { toast } from 'react-toastify';
import { Dialog } from '@reach/dialog';
import {ReactComponent as Quotation} from '../assets/icons/quotation-mark.svg';

import StatsAndTrendsPromo from './StatsAndTrendsPromo';

export default class ShopProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      showConfirmModal: false,
      showPaymentMethodModal: false,
      isLoadingUpgrade: false,
      paymentAmount: 0,
      plusShopRate: 0,
      showReceiptModal: false,
      currentReceipt: {},
      showLearnMoreModal: false,
      reviewOnTop: window.innerWidth <= 1200,
      innerWidth: window.innerWidth,
    };
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    try {
      let config = await getSystemConfig();
      this.setState({
        plusShopRate: config.prices.plusShop,
      });
    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  async upgradeToPlus() {
    if(!this.props.user) return;
    this.setState({ isLoadingUpgrade: true });
    let result = await upgradeShopType(
      this.props.currentShop.shopID,
      SHOP_TYPES.PLUS
    );
    this.setState({
      isLoadingUpgrade: false,
      showConfirmModal: false
    });
    if (result?.error) {
      toast.error(result.error, {
        containerId: 'standard',
        autoClose: false,
        closeOnClick: true
      });
    } else {
      this.props.fetchUser();
      this.props.history.push('/myActivity');
      toast.success(TextOnly('youHaveBeenCharged') + ` $${result}.`, {
        containerId: 'standard',
      });
    }
  }

  handleChangePlan = () => {
    if(!this.props.user) return;
    const noPaymentMethod = !this.props.currentShop.shopPaymentInfo.last4;

    if (noPaymentMethod) {
      // show modal telling the user to add a payment method
      this.setState({
        showPaymentMethodModal: true,
      });
    } else {
      this.setState({
        showConfirmModal: true,
      });
    }
  };

  handleNavToShopProfile = () => {
    this.props.history.push('/shopProfile');
  };

  handleCancelPaymentMethodModal = () => {
    this.setState({
      showPaymentMethodModal: false,
    });
  };
  handleCancelConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
    });
  };

  handleShowLearnMoreModal = () => {
    this.setState({
      showLearnMoreModal: true,
    });
  };

  handleCancelLearnMoreModal = () => {
    let selector =
      this.state.windowWidth < 753 ? '.c-modal-slider' : '.c-modal-slider-75';
    document.querySelector(selector).classList.add('closed');
    setTimeout(() => {
      this.setState({
        showLearnMoreModal: false,
      });
    }, 350);
  };

  handleResize = () => {
    this.setState({
      reviewOnTop: window.innerWidth <= 1200,
      innerWidth: window.innerWidth,
    });
  };

  render() {

    const { reviewOnTop, innerWidth } = this.state;

    return (
      <div className="home">

        {this.props.auto ?

          <div className="l-container">
            <h2>
              <Text tid="autoUpgradeNeeded"/>
            </h2>

          </div>

          : ''
        }

        <>
          <h2 className="c-page-heading">
            <Text tid="comparePlans" />
          </h2>
          <p>
            <strong>
              <Text tid="autoAuthRepresents"/>
            </strong>
          </p>
          <div className="l-container l-view-layout__pricing-content">
            {reviewOnTop &&
              <div className={`c-notice c-notice--promo-plus costumer-quote maxwidth-600px ${innerWidth <= 449.99 ? 'backgroundcolor-aa-gray' : null}`}
              >
                <Quotation
                  className={`margin-bottom-20px ${innerWidth <= 449.99 ? 'q-box' : 'q-box2'}`}
                  title={TextOnly('messageBoard')}
                />
                <p className={`${innerWidth <= 449.99 ? 'color-aa-darkblue' : ''}`}>
                  "{TextOnly('toryMReview')}"
                </p>
                <p className={`${innerWidth <= 449.99 ? 'color-aa-darkblue' : ''}`}>
                  -Tory M.
                </p>
              </div>
            }
            <PricingTable
              handleChangePlan={this.props.handleChangePlan ?? this.handleChangePlan}
              handleLearnMore={this.props.handleShowLearnMoreModal ?? this.handleShowLearnMoreModal}
              shopType={this.props.currentShop?.shopType ?? null}
              user={this.props.user}
            />
            {!reviewOnTop &&
              <div className="c-notice c-notice--promo-plus costumer-quote">
                <Quotation
                  className={`margin-bottom-20px q-box2`}
                  title={TextOnly('messageBoard')}
                />
                <p>
                    "{TextOnly('toryMReview')}"
                </p>
                <p>-Tory M.</p>
              </div>
            }
          </div>
        </>


        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />

        <Dialog
          isOpen={this.state.showPaymentMethodModal && this.props.user}
          onDismiss={this.handleCancelPaymentMethodModal}
          className="c-modal"
          aria-label={TextOnly('noPaymentMethodAvailable')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelPaymentMethodModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="noPaymentMethodAvailable" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="shopDoesNotHaveFormOfPayment" />
            </p>
            <div className="l-flex u-margin-top-large">
              <button
                className="c-btn-outline u-margin-right"
                onClick={this.handleCancelPaymentMethodModal}
              >
                <Text tid="cancel" />
              </button>
              <button className="c-btn" onClick={this.handleNavToShopProfile}>
                <Text tid="goToShopProfile" />
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showConfirmModal && this.props.user}
          onDismiss={this.handleCancelConfirmModal}
          className="c-modal"
          aria-label={TextOnly('upgradeToStandardPlus')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelConfirmModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="upgrade" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="wantToUpgradeToPlus" />
            </p>
            <p>
              <Text
                tid="willBeChargedUpgradingToPlus"
                sub={{
                  amount: this.state.plusShopRate,
                }}
              />
            </p>
            <div className="l-container l-container-center l-flex u-margin-top-large">
              <button
                className="c-btn-outline u-margin-right"
                onClick={this.handleCancelConfirmModal}
              >
                <Text tid="cancel" />
              </button>
              <LoaderButton
                className="c-btn"
                type="submit"
                isLoading={this.state.isLoadingUpgrade}
                text={TextOnly('confirm')}
                loadingText={TextOnly('upgrading')}
                onClick={this.upgradeToPlus.bind(this)}
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showLearnMoreModal}
          onDismiss={this.handleCancelLearnMoreModal}
          className={
            this.state.windowWidth < 753
              ? 'c-modal-slider'
              : 'c-modal-slider-75'
          }
          aria-label={TextOnly('learnMore')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelLearnMoreModal}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="standardPlusFeatures" />
          </h1>

          <div className="c-modal__body">
            {<StatsAndTrendsPromo props={this.props} />}
          </div>
        </Dialog>
      </div>
    );
  }
}