import React, { useState } from 'react'
import { Header } from '../../components/Header';
import { TextOnly } from '../../components/Text';
import RequestVerification from './RequestVerification';
import CurrentVerificationRequests from './CurrentVerificationRequests';

const OwnerVerifications = (props: any) => {
  const [shopRequests, setShopRequests] = useState<any[]>([]);
  return (
    <div className='u-margin-bottom-large'>
      <Header title={TextOnly('vehicleOwnerVerification')} />
      <RequestVerification shopRequests={shopRequests} setShopRequests={setShopRequests} {...props} />
      <CurrentVerificationRequests shopRequests={shopRequests} setShopRequests={setShopRequests} {...props}/>
    </div>
  )
}

export default OwnerVerifications;