import React from 'react';
import { Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import _ from 'underscore';

export const CreateMenu = (
  results,
  menuProps,
  attribute,
  displayLabels,
  first,
  subtext,
  group
) => {
  let idx = 0;
  const grouped = _.groupBy(results, (r) =>
    first ? r[attribute][0].toUpperCase() : r[attribute].toUpperCase()
  );
  const items = Object.keys(grouped)
    .sort()
    .map((attribute) => [
      group &&
        (!!idx && <Menu.Divider key={`${attribute}-divider`} />,
        (
          <Menu.Header key={`${attribute}-header`}>
            <b className="rbt-header">{attribute}</b>
          </Menu.Header>
        )),
      _.map(grouped[attribute], (item) => {
        const reducer = (accumulator, currentValue) =>
          accumulator + ' ' + item[currentValue];
        const displayString = displayLabels.reduce(reducer);
        const menuItem = (
          <MenuItem key={idx} option={item} position={idx}>
            <Highlighter search={menuProps.text}>{displayString}</Highlighter>
            {subtext ? (
              <div>
                <small>{item[subtext]}</small>
              </div>
            ) : (
              ''
            )}
          </MenuItem>
        );

        idx++;
        return menuItem;
      }),
    ]);

  return <Menu {...menuProps}>{items}</Menu>;
};
