import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import HelpTooltip from '../../../components/HelpTooltip';
import BasicModal from '../../../components/BasicModal';
import LoaderButton from '../../../components/LoaderButton';
import { crmRemoveCustomerVehicle } from '../../../libs/db-lib';
import { toast } from 'react-toastify';


const RemoveVehicle: React.FC<any> = ({
  customer,
  shopID,
  selectedVehicle,
  updateSingleCustomer,
}) => {

  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [showRemoveVehicleModal, setShowRemoveVehicleModal] = useState<boolean>(false);

  const removeVehicleFromCustomer = async () => {
    setShowRemoveVehicleModal(true);
  }

  const removeVehicleAssociation = async () => {
    setRemoveLoading(true);
    const response = await crmRemoveCustomerVehicle(shopID, customer.customerID, selectedVehicle.vehicleID)
    if (!response.error) {
      updateSingleCustomer(customer, selectedVehicle, 'REMOVE');
      toast.success(TextOnly('vehicleRemoved'),
        { containerId: 'standard' }
      );
    } else {
      toast.error(`${TextOnly('error')}: ${response.message ?? response.error}`, { containerId: 'standard' });
    }
    setRemoveLoading(false);
  }

  const modalBody = (handleCloseModal: Function) => {
    return (
     <div className="">
      <div className='h2 u-margin-left'>{TextOnly('confirmRemove')}</div>
      <div>
        <div className='display-flex align-items-end'>
          <div className="u-text-right flex-basis-50 u-margin-right l-flex-end align-self-flex-start"><strong><span className="strong">{TextOnly('customer')}:</span></strong></div>
          <div className="u-text-left flex-basis-50 u-margin-left"><span>{customer?.lastName + ', ' + customer?.firstName}</span></div>
        </div>
        <div className='display-flex align-items-end'>
          <div className="u-text-right flex-basis-50 u-margin-right l-flex-end align-self-flex-start"><strong><span className="strong">{TextOnly('vehicle')}:</span></strong></div>
          <div className="u-text-left flex-basis-50 u-margin-left"><span>{selectedVehicle?.year + ' ' + selectedVehicle?.make + ' ' + selectedVehicle?.model + (selectedVehicle?.vin ? ' ' + selectedVehicle?.vin : '')}</span></div>
        </div>
      </div>
      <div>
        <div className='display-flex-justify-content-center align-items-center u-margin-top-large'>
          <LoaderButton
            text={TextOnly('cancel')}
            onClick={() => handleCloseModal()}
            disabled={removeLoading}
            className="c-btn-outline"
          />&nbsp;
          <LoaderButton
            text={TextOnly('confirm')}
            onClick={() => removeVehicleAssociation()}
            isLoading={removeLoading}
          />
        </div>
      </div>
     </div>
   )
 }

  return (
    <>
      <HelpTooltip label={TextOnly('removeVehicleFromCustomer')}>
        <button
          className="c-btn-icon-compact"
          onClick={() => removeVehicleFromCustomer()}
        >
          <div className="c-btn__inner">
            <span className="c-btn__icon fal fa-user-minus" />
          </div>
        </button>
      </HelpTooltip>
      <BasicModal
        isOpen={showRemoveVehicleModal}
        handleCancelModal={()=>setShowRemoveVehicleModal(false)}
        title={TextOnly("removeVehicleFromCustomer")}
        className="c-modal"
        isLoading={false}
        bodyContent={modalBody}
      />
    </>
  )
}

export default RemoveVehicle;
