import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/aa.svg';
import logoPlus from '../assets/images/aa-logo-plus.svg';
import logoPro from '../assets/images/aa-pro.svg';
import logoStandard from '../assets/images/aa-standard.svg';
import { Text, TextOnly } from '../components/Text';
import { ShopType, ShopRole, User } from '../types';
import { SHOP_TYPES, SHOP_ROLES } from '../CONSTANTS';

// nav icons
import { ReactComponent as MyActivityIcon } from '../assets/icons/nav-dashboard.svg';
import { ReactComponent as ManageUsersIcon } from '../assets/icons/nav-manage-users.svg';
import { ReactComponent as ManageToolsIcon } from '../assets/icons/nav-manage-tools.svg';
import { ReactComponent as ViewReportsIcon } from '../assets/icons/nav-view-reports.svg';
import { ReactComponent as ShopProfileIcon } from '../assets/icons/nav-shop-profile.svg';
import { ReactComponent as UserProfileIcon } from '../assets/icons/nav-user-profile.svg';
import { ReactComponent as MessageBoardIcon } from '../assets/icons/chat.svg';
import { ReactComponent as FileInvoiceIcon } from '../assets/icons/my-file-invoice.svg';
import { ReactComponent as MyCalendarIcon } from '../assets/icons/my-calendar-days.svg';

import { ReactComponent as KeyIcon } from '../assets/icons/file-text.svg';
import config from '../config';

type SidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  toggleShowMobileNav: () => void;
  user: User;
  mbRole: string;
  shopName: string;
  shopRole: ShopRole;
  shopType: ShopType;
  gotBanner: number;
  certifiedTech: boolean;
};

export function Sidebar({
  isAuthenticated,
  logout,
  toggleShowMobileNav,
  shopRole,
  shopType,
  user,
  mbRole,
  shopName,
  gotBanner,
  certifiedTech,
}: SidebarProps) {

  const currentPath = window.location.pathname;

  if (!isAuthenticated || !user?.userName) {
    return (
      <div className="c-sidebar">
        <div className="c-sidebar__header c-sidebar__header--empty">
          <NavLink
            className="c-primary-nav__link"
            to="/"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logo} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
          <p className="u-font-weight-500">
            <Text tid="tagLine" />
          </p>
        </div>
      </div>
    );
  }

  const hasShopType = typeof shopType === 'string';
  let logoImg = logo;
  if (shopType === SHOP_TYPES.ENTERPRISE) {
    logoImg = logoPro;
  } else if (shopType === SHOP_TYPES.PLUS) {
    logoImg = logoPlus;
  } else if (shopType === SHOP_TYPES.STANDARD) {
    logoImg = logoStandard;
  }

  if (user) {
    return (
      <div className="c-sidebar">
        <div className="c-sidebar__header">
          <button
            className="c-btn-icon c-btn-mobile-close"
            onClick={toggleShowMobileNav}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <NavLink
            className="c-primary-nav__link"
            to="/myActivity"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logoImg} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
          {config.CERTIFIED_TECH_STATE === "ACTIVE" && certifiedTech ?
            <div className="c-logo cert-tech-padding">
              <img src={'/AA-cert-tech.png'} className="c-logo__image" alt="AutoAuth Cerified Technician" />
            </div>
          : '' }

        </div>

        <div className={"c-sidebar__main"+(config.CERTIFIED_TECH_STATE === "ACTIVE" && certifiedTech ? ' with-cert-tech' : '')}>
          <div>
            <nav className="c-primary-nav" role="navigation">
              <ul className="c-primary-nav__list">
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/myActivity"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <MyActivityIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('myActivity')}
                      />
                      <Text tid="myActivity" />
                    </div>
                  </NavLink>
                </li>
                { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/manageTools/manageShopTools"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ManageToolsIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('manageTools')}
                      />
                      <Text tid="manageTools" />
                    </div>
                  </NavLink>
                </li>
                }
                { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/manageUsers/currentUsers"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ManageUsersIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('manageUsers')}
                      />
                      <Text tid="manageUsers" />
                    </div>
                  </NavLink>
                </li>
                }
                { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/shopProfile"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ShopProfileIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('shopProfile')}
                      />
                      <Text tid="shopProfile" />
                    </div>
                  </NavLink>
                </li>
                }
                { (config?.CRM_STATE === "ACTIVE" && shopType === "PLUS") &&
                <>
                  <li className="c-primary-nav__item">
                    <NavLink
                      className="c-primary-nav__link"
                      to="/crm/manageOrders"
                      activeClassName="is-active"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <FileInvoiceIcon
                          className="c-primary-nav__icon fa-invoice"
                          title={TextOnly('manageOrders')}
                        />
                        <Text tid="manageOrders" />
                      </div>
                    </NavLink>
                      <ul className="c-primary-nav__subList">
                        <li className="c-primary-nav__subItem">
                          <NavLink
                            to="/crm/manageCustomers"
                            className={`c-primary-nav__link`}
                            activeClassName="is-active"
                            onClick={toggleShowMobileNav}
                          >
                            <Text tid="manageCustomers" />
                          </NavLink>
                        </li>
                      </ul>
                  </li>
                </>
                }
                { (config?.CRM_STATE === "ACTIVE" && shopType === "PLUS") &&
                  <li className="c-primary-nav__item">
                    <NavLink
                      className="c-primary-nav__link"
                      to="/crm/calendar"
                      activeClassName="is-active"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <MyCalendarIcon
                          className="c-primary-nav__icon fa-calendar"
                          title={TextOnly('calendar')}
                        />
                        <Text tid="calendar" />
                      </div>
                    </NavLink>
                  </li>
                }
                { (hasShopType && shopRole && shopRole !== SHOP_ROLES.TECH) &&
                <li className="c-primary-nav__item">
                  <NavLink
                    className={`c-primary-nav__link ${
                      shopType === SHOP_TYPES.STANDARD
                        ? 'c-primary-nav__link--plus-promo'
                        : ''
                    }`}
                    to={
                      shopType === SHOP_TYPES.STANDARD
                        ? '/upgradeToPlus/statsAndTrendsPromo'
                        : '/reports/statsAndTrends'
                    }
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <ViewReportsIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('viewReports')}
                      />
                      <Text tid="viewReports" />
                    </div>
                  </NavLink>
                  <ul className="c-primary-nav__subList">
                    <li className="c-primary-nav__subItem">
                      <NavLink
                        to={
                          shopType === SHOP_TYPES.STANDARD
                            ? '/upgradeToPlus/statsAndTrendsPromo'
                            : '/reports/statsAndTrends'
                        }
                        className={`c-primary-nav__link ${
                          shopType === SHOP_TYPES.STANDARD
                            ? 'c-primary-nav__link--plus-promo'
                            : ''
                        }`}
                        activeClassName="is-active"
                        onClick={toggleShowMobileNav}
                      >
                        <Text tid="statsAndTrends" />
                      </NavLink>
                    </li>
                    <li className="c-primary-nav__subItem">
                      <NavLink
                        to={
                          shopType === SHOP_TYPES.STANDARD
                            ? '/upgradeToPlus/shopReportsPromo'
                            : '/reports/shopActions'
                        }
                        className={`c-primary-nav__link ${
                          shopType === SHOP_TYPES.STANDARD
                            ? 'c-primary-nav__link--plus-promo'
                            : ''
                        }`}
                        activeClassName="is-active"
                        onClick={toggleShowMobileNav}
                      >
                        <Text tid="shopActions" />
                      </NavLink>
                    </li>
                    <li className="c-primary-nav__subItem">
                      <NavLink
                        to={
                          shopType === SHOP_TYPES.STANDARD
                            ? '/upgradeToPlus/toolReportsPromo'
                            : '/reports/toolActions'
                        }
                        className={`c-primary-nav__link ${
                          shopType === SHOP_TYPES.STANDARD
                            ? 'c-primary-nav__link--plus-promo'
                            : ''
                        }`}
                        activeClassName="is-active"
                        onClick={toggleShowMobileNav}
                      >
                        <Text tid="toolActions" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                }
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/userProfile"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <UserProfileIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('userProfile')}
                      />
                      <Text tid="myProfile" />
                    </div>
                  </NavLink>
                </li>
                { (config.OWNER_VERIFICATION_STATE === "ACTIVE" &&
                   shopType !== "ENTERPRISE" &&
                   shopRole &&
                   (shopType === "PLUS" || shopRole !== SHOP_ROLES.TECH)) &&
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={`c-primary-nav__link ${
                        (shopType !== SHOP_TYPES.PLUS)
                          ? 'c-primary-nav__link--plus-promo-2'
                          : !user?.certifiedTech ?
                          'c-primary-nav__link--certify-promo' : ''
                      }`}
                      to={
                        shopType !== SHOP_TYPES.PLUS
                          ? '/vehicleOwnerVerificationPromo'
                          : !user?.certifiedTech ?
                              "/vehicleOwnerVerificationVerifyIdentity"
                            : "/vehicleOwnerVerification"
                      }
                      activeClassName="is-active"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <KeyIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('vehicleOwnerVerification')}
                        />
                        <Text tid="vehicleOwnerVerification" />
                      </div>
                    </NavLink>
                  </li>
                }
                { config.MESSAGE_BOARD_STATE === "ACTIVE" && shopType ?
                <li className="c-primary-nav__item">
                  <NavLink
                    className="c-primary-nav__link"
                    to="/messageBoard"
                    activeClassName="is-active"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <MessageBoardIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('messageBoard')}
                      />
                      <Text tid="messageBoard" />
                    </div>
                  </NavLink>
                </li>
                : '' }
              </ul>
            </nav>
          </div>
          <ul className="c-sidebar__links">
            {shopType === SHOP_TYPES.STANDARD ? (
              <li>
                <NavLink
                  className="c-sidebar__link c-btn-dark c-btn-full"
                  to="/upgradeToPlus/comparePlans"
                  onClick={toggleShowMobileNav}
                >
                  <div className="c-btn__inner">
                    <Text tid="comparePlans" />
                  </div>
                </NavLink>
              </li>
            ) : null}
            <li>
              <a
                className="c-sidebar__link c-btn-light"
                href="https://info.autoauth.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-question-circle" />
                  <Text tid="support" />
                </div>
              </a>
            </li>
            <li>
              <button className="c-sidebar__link c-btn-light" onClick={logout}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-sign-out" />
                  <Text tid="logout" />
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return <div className="c-sidebar">{/* empty sidebar */}</div>;
  }
}
