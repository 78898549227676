import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import { LangDictKey, Text, TextOnly } from '../../components/Text';
import { Link } from 'react-router-dom';
import { fieldChangeCase } from '../../libs/utils';
import { findUser, addUserToShop } from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import { Shop, User } from '../../types';
import { capitalize } from '../../libs/utils-ts';

import BulkAddUsers from './BulkAddUsers';

interface AddUserProps {
  user: User;
  currentShop: Shop;
  shopUsers: User[];
  windowWidth: number;
  baseURL: string;
  isEnterprise: boolean;
  onDismiss: () => void;
  handleShowAlert: (
    alertTitle: string,
    alertMessage: string | JSX.Element,
    showModal: boolean
  ) => void;
  handleAddUser: (newUser: User) => void;
  handleBulkAddUsers: (newUsers: { userList: User[] }, error: User[]) => void;
}

type ActivityType = 'SINGLE_USER' | 'BULK_USERS';

interface AddUserState {
  userName: string;
  shopUsers: User[];
  newUserId?: unknown;
  newUserFirstName: string;
  newUserLastName: string;
  isLoading: boolean;
  isSendingRequest: boolean;
  tabset: string;
}

const MY_ACTIVITY: Record<string, ActivityType> = {
  SINGLE: 'SINGLE_USER',
  BULK: 'BULK_USERS',
};

export default class AddUser extends Component<AddUserProps, AddUserState> {
  constructor(props: AddUserProps) {
    super(props);

    this.state = {
      userName: '',
      shopUsers: props.shopUsers,
      newUserId: undefined,
      newUserFirstName: '',
      newUserLastName: '',
      isLoading: false,
      isSendingRequest: false,
      tabset: 'SINGLE_USER',
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'userName') {
      fieldChangeCase(this, event.target, 'lower', false);
    }
  };

  handleTabsetMyActivity = (activityType: ActivityType) => {
    this.setState({
      tabset: activityType,
    });
  };

  validateForm() {
    return this.state.userName.length > 0;
  }

  handleFindUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
      newUserFirstName: '',
      newUserLastName: '',
      newUserId: null,
    });

    try {
      let user = await findUser(this.state.userName);
      this.setState({
        newUserFirstName: user.firstName,
        newUserLastName: user.lastName,
        newUserId: user.userID,
      });
    } catch (e) {
      this.props.handleShowAlert(
        TextOnly('error'),
        <>
          <Text tid="couldntFindUsername" />:{' '}
          <strong>{this.state.userName}</strong>
        </>,
        true
      );
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSendRequest = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    this.setState({ isSendingRequest: true });

    try {
      const data = this.state;
      // Check if this user is already in our shop
      const userFound = this.props.shopUsers.filter((user) => {
        return user.userID === data.newUserId;
      });
      if (userFound.length) {
        this.setState({
          isSendingRequest: false,
          isLoading: false,
        });

        this.props.handleShowAlert(
          TextOnly('success'),
          TextOnly('userAssociatedWithShop'),
          true
        );
      } else {
        const shopId = this.props.currentShop.shopID;
        const ret = await addUserToShop(shopId, data.newUserId, 'USER');
        this.setState({ isSendingRequest: false });
        if (ret?.error) {
          this.props.handleShowAlert(TextOnly('error'), ret.error, true);
        } else {
          const newUser = {
            userID: data.newUserId,
            userName: data.userName,
            firstName: data.newUserFirstName,
            lastName: data.newUserLastName,
            shopUserRole: 'USER',
            shopUserState: 'PENDING',
            addedOn: new Date(),
            addedBy: this.props.user.userID,
            additionalIDFields: {},
            email: '',
            role: 'USER' as LangDictKey,
            state: '',
            name: `${data.newUserFirstName} ${data.newUserLastName}`,
          };
          // partial user being added
          // @ts-ignore
          this.props.handleAddUser(newUser);
          this.props.onDismiss();
        }
      }
    } catch (e) {
      // Need to figure out how to pass back a message from the REST call about the cause of the error
      // @ts-ignore - error type is not checked
      this.props.handleShowAlert(TextOnly('error'), e.message, true);
    } finally {
      this.setState({
        isSendingRequest: false,
        isLoading: false,
      });
    }
  };

  render() {
    const { user, shopUsers, baseURL, isEnterprise, onDismiss } = this.props;

    const newUserId = this.state.newUserId;
    const newUserFirstName = this.state.newUserFirstName;
    const newUserLastName = this.state.newUserLastName;

    const roomForMore =
      (user ? user.shopMaxUsers : 0) -
        (shopUsers !== null ? shopUsers.length : 0) >
      0;

    const { tabset } = this.state;

    return (
      <Dialog
        onDismiss={onDismiss}
        className="c-modal-slider"
        aria-label={TextOnly('addUsers')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={onDismiss}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="addUsers" />
        </h1>
        {(isEnterprise && roomForMore)
        || (!isEnterprise && (roomForMore || this.props.currentShop.shopMaxUsers < this.props.currentShop.shopUserCap)) ?
        (
        <div className="c-modal__body">
          {isEnterprise && (
            <div className="u-text-center">
              <div className="c-tabset">
                <span
                  className={`c-tab__item ${
                    tabset === MY_ACTIVITY.SINGLE ? 'c-tab__item--active' : ''
                  }`}
                  onClick={() =>
                    this.handleTabsetMyActivity(MY_ACTIVITY.SINGLE)
                  }
                >
                  <Text tid="singleUser" />
                </span>
                <span
                  className={`c-tab__item ${
                    tabset === MY_ACTIVITY.BULK ? 'c-tab__item--active' : ''
                  }`}
                  onClick={() => this.handleTabsetMyActivity(MY_ACTIVITY.BULK)}
                >
                  <Text tid="bulkUsers" />
                </span>
              </div>
            </div>
          )}

          {tabset === MY_ACTIVITY.SINGLE ? (
            <form onSubmit={this.handleFindUser}>
              <div className="c-field">
                {roomForMore ? (
                  <>
                    <p>
                      <Text tid="addAnExistingUser" />
                    </p>
                    <p>
                      <Text tid="ifYouWantToAddUser" />
                      <br />
                      <Link to="/signup">{baseURL}/signup</Link>
                    </p>
                  </>
                ) : (
                  <p style={{color: 'red'}}>
                    <Text tid="maximumShopUsersReached" />
                  </p>
                )}

                <label
                  htmlFor="userName"
                  className="c-field__label u-margin-top-large"
                >
                  <Text tid="enterUsername" />
                </label>
                <input
                  autoFocus
                  id="userName"
                  name="userName"
                  maxLength={50}
                  type="text"
                  className="c-input"
                  placeholder={TextOnly('username')}
                  value={this.state.userName}
                  onChange={this.handleChange}
                  disabled={!roomForMore}
                />
              </div>
              <div className="c-field">
                <LoaderButton
                  type="submit"
                  disabled={!roomForMore || !this.validateForm()}
                  isLoading={this.state.isLoading}
                  text={TextOnly('searchForUser')}
                  loadingText={TextOnly('searchingUser')}
                />
                {!roomForMore && !newUserId ? (
                  <Link
                    to={{
                      pathname: '/shopProfile',
                      state: {
                        tab: 'shopInfo',
                      },
                    }}
                  >
                    <Text tid="addUserCapacityHere" />
                  </Link>
                ) : null}
              </div>
              {newUserId ? (
                <div className="c-field">
                  <label htmlFor="username" className="c-field__label">
                    {newUserFirstName} {newUserLastName}
                  </label>
                  <LoaderButton
                    type="submit"
                    disabled={!this.state.newUserId || !roomForMore}
                    isLoading={this.state.isSendingRequest}
                    text={
                      roomForMore
                        ? TextOnly('sendRequestToUser')
                        : TextOnly('maximumShopUsersReached')
                    }
                    loadingText={TextOnly('sendingRequest')}
                    onClick={this.handleSendRequest.bind(this)}
                  />
                  {!roomForMore ? (
                    <Link
                      to={{
                        pathname: '/shopProfile',
                        state: {
                          tab: 'shopInfo',
                        },
                      }}
                    >
                      <Text tid="addUserCapacityHere" />
                    </Link>
                  ) : null}
                </div>
              ) : null}
            </form>
          ) : null}

          {tabset === MY_ACTIVITY.BULK && isEnterprise ? (
            <BulkAddUsers
              userShops={this.props.user.userShops}
              currentShop={this.props.currentShop}
              shopUsers={this.state.shopUsers}
              windowWidth={this.props.windowWidth}
              handleShowAlert={this.props.handleShowAlert}
              handleBulkAddUsers={this.props.handleBulkAddUsers}
            />
          ) : null}
        </div>) : (
          this.props.currentShop.shopType === "STANDARD" ?
          (
            <p className="u-font-weight-bold">
              <Text
                tid="standardReachedMaxUsers"
                sub={{
                  maxUserCount: (
                    <span>
                      {this.props.currentShop.shopUserCap}
                    </span>
                  ),
                  upgradePlus: (
                    <a
                      href="/upgradeToPlus/comparePlans"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {capitalize(TextOnly('upgradeToStandardPlus'))}
                    </a>
                  ),
                }}
              />
            </p>
          ) :
          (
            <p className="u-font-weight-bold">
              <Text
                tid="reachedMaxUsers"
                sub={{
                  userCount: (
                    <span>
                      {this.props.user.shopMaxUsers}
                    </span>
                  ),
                  maxUserCount: (
                    <span>
                      {this.props?.currentShop?.shopUserCap}
                    </span>
                  ),
                  contactLink: (
                    <a
                      href="https://info.autoauth.com/contact/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {capitalize(TextOnly('contactUs'))}
                    </a>
                  ),
                }}
              />
            </p>
          )
        )
      }
      </Dialog>
    );
  }
}
