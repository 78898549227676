import React, { useEffect, useState } from 'react';
import { Header } from '../../components/Header';
import LoaderButton from '../../components/LoaderButton';
import { toast } from 'react-toastify';
import { agreeTaC } from '../../libs/db-lib';
import { TextOnly, Text } from '../../components/Text';
import './MessageBoard.css';

export const TermsAndConditionsMB = (props: any) => {
  const shopID = props.currentShop.shopID;

  const [isLoading, setIsLoading] = useState(false);
  const [windowMaxHeight, setWindowMaxHeight] = useState(
    window.innerWidth < 770
      ? 'fit-content'
      : window.innerWidth < 960 && window.innerHeight < 850
      ? 'fit-content'
      : window.innerHeight - (window.innerWidth < 960 ? 350 : 250) + 'px'
  );
  const [windowMinHeight, setWindowMinHeight] = useState(
    window.innerWidth < 960 && window.innerHeight < 850
      ? 'fit-content'
      : '550px'
  );

  const handleAgreeTaC = async () => {
    setIsLoading(true);
    const response = await agreeTaC(shopID);
    if (!response?.error) {
      const userAux = {...props.user};
      userAux.application['MESSAGE_BOARD'] = response.data; //response.data === 'AGREED'
      props.updateUserInfo({...userAux})
    } else {
      toast.error(response.error, {
        containerId: 'standard',
        autoClose: false,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 770) {
        setWindowMaxHeight('fit-content');
      } else if (window.innerWidth < 960 && window.innerHeight < 850) {
        setWindowMinHeight('fit-content');
        setWindowMaxHeight('fit-content');
      } else {
        // If width < 960, layout changes with AutoAuth Logo added on top, reducing maxHeight.
        setWindowMaxHeight(
          window.innerHeight - (window.innerWidth < 960 ? 350 : 250) + 'px'
        );
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const containerStyle = {
    maxHeight: windowMaxHeight,
    minHeihgt: windowMinHeight,
  };

  return (
    <>
      <Header title={TextOnly('termsConditions')} />
      <div className='wrapper-modal-tac'>
        <div className="c-box modal-messagetac">
          <p className="text-message-tac">
            {TextOnly('modalMessageTermsCondition')}
          </p>
          <a
            href="/ISS-AutoAuth-Subscriber-Agreement_6_21_2023.pdf"
            download
          >
            <Text tid="termsConditions" />
          </a>
          <div>
            <LoaderButton
              type="submit"
              disabled={false}
              isLoading={isLoading}
              text={TextOnly('agree')}
              loadingText={TextOnly('loading')}
              onClick={() => handleAgreeTaC()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
