import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import { convertOrderNumToDisplay } from '../../../libs/utils';
import { TechAvatar } from '../../../components/TechAvatar';
import { Dialog } from '@reach/dialog';
import AddEditOrderForm from './AddEditOrderForm';
import { ORDER_STATUS_OPTIONS } from '../CrmConstants';
import { Draggable } from './Draggable';

interface KanbanCardProps {
  data: any;
  currentShop: any;
  onChange: any;
  shopCustomers: any[];
  isSingleCustomer?: boolean;
  user: any;
}

const KanbanCard: React.FC<KanbanCardProps> = ({
  data,
  currentShop,
  onChange,
  shopCustomers,
  isSingleCustomer,
  user,
 }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingOrder, setEditingOrder] =useState<any>();

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.kanban-edit-order')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);
  };

  const statusColorIndex: number = ORDER_STATUS_OPTIONS.findIndex((status: any) => status.value === data?.status);

  return (
    <>
      <Draggable id={data.orderID} >
        <div className="c-box--white kanban-card">
        <div className="flex-row flex-space-between">
          <div
            className="text-link"
            onClick={() => {
              setEditingOrder(data);
              setShowModal(true);
            }}
          >
            {`${TextOnly('order')}#: ${convertOrderNumToDisplay(data?.orderNumber)}`}
          </div>
            <i className={`fa fa-circle u-padding-right-med status-color-${statusColorIndex}`} aria-hidden="true"></i>
          </div>
          <div className="h2 c-box__title">{data?.title}</div>
          <div className="flex-row flex-space-between">
            <section>
              <div>
                <i className="fa fa-car" aria-hidden="true"></i>
                <span className="u-padding-left-small">
                  {data?.makeModelYear}
                </span>
              </div>
              <div>
                <i className="fa fa-user" aria-hidden="true"></i>
                <span className="u-padding-left-small">
                  {data?.customerName}
                </span>
              </div>
            </section>
            {data?.tech && (
              <div className="l-flex u-align-end">
                <TechAvatar tech={data.tech}/>
              </div>
            )}
          </div>
        </div>
      </Draggable>
      <Dialog
        isOpen={showModal}
        onDismiss={handleCancelModal}
        className="c-modal-slider kanban-edit-order"
        id="addEditOrder"
        aria-label={TextOnly('orderDetails')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <AddEditOrderForm
          currentShop={currentShop}
          shopCustomers={shopCustomers}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          handleCloseModal={handleCancelModal}
          orderData={editingOrder}
          onSubmit={onChange}
          isSingleCustomer={isSingleCustomer}
          user={user}
        />
      </Dialog>
    </>
  );
};

export default KanbanCard;