import React from 'react';
import { ORDER_STATUS_OPTIONS } from '../CrmConstants';
import KanbanColumn from './KanbanColumn';
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';

const KanbanBoard: React.FC<any> = (props) => {

  const {
    handleOrderChange,
    handleOrderStatusChange,
    orders,
    currentShop,
    shopCustomers,
    user,
  } = props;

  const columnKeys: string[] = [];
  ORDER_STATUS_OPTIONS.forEach((status) => {
    if (status.value !== 'paid' && status.value !== 'canceled') {
      columnKeys.push(status.value);
    }
  });

  const mouseSensor = useSensor(MouseSensor,
    {
      activationConstraint: {
        distance: 10,
      }
    }
  );
  const touchSensor = useSensor(TouchSensor,
    {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      }
    }
  );

  const sensors = useSensors(
    mouseSensor,
    touchSensor,
  );

  function handleDragEnd({ over, active }: any) {
    const orderID = active?.data?.current?.orderID;
    const newStatus = over?.data?.current?.status;

    const order = orders.find((order: any) => order.orderID === orderID);
    if (over && newStatus !== order.status) {
      handleOrderStatusChange(currentShop.shopID, orderID, newStatus);
    }
  }

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <div className="flex-space-between flex-row u-margin-top u-margin-bottom">
        {columnKeys.map((status: any, index: number) => {
          const column: any = ORDER_STATUS_OPTIONS.find((statusOption: any) => status === statusOption.value);
          const ordersByStatus: any[] = orders.filter((order:any) => order.status === column?.value);
          return (
            <div
              className={`u-width-100-percent u-padding-${index === 0 ? "right-" : index === columnKeys.length - 1 ? "left-" : "sides-"}small`}
              key={index}
            >
              <KanbanColumn
                key={index}
                columnNum={index}
                title={column?.label}
                value={column.value}
                data={ordersByStatus}
                currentShop={currentShop}
                shopCustomers={shopCustomers}
                handleOrderChange={handleOrderChange}
                user={user}
              />
            </div>
          );
        }
        )}
      </div>
    </DndContext>
  );
};

export default KanbanBoard;