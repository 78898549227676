import React from 'react';
import { NavLink } from 'react-router-dom';
import { useMenu } from '../context/Menu';
import { Text } from '../components/Text';
import menu from '../assets/icons/menu.svg';
import { useUser } from '../context/User';
import { SelectShop } from './SelectShop';
import { SHOP_ROLES, SHOP_TYPES } from '../CONSTANTS';

type HeaderPropType = {
  context?: string;
  comp?: any;
  title: string;
};

export function Header({ context, title, comp }: HeaderPropType) {
  const { toggleShowMobileNav, showMobileNav } = useMenu();
  const { user, currentShop } = useUser();
  const isStandard = currentShop?.shopType === SHOP_TYPES.STANDARD;
  const isOwner = user?.shopRole === SHOP_ROLES.OWNER;

  return (
    <>
      <div className="l-flex-between u-margin-top-large">
        <div className="c-mobile-nav">
          <button
            className={`c-btn-link c-btn-link-mobile-nav ${
              showMobileNav ? 'c-btn-link__active' : ''
            }`}
            onClick={toggleShowMobileNav}
          >
            <div className="c-btn__inner">
              <img
                src={menu}
                className="c-btn__inner"
                alt="toggle menu button"
              />
            </div>
          </button>
        </div>
        <div />
        <div>
          <SelectShop />
        </div>
      </div>

      <div className="c-header">
        <div className="l-flex-start">
          {comp ?? null}
          <h2 className="c-page-heading">
            {context ? (
              <>
                <span className="c-page-heading__context">{context}</span>
                <span className="c-page-heading__divider">/</span>
              </>
            ) : null}
            <span className="c-page-heading__title">{title}</span>
          </h2>
        </div>

        <div className="l-flex-end">
          {isStandard && isOwner ? (
            <div>
              <NavLink
                className="c-btn-dark c-btn u-mobile-only"
                to="/upgradeToPlus/comparePlans"
              >
                <Text tid="upgrade" />
              </NavLink>
            </div>
          ) : null}

          {isStandard && isOwner ? (
            <div className="u-desktop-only">
              <NavLink className="c-btn-dark" to="/upgradeToPlus/comparePlans">
                <div className="c-btn__inner">
                  <Text tid="upgradeToStandardPlus" />
                </div>
              </NavLink>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
