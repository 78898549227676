import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoaderButton from '../components/LoaderButton';
import {
  passwordValidate,
  usernameValidate,
  nameValidate,
  isValidEmail,
  fieldChangeCase,
} from '../libs/utils';
import { Text, TextOnly } from '../components/Text';
import {
  createUser,
  getSystemConfig,
  getSignupCustomFields,
} from '../libs/db-lib';
import { Dialog } from '@reach/dialog';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ExternalFooterLinks } from '../components/ExternalFooterLinks';
import LanguageSelector from '../components/LanguageSelector';
import { toast } from 'react-toastify';

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isVerified: false,
      showModal: false,
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      inviteCode: '',
      verifyFirstName: false,
      verifyLastName: false,
      verifyEmail: false,
      verifyConfirmEmail: false,
      verifyInviteCode: false,
      disclaimerAccepted: false,
      recaptcha: '',
      reloadRecaptcha: false,
      key: 0,
      config: {},
      customFields: [],
      awaitingRecaptcha: false,
    };
  }

  async componentDidMount() {
    // Render captcha only for Signup page
    if (document.querySelector('.grecaptcha-badge')) {
      document.querySelector('.grecaptcha-badge').classList.add('captcha-show');
    }

    // Refresh recaptcha token every 2 minutes
    setInterval(() => {
      this.setState({
        reloadRecaptcha: !this.state.reloadRecaptcha,
      });
    }, 110 * 1000);

    let config = await getSystemConfig();
    this.setState({ config: config });

    if (this.props.linkID) {
      const results = await getSignupCustomFields(this.props.linkID);
      if (results.message) {
        this.setState({
          alertMessage: results.message,
          showModal: true,
          isLoading: false,
        });
      } else {
        const customFields = results[0].additionalIDFields;
        this.setState({
          customFields: customFields,
          linkID: this.props.linkID,
        });
        for (let i = 0; i < customFields.length; i++) {
          let fieldControlID = customFields[i].replace(/ /g, '-');
          let fieldName = 'field-' + fieldControlID;
          let setStateObj = {};
          setStateObj[fieldName] = '';
          this.setState(setStateObj);
        }
      }
    }
  }

  componentWillUnmount() {
    if (document.querySelector('.grecaptcha-badge')) {
      document
        .querySelector('.grecaptcha-badge')
        .classList.remove('captcha-show');
    }
  }

  validateForm() {
    let passwordValid = passwordValidate(this.state.password);
    let usernameValid = usernameValidate(this.state.username);
    let namesValid =
      nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);

    let customFields = this.state.customFields;

    let customFieldsValid = true;

    for (let i = 0; i < customFields.length; i++) {
      let fieldControlID = customFields[i].replace(/ /g, '-');
      let fieldName = 'field-' + fieldControlID;
      if (!(this.state[fieldName] && this.state[fieldName].length > 0)) {
        customFieldsValid = false;
        break;
      }
    }

    return (
      this.state.username.length > 0 &&
      this.state.email.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      (this.props.inviteCode !== '1' || this.state.inviteCode.length > 0) &&
      passwordValid &&
      usernameValid &&
      namesValid &&
      emailValid &&
      customFieldsValid &&
      this.state.password === this.state.confirmPassword &&
      this.state.email === this.state.confirmEmail &&
      this.state.isVerified === true &&
      this.state.disclaimerAccepted === true
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  activateFirstNameVerify() {
    this.setState({ verifyFirstName: true });
  }

  activateLastNameVerify() {
    this.setState({ verifyLastName: true });
  }

  activateInviteCodeVerify() {
    this.setState({ verifyInviteCode: true });
  }

  activateEmailVerify() {
    this.setState({ verifyEmail: true });
  }

  verifyCaptcha = (token) => {
    if (token) {
      if (document.querySelector('.grecaptcha-badge')) {
        document
          .querySelector('.grecaptcha-badge')
          .classList.add('captcha-show');
      }
      this.setState({
        isVerified: true,
        recaptcha: token,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
      isLoading: false,
    });
  };

  handleChange = (event) => {
    if (event.target.id === 'username' || event.target.id === 'email') {
      fieldChangeCase(this, event.target, 'lower', false);
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
    if (event.target.id === 'email') {
      this.activateEmailVerify();
    }
    if (event.target.id === 'firstName') {
      this.activateFirstNameVerify();
    }
    if (event.target.id === 'lastName') {
      this.activateLastNameVerify();
    }
    if (event.target.id === 'inviteCode') {
      this.activateInviteCodeVerify();
    }
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };

  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleAcceptDisclaimer = (event) => {
    if (this.state.disclaimerAccepted) {
      this.setState({ disclaimerAccepted: false });
    } else {
      this.setState({ disclaimerAccepted: true });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.state.isVerified) {
      this.setState({
        isLoading: true,
      });
      this.submitCreateUser(this.state.recaptcha);
    }
  };

  submitCreateUser = async (token) => {
    let customFields = this.state.customFields;
    let customFieldsParam = {};

    for (let i = 0; i < customFields.length; i++) {
      let fieldControlID = customFields[i].replace(/ /g, '-');
      let fieldName = 'field-' + fieldControlID;
      customFieldsParam[customFields[i]] = this.state[fieldName];
    }

    let err = await createUser(
      this.state.username,
      this.state.password,
      this.state.firstName,
      this.state.lastName,
      this.state.email,
      window.localStorage.getItem('rcml-lang'),
      token,
      this.state.inviteCode,
      this.state.linkID,
      customFieldsParam
    );
    if (err) {
      this.setState({
        alertMessage: err,
        showModal: true,
        isLoading: false,
        reloadRecaptcha: !this.state.reloadRecaptcha
      });
    } else {
      const signupConfirmToast = toast.success(
        TextOnly('signupConfirm', { email: this.state.email }),
        {
          containerId: 'wide',
          autoClose: false,
        }
      );
      this.props.history.push({
        pathname: '/Login',
        state: {
          previousToast: signupConfirmToast,
        },
      });
    }

    this.setState({
      isLoading: false,
      awaitingRecaptcha: false,
    });
  };

  render() {
    return (
      <>
        <div className="l-container-lg l-full-height">
          <div className="l-form-column u-margin-top-large">
            <LanguageSelector />
          </div>
          <form onSubmit={this.handleSubmit}>
            <h2 className="u-text-center  h1 u-padding-bottom-large">
              <Text tid="userSignup" />
            </h2>
            <div className="l-container-sm">
              {this.state.config.checkInviteCode === 1 ? (
                <div className="c-field">
                  <label htmlFor="inviteCode" className="c-field__label">
                    <Text tid="inviteCode" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.inviteCode &&
                      this.state.inviteCode.length === 0
                        ? 'error'
                        : ''
                    }`}
                    maxLength="50"
                    id="inviteCode"
                    placeholder={TextOnly('inviteCode')}
                    type="text"
                    value={this.state.inviteCode}
                    onChange={this.handleChange}
                  />
                  {this.state.verifyInviteCode &&
                  this.state.inviteCode.length === 0 ? (
                    <div className="c-field__error">
                      <Text tid="inviteCodeHint" />
                    </div>
                  ) : null}
                  <div>
                    <Link
                      to={'/requestInvite?inviteCode=' + this.props.inviteCode}
                    >
                      <Text tid="needInviteCode" />
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="l-container-med l-flex-wrap u-align-start">
              <div className="l-form-column u-padding-right-small-desktop">
                <div className="c-field">
                  <label htmlFor="username" className="c-field__label">
                    <Text tid="username" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.username.length > 0 &&
                      !usernameValidate(this.state.username)
                        ? 'error'
                        : ''
                    }`}
                    maxLength="50"
                    placeholder={TextOnly('username')}
                    id="username"
                    type="text"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                  {this.state.username.length > 0 &&
                  !usernameValidate(this.state.username) ? (
                    <div className="c-field__error">
                      <Text tid="usernameHint" />
                    </div>
                  ) : null}
                </div>
                <div className="c-field">
                  <label htmlFor="firstName" className="c-field__label">
                    <Text tid="firstName" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.verifyFirstName &&
                      !nameValidate(this.state.firstName)
                        ? 'error'
                        : ''
                    }`}
                    maxLength="50"
                    id="firstName"
                    placeholder={TextOnly('firstName')}
                    type="text"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                  {this.state.verifyFirstName &&
                  !nameValidate(this.state.firstName) ? (
                    <div className="c-field__error">
                      <Text tid="firstNameHint" />
                    </div>
                  ) : null}
                </div>
                <div className="c-field">
                  <label htmlFor="lastName" className="c-field__label">
                    <Text tid="lastName" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.verifyLastName &&
                      !nameValidate(this.state.lastName)
                        ? 'error'
                        : ''
                    }`}
                    maxLength="50"
                    id="lastName"
                    placeholder={TextOnly('lastName')}
                    type="text"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                  {this.state.verifyLastName &&
                  !nameValidate(this.state.lastName) ? (
                    <div className="c-field__error">
                      <Text tid="lastNameHint" />
                    </div>
                  ) : null}
                </div>
                {/* <LanguageSelector /> */}
              </div>
              <div className="l-form-column u-padding-left-small-desktop">
                <div className="c-field">
                  <label htmlFor="email" className="c-field__label">
                    <Text tid="email" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.verifyEmail && !isValidEmail(this.state.email)
                        ? 'error'
                        : ''
                    }`}
                    maxLength="100"
                    id="email"
                    placeholder={TextOnly('email')}
                    type="text"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  {this.state.verifyEmail && !isValidEmail(this.state.email) ? (
                    <div className="c-field__error">
                      <Text tid="emailHint" />
                    </div>
                  ) : null}
                </div>
                <div className="c-field">
                  <label htmlFor="confirmEmail" className="c-field__label">
                    <Text tid="confirmEmail" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.verifyEmail &&
                      !isValidEmail(this.state.confirmEmail)
                        ? 'error'
                        : ''
                    }`}
                    maxLength="100"
                    id="confirmEmail"
                    placeholder={TextOnly('confirmEmail')}
                    type="text"
                    value={this.state.confirmEmail}
                    onChange={this.handleChange}
                  />
                  {this.state.verifyEmail &&
                  this.state.confirmEmail !== this.state.email ? (
                    <div className="c-field__error">
                      <Text tid="confirmEmailHint" />
                    </div>
                  ) : null}
                </div>
                <div className="c-field">
                  <label htmlFor="password" className="c-field__label">
                    <Text tid="password" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.password.length > 0 &&
                      !passwordValidate(this.state.password)
                        ? 'error'
                        : ''
                    }`}
                    maxLength="50"
                    id="password"
                    placeholder={TextOnly('password')}
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                  />
                  {this.state.password.length > 0 &&
                  !passwordValidate(this.state.password) ? (
                    <div className="c-field__error">
                      <Text tid="passwordHint" />
                    </div>
                  ) : null}
                </div>
                <div className="c-field">
                  <label htmlFor="confirmPassword" className="c-field__label">
                    <Text tid="confirmPassword" />
                  </label>
                  <input
                    className={`c-input ${
                      this.state.confirmPassword.length > 0 &&
                      this.state.password !== this.state.confirmPassword
                        ? 'error'
                        : ''
                    }`}
                    maxLength="50"
                    id="confirmPassword"
                    placeholder={TextOnly('confirmPassword')}
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    type="password"
                  />
                  {this.state.confirmPassword.length > 0 &&
                  this.state.password !== this.state.confirmPassword ? (
                    <div className="c-field__error">
                      <Text tid="confirmPasswordHint" />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="l-form-column">
              {this.state.customFields.map((field) => {
                let fieldControlID = field.replace(/ /g, '-');
                return (
                  <div className="c-field">
                    <label
                      className={`${
                        this.state['field-' + fieldControlID + 'FieldActivate']
                      } c-field__label`}
                      htmlFor={`${this.state['field-' + fieldControlID]}`}
                    >
                      {field}
                    </label>
                    <input
                      autoFocus
                      id={`${this.state['field-' + fieldControlID]}`}
                      className={
                        this.state['field-' + fieldControlID] &&
                        !(this.state['field-' + fieldControlID].length > 0)
                          ? 'error'
                          : ''
                      }
                      type="text"
                      maxLength="100"
                      value={this.state['field-' + fieldControlID]}
                      onChange={this.handleChange.bind(this)}
                      onFocus={this.activateField.bind(this)}
                      onBlur={this.disableField.bind(this)}
                    />
                    {this.state['field-' + fieldControlID] &&
                    this.state['field-' + fieldControlID].length === 0 ? (
                      <div className="c-field__error">
                        <Text tid="fieldCannotBeBlank" />
                      </div>
                    ) : null}
                  </div>
                );
              })}
              <div className="l-container-sm l-flex-wrap u-margin-top-large u-padding-top-large u-margin-bottom-large u-padding-bottom-large u-justify-space-around  u-align-center">
                <a href="/ISS-AutoAuth-Subscriber-Agreement_6_21_2023.pdf" download>
                  <Text tid="termsConditions" />
                </a>

                <div className="l-flex-wrap u-align-center">
                  <input
                    id="acceptDisclaimer"
                    type="checkbox"
                    checked={!!this.state.disclaimerAccepted}
                    onChange={this.handleAcceptDisclaimer}
                    className="u-margin-right-large"
                  />
                  <label
                    htmlFor="acceptDisclaimer"
                    className="c-field__label left-padding u-padding-bottom-none"
                  >
                    <Text tid="accept" />
                  </label>
                </div>
              </div>
              <div className="c-card u-padding-bottom-small u-padding-top-small u-margin-bottom">
                <label htmlFor="password" className="c-field__label">
                  This site is protected by reCAPTCHA and the Google
                  <a
                    href="https://policies.google.com/privacy"
                    className="c-field__label"
                  >
                    {' '}
                    Privacy Policy
                  </a>{' '}
                  and
                  <a
                    href="https://policies.google.com/terms"
                    className="c-field__label"
                  >
                    {' '}
                    Terms of Service
                  </a>{' '}
                  apply.
                </label>
              </div>

              <LoaderButton
                id="submit-button"
                className="c-btn-full"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text={<Text tid="signup" />}
                loadingText={<Text tid="signingUp" />}
              />
              <div className="captcha-container">
                <GoogleReCaptcha
                  onVerify={this.verifyCaptcha}
                  key={this.state.reloadRecaptcha}
                />
              </div>
            </div>
          </form>
          <ExternalFooterLinks />
        </div>
        <Dialog
          isOpen={this.state.showModal}
          onDismiss={this.handleCancel.bind(this)}
          className="c-modal"
          aria-label={TextOnly('error')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancel.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="error" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid={this.state.alertMessage} />
            </p>
          </div>
        </Dialog>
      </>
    );
  }
}
