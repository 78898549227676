import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import ReactTable from 'react-table';
import AddEditVehicle from './AddEditVehicle';
import RemoveVehicle from './RemoveVehicle';


const VehicleTable: React.FC<any> = (props) => {
  const { allVehicles, currentShop, customer, updateSingleCustomer } = props;
  const shopID = currentShop?.shopID;

  const [filterString, setFilterString] = useState<string>('');

  const VehicleCol = [
    {
      Header: TextOnly('vehicle'),
      width: 200,
      Cell: (row: any) => {
        return (
          <div className="l-flex-gap-1">
            {row.original.year} &nbsp;
            {row.original.make} &nbsp;
            {row.original.model}
          </div>
        );
      },
    },
    {
      Header: TextOnly('color'),
      accessor: 'color',
    },
    {
      Header: TextOnly('licensePlate'),
      accessor: 'licensePlate',
    },
    {
      Header: TextOnly('actions'),
      id: 'actions',
      Cell: (row: any) => {
        return (
          <div className="l-flex-gap-1">
            {/* Edit Vehicle */}
            <AddEditVehicle
              vehicle={row.original}
              currentShop={currentShop}
              customer={customer}
              updateSingleCustomer={updateSingleCustomer}
            />
            {customer &&
              <RemoveVehicle
                customer={customer}
                shopID={shopID}
                selectedVehicle={row.original}
                updateSingleCustomer={updateSingleCustomer}
              />
            }
          </div>
        );
      },
    },
  ];


  return (
    <div className='l-container-med u-margin-none u-margin-bottom-large u-padding-xs'>
      <div className="c-field no-spacing u-width-100-percent u-margin-top-large display-flex">
        <div className='u-margin-right'>
          <AddEditVehicle
            customer={customer}
            currentShop={currentShop}
            updateSingleCustomer={updateSingleCustomer}
          />
        </div>
        <input
          id="filterActions"
          type="text"
          maxLength={50}
          className="c-input"
          placeholder={TextOnly('searchVehicle') + '...'}
          onChange={(event) => setFilterString(event.target.value)}
          value={filterString}
        />
        <i className="c-field__input-icon fal fa-search" />
      </div>
      <ReactTable
        columns={VehicleCol}
        data={
          allVehicles
            .map((vehicle: any) =>{
              const filterValue = vehicle.make + vehicle.model + vehicle.year + vehicle.vin
              vehicle.filterValue = filterValue.toLocaleLowerCase();
              return vehicle
            })
            .filter((vehicle: any) =>
              vehicle?.filterValue?.includes(filterString.toLocaleLowerCase())
            )
        }
        className="-highlight u-margin-top tablelayout"
        previousText={TextOnly('previousPage')}
        nextText={TextOnly('nextPage')}
        pageText={TextOnly('page')}
        ofText={TextOnly('of')}
        rowsText={TextOnly('rows')}
        noDataText={TextOnly('noVehicles')}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'lastName',
            desc: false,
          },
        ]}
        getTheadThProps={() => {
          return {
            style: {
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              width: '100%',
            },
          };
        }}
        SubComponent={row=>
          <div className='u-margin-bottom'>
            <div className="c-card u-padding-bottom">
              <div className="h3 c-card__title u-margin-none">{TextOnly('details')}</div>
              <div className="c-card__description u-margin-none">
                <table className="card-table width-auto">
                  <colgroup>
                    <col className="card-table-col-labels-25" />
                    <col className="card-table-col-labels-25" />
                  </colgroup>
                  <tbody>
                    <tr className="card-tr">
                      <th className={`card-th`}>{TextOnly('vin')}: </th>
                      <td className={`card-td`}>
                        {row.original.vin || `<${TextOnly('unavailable')}>`}
                      </td>
                    </tr>
                    <tr className="card-tr">
                      <th className={`card-th`}>{TextOnly('odometer')}: </th>
                      <td className={`card-td`}>
                        {row.original.odometer || `<${TextOnly('unavailable')}>`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default VehicleTable;
