import React from 'react';
import { useUser } from '../context/User';
import { SHOP_STATES } from '../CONSTANTS';
import _sortBy from 'lodash/sortBy';
import { TextOnly } from '../components/Text';

export function SelectShop() {
  const { user, currentShop, updateCurrentShop } = useUser();

  function handleShopChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    let newShop = event.target.value;
    updateCurrentShop(newShop);
  }

  const filteredShops = user?.shops.filter(
    (shop) =>
      shop.state !== SHOP_STATES.PENDING &&
      shop.shopUserState !== SHOP_STATES.PENDING
  );
  const shops = filteredShops?.map((shop) => {
    return { name: shop.shopName, id: shop.shopID };
  });
  const sortedShops = _sortBy(shops, 'name');

  const hasManyShops = shops && shops.length > 1;
  const shopOptions = hasManyShops
    ? sortedShops?.map(({ name, id }) => (
        <option key={id} value={id}>
          {TextOnly('shop')} : {name}
        </option>
      ))
    : [];

  return (
    <>
      {hasManyShops ? (
        <div className="c-select">
          <select
            value={currentShop?.shopID || undefined}
            onChange={handleShopChange}
            id="tools"
          >
            {shopOptions}
          </select>
        </div>
      ) : null}
    </>
  );
}
