import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import LoaderButton from '../../../components/LoaderButton';
import AddCustomerForm from './AddCustomerForm';
import { Dialog } from '@reach/dialog';

const AddCustomer: React.FC<any> = (props) => {
  const { currentShop, getAllCustomers} = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCancelModal = () => {
    if (!isLoading) {
      document.querySelector('.c-modal-slider')?.classList.add('closed');
      setTimeout(() => {
        setShowModal(false);
      }, 350);
    }
  };

  return (
    <div>
      <LoaderButton
        text={TextOnly('addCustomer')}
        icon={<i className="c-btn__icon fal fa-plus" />}
        onClick={() => setShowModal(true)}
        className='textwrap-nowrap'
      />
      <Dialog
        isOpen={showModal}
        className="c-modal-slider"
        id={TextOnly('addCustomer')}
        aria-label={TextOnly('addCustomer')}
        onDismiss={()=>handleCancelModal()}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={()=>handleCancelModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">{TextOnly('addCustomer')}</h1>
        <AddCustomerForm
          currentShop={currentShop}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          getAllCustomers={getAllCustomers}
          handleCloseModal={handleCancelModal}
        />
      </Dialog>
    </div>
  );
};

export default AddCustomer;
