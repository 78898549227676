import React, { useEffect, useState, ChangeEvent } from 'react';
import { Text, TextOnly } from '../../components/Text';
import { formatDateTime } from '../../libs/utils';
import LoaderButton from '../../components/LoaderButton';
import { Loading } from '../../components/Loading';
import { Header } from '../../components/Header';
import { useParams } from 'react-router-dom';
import { getSingleOwnerVerification, updateOwnerVerification, rejectVerificationRequest, techSubmitSignatureDate, shopOwnerSubmitSignatureDate, techGeneratePdfVerification, driverSubmitSignatureDate } from '../../libs/db-lib';
import { toast } from 'react-toastify';
import { statesUSA } from '../../components/StatePicker';
import { Dialog } from '@reach/dialog';
import SignatureCanvas from '../../components/Signature';

interface RouteParams {
  verificationID: string;
}

const DetailedOwnerVerification = (props: any) => {
  const { history, currentShop } = props;
  const stateOptions = Object.keys(statesUSA).sort();
  const { verificationID } = useParams<RouteParams>();

  const [verification, setVerification] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLock, setIsLock] = useState<boolean>(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState<boolean>(false);
  const [isLoadingReject, setIsLoadingReject] = useState<boolean>(false);
  const [isLoadingSubmitSignDate, setIsLoadingSubmitSignDate] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isRejected, setIsRejected] = useState<boolean>(false);
  const [vehicleColor, setVehicleColor] = useState<string>('');
  const [vehicleStateRegistration, setVehicleStateRegistration] = useState<string>('');
  const [vehicleLPN, setVehicleLPN] = useState<string>('');
  const [vehicleOdometer, setVehicleOdometer] = useState<string>('');
  const [lsid, setLsid] = useState<string>('');
  const [odometerNotAvailable, setOdometerNotAvailable] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showOdometerError, setShowOdometerError] = useState<boolean>(false);
  const [signatureUrlTech, setSignatureUrlTech] = useState<any>();
  const [signTech, setSignTech] = useState<any>();
  const [signatureUrlDriver, setSignatureUrlDriver] = useState<any>();
  const [signDriver, setSignDriver] = useState<any>();
  const [signatureUrlOwner, setSignatureUrlOwner] = useState<any>();
  const [signOwner, setSignOwner] = useState<any>();

  const handleClearTech = () => {
    signTech?.clear();
    setSignatureUrlTech(undefined);
  };
  const handleSaveTech = () => setSignatureUrlTech(signTech?.getTrimmedCanvas().toDataURL('image/png'));

  const handleClearDriver = () => {
    signDriver?.clear();
    setSignatureUrlDriver(undefined);
  };
  const handleSaveDriver = () => setSignatureUrlDriver(signDriver?.getTrimmedCanvas().toDataURL('image/png'));

  const handleClearOwner = () => {
    signOwner?.clear();
    setSignatureUrlOwner(undefined);
  };
  const handleSaveOwner = () => setSignatureUrlOwner(signOwner?.getTrimmedCanvas().toDataURL('image/png'));

  const handleUpdateOdometerNotAvailable = (event: ChangeEvent<HTMLInputElement>) => {
    setOdometerNotAvailable(event.target.checked);
    setShowOdometerError(false);
    if(event.target.checked) {
      setVehicleOdometer('N/A');
    } else {
      setVehicleOdometer('');
    }
  };
  const handleCloseModal = () => {
    if(!isLoadingReject) setShowRejectModal(false);
  };
  const handleRejectVerification = async (rejecting: boolean) => {
    setIsLoadingReject(true);
    const response = await rejectVerificationRequest(currentShop.shopID, rejecting, verification.verificationID);
    setIsLoadingReject(false);
    if(!response.error) {
      toast.success(
        TextOnly(rejecting ? 'verificationRejected' : 'verificationRecovered'),
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
      history.push(`/vehicleOwnerVerification`);
    } else {
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
  }
  const handleTechSubmitSignature = async () => {
    if(!signatureUrlTech) {
      toast.error(
        `${TextOnly('error')}: ${TextOnly('pleaseEnterSignature')}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
      return;
    }
    setIsLoadingSubmitSignDate(true);
    const response = await techSubmitSignatureDate(currentShop.shopID, verification.verificationID, signatureUrlTech, lsid);
    setIsLoadingSubmitSignDate(false);
    if(!response.error) {
      await getVerification();
    } else {
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
  }
  const handleDriverSubmitSignature = async () => {
    if(!signatureUrlDriver) {
      toast.error(
        `${TextOnly('error')}: ${TextOnly('pleaseEnterSignature')}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
      return;
    }
    setIsLoadingSubmitSignDate(true);
    const response = await driverSubmitSignatureDate(currentShop.shopID, verification.verificationID, signatureUrlDriver);
    setIsLoadingSubmitSignDate(false);
    if(!response.error) {
      await getVerification();
    } else {
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
  }
  const handleOwnerSubmitSignature = async () => {
    if(!signatureUrlOwner) {
      toast.error(
        `${TextOnly('error')}: ${TextOnly('pleaseEnterSignature')}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
      return;
    }
    setIsLoadingSubmitSignDate(true);
    const response = await shopOwnerSubmitSignatureDate(currentShop.shopID, verification.verificationID, signatureUrlOwner);
    setIsLoadingSubmitSignDate(false);
    if(!response.error) {
      await getVerification();
    } else{
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
  }
  const handleUpdateVehicleOdometer = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value.trim();
    if(isNaN(newValue)) {
      setShowOdometerError(true);
      return;
    } else {
      setShowOdometerError(false);
      setVehicleOdometer(newValue);
    }
  };

  const getVerification = async () => {
    setIsLoading(true);
    const response = await getSingleOwnerVerification(
      currentShop.shopID,
      verificationID
    );
    if (!response.error) {
      setVerification(response);
      setVehicleColor(response.vehicleColor ?? '');
      setVehicleLPN(response.vehicleLPN ?? '');
      setVehicleStateRegistration(response.vehicleStateRegistration ?? '');
      setVehicleOdometer(response.vehicleOdometer ?? '');
      setLsid(response.lsid ?? '');
      setOdometerNotAvailable(response.vehicleOdometer === 'N/A');
      setShowOdometerError(false);
      setIsRejected(
        response?.verificationStatus?.startsWith("REJECT") || response?.verificationStatus?.startsWith("CANCELED")
      );
      setIsLock(
        response?.driverSignature ||
        response?.techSignature
      );
      setIsLoading(false);
    } else {
      console.log(response)
      toast.error(
        `${TextOnly('error')}: ${response?.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
      history.push('/vehicleOwnerVerification');
    }
  };

  const validateUpdate = () => {
    if (
      (verification.vehicleColor !== vehicleColor && vehicleColor.length > 0) ||
      (verification.vehicleStateRegistration !== vehicleStateRegistration && vehicleStateRegistration.length > 0) ||
      (verification.vehicleLPN !== vehicleLPN && vehicleLPN.length > 0) ||
      (verification.vehicleOdometer !== vehicleOdometer && vehicleOdometer.length > 0)
    ) return false;
    else return true;
  }

  const handleUpdate = async () => {
    setIsLoadingUpdate(true);
    const response = await updateOwnerVerification(
      vehicleColor,
      vehicleStateRegistration,
      vehicleLPN,
      vehicleOdometer,
      currentShop.shopID,
      verification.verificationID,
    );
    setIsLoadingUpdate(false);
    if(!response.error) {
      await getVerification();
    } else {
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
  }

  const handleGeneratePDF = async () => {
    setIsLoadingPDF(true);
    // Make backend call to get modified PDF bytes
    const response = await techGeneratePdfVerification(currentShop.shopID, verificationID as string);
    if(!response.error) {
      // Create File Name
      const fileDriverName = verification.driverName.replace(/\s/g, "_");
      const fileDate = verification.createdOn.split('T')[0];
      const fileName = `${fileDriverName}_${verification.vin}_${fileDate}_D1Form.pdf`;
      // Convert to Uint8Array
      const uint8Array = new Uint8Array(Object.values(response));
      // Create a Blob from Uint8Array
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      // Create a download link and trigger a download
      const downloadLink = document.createElement('a');
      // Create URL
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = fileName;
      // Append the link to the document and trigger a click
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Clean up: remove the link and revoke the URL
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
      toast.success(
        `${TextOnly('d1FormDownloaded')}`, {
        containerId: 'standard',
        autoClose: false,
      });
    } else {
      console.error('Error generating PDF verification:', response.error);
      toast.error(
        `${TextOnly('error')}: ${response.error}`,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoadingPDF(false);
  }

  useEffect(() => {
    getVerification();
  }, []);

  useEffect(() => {
    if(signatureUrlTech) handleTechSubmitSignature();
  }, [signatureUrlTech]);
  useEffect(() => {
    if(signatureUrlDriver) handleDriverSubmitSignature();
  }, [signatureUrlDriver]);
  useEffect(() => {
    if(signatureUrlOwner) handleOwnerSubmitSignature();
  }, [signatureUrlOwner]);

  if (isLoading) return <Loading />;
  return (
    <div className="l-container">
      <Header title={TextOnly('detailedOwnerVerification')} />
      <LoaderButton text={TextOnly('goBack')} className='marginbottom-10' onClick={()=>history.push(`/vehicleOwnerVerification`)}/>
      <div className="c-box">
        <div className="l-flex-between overflow-x-auto">
          <section className="c-section-alt u-width-100-percent flex-space-between">
            <div className="c-section-alt__content">
              <label className="c-section-alt__label">
                <Text tid="servicePerformed" />
              </label>
              <span className="c-section-alt__value u-font-weight-bold">
                {TextOnly(verification.servicePerformed)}
              </span>
            </div>
            <div className="c-section-alt__content">
              <label className="c-section-alt__label">
                <Text tid="vehicleOwner" />
              </label>
              <span className="c-section-alt__value u-font-weight-bold">
                {verification.driverName}
              </span>
            </div>
            <div className="c-section-alt__content">
              <label className="c-section-alt__label">
                <Text tid="date" />
              </label>
              <span className="c-section-alt__value u-font-weight-bold">
                {formatDateTime(verification.createdOn)}
              </span>
            </div>
            <div className="c-section-alt__content">
              <label className="c-section-alt__label">
                <Text tid="vehicle" />
              </label>
              <span className="c-section-alt__value u-font-weight-bold">
                {verification.vehicleMake} {verification.vehicleModel}{' '}
                {verification.vehicleYear}
              </span>
            </div>
          </section>
        </div>
      </div>

      <div className="c-notice  c-notice--column">
        <h2 className="c-notice__title u-text-error u-padding-none">
          <Text tid="verificationStatus"/>
        </h2>
        { (
          verification.verificationStatus === 'COMPLETED'
          ) ?
          <>
            <h2 className="c-notice__title l-margin-top-2rem">
              <Text tid="verificationCompleted" />
            </h2>
            <div className="u-width-100-percent display-flex-justify-content-center">
              <LoaderButton text={TextOnly('generateD1Form')} onClick={()=>handleGeneratePDF()} disabled={isLoadingPDF} isLoading={isLoadingPDF} loadingText={TextOnly('loading')} />
            </div>
          </>
          :
          <h2 style={{marginTop:'1rem', marginBottom: '0'}}>
            <Text tid={verification.verificationStatus} />
          </h2>
        }
      </div>

      { (
        verification?.verificationStatus?.startsWith("REJECT") || verification?.verificationStatus?.startsWith("CANCELED")
      ) ?
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title u-text-error u-padding-none">
            <Text tid="verificationRejectedBy"
              sub={{rejectedBy:
                verification?.verificationStatus?.includes("DRIVER") ?
                <>
                  {TextOnly('vehicleOwner')}
                </>
                :
                <>
                  {TextOnly('technician')}
                </>
              }}
            />
          </h2>
        { (isRejected && verification.verificationStatus === 'CANCELED_BY_DRIVER') &&
          <div className='u-width-100-percent display-flex-justify-content-center u-margin-top-small'>
            <LoaderButton text={TextOnly('confirmRejection')} onClick={()=>handleRejectVerification(true)} className='red-btn' isLoading={isLoadingReject} loadingText={TextOnly('loading')} disabled={isLoadingReject}/>
          </div>
        }
        { (isRejected && verification.verificationStatus === 'CANCELED_BY_TECH') &&
          <div className='u-width-100-percent display-flex-justify-content-center u-margin-top-small'>
            <LoaderButton text={TextOnly('recoverVerification')} onClick={()=>handleRejectVerification(false)} className='red-btn' isLoading={isLoadingReject} loadingText={TextOnly('loading')} disabled={isLoadingReject}/>
          </div>
        }
        </div>
      : (
        !verification.driverName ||
        !verification.driverStreetAddress ||
        !verification.driverCityStateZipCode ||
        !verification.driverLicenseNumber ||
        !verification.driverLicenseState ||
        !verification.driverLicenseExpirationDate ||
        !verification.vehicleColor ||
        !verification.vehicleLPN ||
        !verification.vehicleStateRegistration ||
        !verification.vehicleOdometer ||
        !verification.driverSignature ||
        !verification.driverSignDate ||
        !verification.ownerSignature ||
        !verification.ownerSignDate ||
        !verification.techSignature ||
        !verification.techSignDate
      ) ?
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title u-text-error">
            <Text tid="PENDING" />
          </h2>
          <div className="u-width-100-percent">
            { (
              !verification.driverName ||
              !verification.driverStreetAddress ||
              !verification.driverCityStateZipCode ||
              !verification.driverLicenseNumber ||
              !verification.driverLicenseState ||
              !verification.driverLicenseExpirationDate
            ) &&
              <>
                <div className="display-flex alignitems-baseline">
                  <label className="c-section-alt__label textwrap-nowrap u-font-weight-bold">
                    <Text tid="vehicleOwnerInformation" />:
                  </label>
                </div>
                <div className="u-margin-left c-field">
                  <span className="margin-bottom-2rem u-margin-left-large u-text-error">
                    * {TextOnly('waitingVehicleOwner')}.
                  </span>
                </div>
              </>
            }
            { (
              !verification.vehicleColor ||
              !verification.vehicleLPN ||
              !verification.vehicleStateRegistration ||
              !verification.vehicleOdometer
            ) &&
              <>
                <div className="display-flex alignitems-baseline">
                  <label className="c-section-alt__label textwrap-nowrap u-font-weight-bold">
                    <Text tid="vehicleInformation" />:
                  </label>
                </div>
                <div className="u-margin-left c-field">
                  <span className="margin-bottom-2rem u-margin-left-large u-text-error">
                    * {
                      [
                        !verification.vehicleColor ? ` ${TextOnly('vehicleColor')}` : '',
                        !verification.vehicleLPN ? ` ${TextOnly('vehicleLPN')}` : '',
                        !verification.vehicleStateRegistration ? ` ${TextOnly('vehicleStateRegistration')}` : '',
                        !verification.vehicleOdometer ? ` ${TextOnly('vehicleOdometer')}` : ''
                      ].filter(Boolean).join(',')
                      }.
                  </span>
                </div>
              </>
            }
            { (
              (verification.driverName &&
              verification.driverStreetAddress &&
              verification.driverCityStateZipCode &&
              verification.driverLicenseNumber &&
              verification.driverLicenseState &&
              verification.driverLicenseExpirationDate &&
              verification.vehicleColor &&
              verification.vehicleLPN &&
              verification.vehicleStateRegistration &&
              verification.vehicleOdometer)
              &&
              (!verification.driverSignature ||
              !verification.driverSignDate ||
              !verification.techSignature ||
              !verification.techSignDate)
            ) &&
              <>
                <div>
                  <label className="c-section-alt__label textwrap-nowrap u-font-weight-bold">
                    <Text tid="signaturesAndDates" />:
                  </label>
                </div>
                { (!verification.driverSignature || !verification.driverSignDate) &&
                  <div className="u-margin-left c-field">
                    <span className="margin-bottom-2rem u-margin-left-large u-text-error">
                      * {TextOnly('vehicleOwnerSignature')}.
                    </span>
                  </div>
                }
                { (!verification.techSignature || !verification.lsid) &&
                  <div className="u-margin-left c-field">
                    <div>
                      <span className="margin-bottom-2rem u-margin-left-large u-text-error">
                        * {TextOnly('technicianSignature')}.
                      </span>
                    </div>
                    <div>
                      <span className="margin-bottom-2rem u-margin-left-large u-text-error">
                        * {TextOnly('lockSmithID')}.
                      </span>
                    </div>
                  </div>
                }
              </>
            }
            { (
              (verification.driverName &&
              verification.driverStreetAddress &&
              verification.driverCityStateZipCode &&
              verification.driverLicenseNumber &&
              verification.driverLicenseState &&
              verification.driverLicenseExpirationDate &&
              verification.vehicleColor &&
              verification.vehicleLPN &&
              verification.vehicleStateRegistration &&
              verification.vehicleOdometer &&
              verification.driverSignature &&
              verification.driverSignDate &&
              verification.techSignature &&
              verification.techSignDate
              )
              &&
              (!verification.ownerSignature ||
              !verification.ownerSignDate)
            ) &&
              <>
                <div>
                  <label className="c-section-alt__label textwrap-nowrap u-font-weight-bold">
                    <Text tid="signaturesAndDates" />:
                  </label>
                </div>
                <div className="u-margin-left c-field">
                  <span className="margin-bottom-2rem u-margin-left-large u-text-error">
                    * {TextOnly('shopOwnerSignature')}.
                  </span>
                </div>
              </>
            }
          </div>
        </div>
      : null //TODO
      }

      {(
          (verification.driverName &&
          verification.driverStreetAddress &&
          verification.driverCityStateZipCode &&
          verification.driverLicenseNumber &&
          verification.driverLicenseState &&
          verification.driverLicenseExpirationDate &&
          verification.vehicleColor &&
          verification.vehicleLPN &&
          verification.vehicleStateRegistration &&
          verification.vehicleOdometer)
          &&
          (!verification.techSignature ||
          !verification.techSignDate)
        ) &&
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title">
            <Text tid="technicianSignature" />
          </h2>
          <div>
              <SignatureCanvas
                velocityFilterWeight={1}
                penColor='#0d0d7d'
                canvasProps={{
                  width: "400",
                  height: 150,
                  className: "sigCanvas signBox",
                }}
                ref={(data)=>setSignTech(data)}
              />
            <div className='display-flex u-justify-space-around u-margin-top-small u-margin-bottom-small'>
              <LoaderButton type="button" text={TextOnly('clear')} onClick={()=>handleClearTech()} disabled={isLoadingSubmitSignDate}/>
            </div>
          </div>
          <div className='u-width-100-percent'>
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <span className="u-text-error">*</span>{' '}
                <Text tid="lsid" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <input
                type="text"
                className={`c-input ${verification.lsid ? 'non-editable' : ''} u-font-weight-bold`}
                value={lsid}
                placeholder={TextOnly('lockSmithID')+'...'}
                onChange={(event)=>setLsid(event.target.value)}
                readOnly={verification.lsid}
                disabled={verification.lsid}
              />
              { lsid.length === 0 &&
                <div className='c-field__error'>{TextOnly('lockSmithID')}</div>
              }
            </div>
          </div>
          <div className="u-width-100-percent display-flex-justify-content-center align-items-center flex-flow-column u-margin-top-large">
            <div>
              <LoaderButton
                text={TextOnly('signAndDate')}
                onClick={()=>handleSaveTech()}
                disabled={!lsid || isLoadingSubmitSignDate}
                isLoading={isLoadingSubmitSignDate}
                loadingText='Submitting'
              />
            </div>
          </div>
        </div>
      }

      {(
          (
            verification.verifyInPerson &&
            verification.techSignature &&
            verification.driverName &&
            verification.driverStreetAddress &&
            verification.driverCityStateZipCode &&
            verification.driverLicenseNumber &&
            verification.driverLicenseState &&
            verification.driverLicenseExpirationDate &&
            verification.vehicleColor &&
            verification.vehicleLPN &&
            verification.vehicleStateRegistration &&
            verification.vehicleOdometer
          )
          &&
          (!verification.driverSignature)
        ) &&
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title">
            <Text tid="vehicleOwnerSignature" />
          </h2>
          <div>
              <SignatureCanvas
                velocityFilterWeight={1}
                penColor='#0d0d7d'
                canvasProps={{
                  width: "400",
                  height: 150,
                  className: "sigCanvas signBox",
                }}
                ref={(data)=>setSignDriver(data)}
              />
            <div className='display-flex u-justify-space-around u-margin-top-small u-margin-bottom-small'>
              <LoaderButton type="button" text={TextOnly('clear')} onClick={()=>handleClearDriver()} disabled={isLoadingSubmitSignDate}/>
              <LoaderButton
                text={TextOnly('signAndDate')}
                onClick={()=>handleSaveDriver()}
                disabled={isLoadingSubmitSignDate}
                isLoading={isLoadingSubmitSignDate}
                loadingText='Submitting'
              />
            </div>
          </div>
        </div>
      }

      {(
          (verification.driverName &&
          verification.driverStreetAddress &&
          verification.driverCityStateZipCode &&
          verification.driverLicenseNumber &&
          verification.driverLicenseState &&
          verification.driverLicenseExpirationDate &&
          verification.vehicleColor &&
          verification.vehicleLPN &&
          verification.vehicleStateRegistration &&
          verification.techSignature &&
          verification.techSignDate &&
          verification.driverSignature &&
          verification.driverSignDate &&
          verification.vehicleOdometer)
          &&
          (!verification.ownerSignature ||
          !verification.ownerSignDate)
          &&
          (props.user.shopRole === 'OWNER')
        ) &&
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title">
            <Text tid="shopOwnerSignatureDate" />
          </h2>
          <div>
              <SignatureCanvas
                velocityFilterWeight={1}
                penColor='#0d0d7d'
                canvasProps={{
                  width: "400",
                  height: 150,
                  className: "sigCanvas signBox",
                }}
                ref={(data)=>setSignOwner(data)}
              />
            <div className='display-flex u-justify-space-around u-margin-top-small u-margin-bottom-small'>
              <LoaderButton type="button" text={TextOnly('clear')} onClick={()=>handleClearOwner()} disabled={isLoadingSubmitSignDate}/>
              <LoaderButton
                text={TextOnly('signAndDate')}
                onClick={()=> {
                  handleSaveOwner()}
                }
                isLoading={isLoadingSubmitSignDate}
                loadingText='Submitting'
              />
            </div>
          </div>
        </div>
      }

      {verification.ownerSignature &&
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title">
            <Text tid="shopOwnerSignatureDate" />
          </h2>
          <div className="u-width-100-percent">
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="shopOwnerSignature" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <div className='c-signature'>
                <img alt='signature' src={verification.ownerSignature}/>
              </div>
            </div>
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="shopOwnerSignedDate" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <input
                type="text"
                className={`c-input non-editable u-font-weight-bold`}
                value={formatDateTime(verification.ownerSignDate)}
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      }

      {verification.techSignature &&
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title">
            <Text tid="techSignatureDate" />
          </h2>
          <div className="u-width-100-percent">
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="technicianSignature" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <div className='c-signature'>
                <img alt='signature' src={verification.techSignature}/>
              </div>
            </div>
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="technicianSignedDate" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <input
                type="text"
                className={`c-input non-editable u-font-weight-bold`}
                value={formatDateTime(verification.techSignDate)}
                readOnly={true}
                disabled={true}
              />
            </div>
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="lsid" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <input
                type="text"
                className={`c-input non-editable u-font-weight-bold`}
                value={verification.lsid}
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      }

      {verification.driverSignature &&
        <div className="c-notice  c-notice--column">
          <h2 className="c-notice__title">
            <Text tid="driverSignatureDate" />
          </h2>
          <div className="u-width-100-percent">
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="vehicleOwnerSignature" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <div className='c-signature'>
                <img alt='signature' src={verification.driverSignature}/>
              </div>
            </div>
            <div className="display-flex alignitems-baseline">
              <label className="c-section-alt__label textwrap-nowrap">
                <Text tid="driverSignedDate" />:
              </label>
            </div>
            <div className="u-margin-left c-field">
              <input
                type="text"
                className={`c-input non-editable u-font-weight-bold`}
                value={formatDateTime(verification.driverSignDate)}
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      }

      <div className="c-notice  c-notice--column">
        <h2 className="c-notice__title">
          <Text tid="vehicleOwnerInformation" />
        </h2>
        <div className="u-width-100-percent">
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              {`${TextOnly('vehicleOwner')} - ${TextOnly('name')}`}:
            </label>
          </div>
          <div className="u-margin-left c-field">
          <label className={`u-margin-left-large ${ verification.driverName ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`} >
              {verification.driverName || `${TextOnly('vehicleOwner')} - ${TextOnly('name')}`}
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="streetAddress" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
          <label className={`u-margin-left-large ${ verification.driverStreetAddress ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`}>
            {verification.driverStreetAddress ?? `${TextOnly('waitingVehicleOwner')}...` }
          </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="city" /> / <Text tid="state" /> /{' '}
              <Text tid="zipCode" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large ${ verification.driverCityStateZipCode ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`}>
              {verification.driverCityStateZipCode ?? `${TextOnly('waitingVehicleOwner')}...` }
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="phoneNumber" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large ${ verification.driverPhone ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`}>
              {verification.driverPhone ?? `${TextOnly('waitingVehicleOwner')}...` }
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="driversLicenseNumber" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large ${ verification.driverLicenseNumber ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`}>
              {verification.driverLicenseNumber ?? `${TextOnly('waitingVehicleOwner')}...` }
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="driversLicenseState" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large ${ verification.driverLicenseState ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`}>
              {verification.driverLicenseState ?? `${TextOnly('waitingVehicleOwner')}...` }
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="driversLicenseExpirationDate" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large ${ verification.driverLicenseExpirationDate ? 'u-font-weight-bold' : 'c-placeholder font-style-italic'}`}>
              {verification.driverLicenseExpirationDate ?? `${TextOnly('waitingVehicleOwner')}...` }
            </label>
          </div>
        </div>
      </div>

      <div className="c-notice  c-notice--column">
        <h2 className="c-notice__title">
          <Text tid="vehicleInformation" />
        </h2>
        <div className="u-width-100-percent">
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vin" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large u-font-weight-bold`}>
              {verification.vin}
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleYear" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large u-font-weight-bold`}>
              {verification.vehicleYear}
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleMake" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large u-font-weight-bold`}>
              {verification.vehicleMake}
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleModel" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <label className={`u-margin-left-large u-font-weight-bold`}>
              {verification.vehicleModel}
            </label>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleColor" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <input
              type="text"
              className={`c-input non-editable ${ (verification.vehicleColor && verification.vehicleColor === vehicleColor) ? 'u-font-weight-bold' : ''}`}
              placeholder={TextOnly('vehicleColor') + '...'}
              autoCapitalize="off"
              value={vehicleColor}
              onChange={(event)=>setVehicleColor(event.target.value)}
              disabled={isRejected || isLock}
            />
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleLPN" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <input
              type="text"
              className={`c-input non-editable ${ (verification.vehicleLPN && verification.vehicleLPN === vehicleLPN) ? 'u-font-weight-bold' : ''}`}
              placeholder={TextOnly('vehicleLPN') + '...'}
              autoCapitalize="off"
              value={vehicleLPN}
              onChange={(event)=>setVehicleLPN(event.target.value)}
              disabled={isRejected || isLock}
            />
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleStateRegistration" />:
            </label>
          </div>
          {!verification.vehicleStateRegistration ?
          <div className="u-margin-left c-field">
            <div className={`c-select background-white`}>
              <select value={vehicleStateRegistration} onChange={(event)=>setVehicleStateRegistration(event.target.value)} disabled={isRejected || isLock}>
                <option value="" disabled>{TextOnly('vehicleStateRegistration')}</option>
                {stateOptions.map((state: any)=> <option value={statesUSA[state]} key={state}>{statesUSA[state]}</option>)}
              </select>
            </div>
          </div>
          :<div className="u-margin-left c-field">
            <input
              type="text"
              className={`c-input non-editable u-font-weight-bold`}
              placeholder={TextOnly('vehicleStateRegistration') + '...'}
              autoCapitalize="off"
              value={verification.vehicleStateRegistration}
              onChange={()=>null}
              disabled={true}
            />
          </div>
          }
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="vehicleOdometer" />:
            </label>
            <input
              type="checkbox"
              checked={odometerNotAvailable}
              onChange={handleUpdateOdometerNotAvailable}
              disabled={isRejected || isLock}
            />
            {TextOnly('notAvailable')}
          </div>
          <div className="u-margin-left c-field">
            <input
              type={'text'}
              className={`c-input non-editable ${ (verification.vehicleOdometer && verification.vehicleOdometer === vehicleOdometer) ? 'u-font-weight-bold' : ''}`}
              placeholder={TextOnly('vehicleOdometer') + '...'}
              autoCapitalize="off"
              value={vehicleOdometer}
              disabled={odometerNotAvailable || isRejected || isLock}
              onChange={handleUpdateVehicleOdometer}
            />
            { showOdometerError &&
              <div className='c-field__error'>{TextOnly('odometerInputError')}</div>
            }
          </div>
        </div>
        { (!isRejected && !isLock) &&
          <LoaderButton
            type="submit"
            disabled={validateUpdate() || isLoadingUpdate || isRejected || isLock}
            isLoading={isLoadingUpdate}
            text={TextOnly('update')}
            loadingText={TextOnly('updating')}
            onClick={()=>handleUpdate()}
          />
        }
      </div>

      <div className="c-notice  c-notice--column">
        <h2 className="c-notice__title">
          <Text tid="shopInformation" />
        </h2>
        <div className="u-width-100-percent">
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="serviceProviderBusinessName" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <span className="margin-bottom-2rem u-margin-left-large u-font-weight-bold">
              {verification.shopName}
            </span>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="phoneNumber" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <span className="margin-bottom-2rem u-margin-left-large u-font-weight-bold">
              {verification.shopPhoneNumber}
            </span>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="streetAddress" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <span className="margin-bottom-2rem u-margin-left-large u-font-weight-bold">
              {verification.shopStreetAddress}
            </span>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="city" /> / <Text tid="state" /> /{' '}
              <Text tid="zipCode" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
            <span className="margin-bottom-2rem u-margin-left-large u-font-weight-bold">
              {verification.shopCityStateZipCode}
            </span>
          </div>
          <div className="display-flex alignitems-baseline">
            <label className="c-section-alt__label textwrap-nowrap">
              <Text tid="technicianName" />:
            </label>
          </div>
          <div className="u-margin-left c-field">
          <span className="margin-bottom-2rem u-margin-left-large u-font-weight-bold">
              {verification.techFirstName} {verification.techLastName}
            </span>
          </div>
        </div>
      </div>
      { (!isRejected && !verification.techSignature) &&
        <div className='u-width-100-percent display-flex-justify-content-center margin-bottom-8rem'>
          <LoaderButton text={TextOnly('clickReject')} onClick={()=>setShowRejectModal(true)} className='red-btn'/>
        </div>
      }
      <Dialog
        isOpen={showRejectModal}
        onDismiss={()=>handleCloseModal()}
        className="c-modal"
        id="confirmRejectVerificationRequest"
        aria-label={TextOnly('confirmRejectVerificationRequest')}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={()=>handleCloseModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="confirmRejectVerificationRequest" />
        </h1>

        <div className="c-modal__body">
          <p>
            <Text tid="areYouSureRejectVerification" />
          </p>
          <div className='display-flex-justify-content-center' >
            <LoaderButton
              type="button"
              onClick={()=>handleRejectVerification(true)}
              isLoading={isLoadingReject}
              text={TextOnly('confirm')}
              loadingText={TextOnly('loading')}
              disabled={isLoadingReject}
            />{' '}
            <button className="c-btn-outline u-margin-left-small" onClick={()=>handleCloseModal()}>
              <Text tid="cancel" />
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DetailedOwnerVerification;
