import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import CustomDatePicker from '../../components/CustomDatePicker';
import moment from 'moment';
import { toolSerialNoValidate } from '../../libs/utils';
import { updateSignupLinkForShop } from '../../libs/db-lib';
import LoaderButton from '../../components/LoaderButton';
import ReactTable from 'react-table';
import { Text, TextOnly } from '../../components/Text';
import 'react-table/react-table.css';
import { toast } from 'react-toastify';
import { SHOP_TYPES } from '../../CONSTANTS';
import { Loading } from '../../components/Loading';
export default class EditSignupLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serialNumber: '',
      manufacturer: '',
      multiManufacturer: '',
      multiModel: '',
      model: '',
      alertMessage: '',
      showModal: false,
      currentShop: props.currentShop,
      toolPreviewList: [],
      filter: '',
      shopTools: null,
      shopToolsFiltered: [],
      toolActions: [],
      validityCriteria: SHOP_TYPES.STANDARD,
      validityParams: {
        startDate: moment.utc().startOf('day'),
        endDate: moment.utc().endOf('day'),
      },
      name: '',
      startDate: moment.utc().startOf('month').startOf('day'),
      endDate: moment.utc().endOf('month').endOf('day'),
      noOfAdditionalFields: 0,
      additionalFields: [],
    };
  }

  componentDidMount() {
    let linkRec = this.props.link;
    // Set initial state of fields
    if (!this.state.linkID && linkRec) {
      this.setState({
        name: linkRec.friendlyName,
        linkState: linkRec.linkState,
        linkType: linkRec.linkType,
        validityCriteria: linkRec.linkType,
        startDate: moment(linkRec.startDate),
        endDate: moment(linkRec.endDate),
        additionalFields: linkRec.additionalIDFields,
        noOfAdditionalFields: linkRec.additionalIDFields.length,
        linkID: linkRec.linkID,
      });
    }
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    return (
      this.state.name.length > 0 && !this.state.additionalFields.includes('')
    );
  }

  handleRangeChange = (value) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
  };

  handleAddFieldsChange = (index, event) => {
    let af = this.state.additionalFields;
    af[index] = event.target.value;
    this.setState({
      additionalFields: af,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleAddField = (event) => {
    let newNoOfFields = this.state.noOfAdditionalFields + 1;
    let additionalFields = this.state.additionalFields;
    additionalFields.push('');
    this.setState({
      noOfAdditionalFields: newNoOfFields,
      additionalFields: additionalFields,
    });
  };

  handleRemoveFieldsChange = (index, event) => {
    let af = this.state.additionalFields;
    af.splice(index, 1);
    this.setState({
      additionalFields: af,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let data = this.state;

    for (let field of data.additionalFields) {
      if (field.trim().length === 0) {
        data.additionalFields = data.additionalFields.filter(
          (element) => element !== field
        );
      }
    }

    let result = await updateSignupLinkForShop(
      data.currentShop.shopID,
      data.linkID,
      data.name,
      data.validityCriteria,
      data.linkState,
      data.additionalFields,
      data.startDate,
      data.endDate
    );
    if (result?.error) {
      this.props.handleShowAlert(TextOnly('error'), result.error, true);
    } else {
      let newLinkRec = {
        friendlyName: data.name,
        startDate: data.validityCriteria === 'TIME' && data.startDate,
        endDate: data.validityCriteria === 'TIME' && data.endDate,
        additionalFields: data.additionalFields,
        linkType: data.validityCriteria,
        linkID: data.linkID,
      };

      this.props.handleEditSignupLink(newLinkRec);
      toast.success(TextOnly('signupLinkUpdated'), { containerId: 'standard' });
      this.setState({ isLoading: false });
      this.props.onDismiss();
    }
  };

  render() {
    let startDate = this.state.startDate
      ? this.state.startDate
      : moment.utc().startOf('month').startOf('day');
    let endDate = this.state.endDate
      ? this.state.endDate
      : moment.utc().endOf('month').endOf('day');

    let additionalFieldsInput = [];
    additionalFieldsInput.push(
      this.state.additionalFields.map((field, index) => {
        return (
          <div className="c-field" key={index}>
            <label htmlFor="fieldName" className="c-field__label">
              <Text tid="fieldName" />
            </label>
            <div
              controlId="additionalFields"
              className="c-field__content-wrapper"
            >
              <input
                id="fieldName"
                className="c-input"
                type="text"
                maxLength="100"
                placeholder={TextOnly('fieldName')}
                value={this.state.additionalFields[index]}
                onChange={this.handleAddFieldsChange.bind(this, index)}
              />
              <button
                className="c-btn-icon"
                onClick={this.handleRemoveFieldsChange.bind(this, index)}
              >
                <div className="c-btn__inner">
                  <i
                    className="c-btn__icon fal fa-times"
                    title={<Text tid="removeCustomField" />}
                  />
                </div>
              </button>
            </div>
            {this.state.additionalFields[index].length === 0 ? (
              <div className="c-field__error">
                <Text tid="fieldNameCannotBeBlank" />
              </div>
            ) : null}
          </div>
        );
      })
    );

    const { isOpen, onDismiss } = this.props;

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <Dialog
        isOpen={isOpen}
        onDismiss={onDismiss}
        className="c-modal-slider"
        id="editSignupLink"
        aria-label={TextOnly('editSignupLink')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={onDismiss}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1>
          <Text tid="editSignupLink" />
        </h1>
        {this.state.linkID ? (
          <form onSubmit={this.handleSubmit}>
            <div className="c-field">
              <label htmlFor="name" className="c-field__label">
                <Text tid="linkName" />
              </label>
              <input
                id="name"
                className="c-input"
                type="text"
                maxLength="100"
                placeholder={TextOnly('name')}
                value={this.state.name}
                onChange={this.handleChange}
              />
              {this.state.verifyName &&
              !toolSerialNoValidate(this.state.name) ? (
                <div className="c-field__error">
                  <Text tid="fieldNameCannotBeBlank" />
                </div>
              ) : null}
            </div>
            <div>
              <label htmlFor="validityCriteria" className="c-field__label">
                <Text tid="validityCriteria" />
              </label>
              <select
                value={this.state.validityCriteria}
                className="c-select"
                onChange={this.handleChange}
                id="validityCriteria"
              >
                <option key="1" value="STANDARD">
                  {TextOnly('standard')}
                </option>
                <option key="2" value="TIME">
                  {TextOnly('time')}
                </option>
              </select>
            </div>
            {this.state.validityCriteria === 'TIME' ? (
              <div className="c-field">
                <label className="c-field__label u-margin-top-large">
                  <Text tid="startEndTime" />:
                </label>
                <CustomDatePicker
                  value={{
                    start: startDate,
                    end: endDate,
                    name: 'Custom',
                  }}
                  onChange={this.handleRangeChange.bind(this)}
                />
              </div>
            ) : null}
            {this.state.noOfAdditionalFields ? (
              <>
                <h2>
                  <Text tid="customFieldsFromUsers" />
                </h2>
                {additionalFieldsInput}
              </>
            ) : null}
            <LoaderButton
              type="button"
              isLoading={this.state.isLoading}
              onClick={this.handleAddField.bind(this)}
              text={<Text tid="addCustomField" />}
              className="c-btn-outline"
            />
            <div className="u-margin-top-large">
              <LoaderButton
                type="submit"
                disabled={!this.validateForm()}
                isLoading={this.state.isLoading}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            </div>
          </form>
        ) : (
          <div>
            <Loading />
          </div>
        )}
      </Dialog>
    );
  }
}
