import React, { useEffect, useState } from "react";
import { TextOnly } from "../../components/Text";
import { Header } from "../../components/Header";
import { getRegionOemToolCompatibility } from "../../libs/db-lib";
import CompatibleTools from "../../components/CompatibleTools/CompatibleTools";
import { Loading } from "../../components/Loading";


const OemToolRegionCompatibility: React.FC = () => {

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Set timeout to get the compatibility data in the background
    setTimeout(async () => {
      const response = await getRegionOemToolCompatibility();
      if (!response.error)
      setChartData(response.data);
    }, 350);
  }, []);

  useEffect(() => {
  }, [chartData]);

 if (Object.keys(chartData).length !== 0) {
    return (
      <div className="width-fitcontent">
        <Header title={TextOnly('vehicleToolCompatibility')} />
        <CompatibleTools
          chartData={chartData}
        />
      </div>
    )
 } else {
  return <Loading />
 }
}

export default OemToolRegionCompatibility;