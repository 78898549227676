import moment from 'moment';
import React from 'react';
import { capitalize } from '../../libs/utils-ts';
import { Text, TextOnly } from '../Text';

interface ToolErrorsProps {
  apiErrorMessage: string;
  actionDate: string;
  name: string;
  toolSerial: string;
  toolManufacturer: string;
  toolModel: string;
  vin: string;
}

export const ToolErrors = ({
  apiErrorMessage,
  actionDate,
  name,
  toolSerial,
  toolManufacturer,
  toolModel,
  vin,
}: ToolErrorsProps) => {
  return (
    <p>
      <dl>
        <dt>
          <Text tid="error" />
        </dt>
        <dd>{apiErrorMessage}</dd>
      </dl>
      <dl>
        <dt>
          {TextOnly('date')}/{capitalize(TextOnly('time'))}
        </dt>
        <dd>{moment(actionDate).format('MM/DD/YYYY HH:mmA')}</dd>
      </dl>
      <dl>
        <dt>
          <Text tid="tech" />
        </dt>
        <dd>{name}</dd>
      </dl>
      <dl>
        <dt>
          <Text tid="serialNumber" />
        </dt>
        <dd>{toolSerial}</dd>
      </dl>
      <dl>
        <dt>
          <Text tid="manufacturer" />
        </dt>
        <dd>{toolManufacturer}</dd>
      </dl>
      <dl>
        <dt>
          <Text tid="model" />
        </dt>
        <dd>{toolModel}</dd>
      </dl>
      <dl>
        <dt>
          <Text tid="vin" />
        </dt>
        <dd>{vin}</dd>
      </dl>
    </p>
  );
};
