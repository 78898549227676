import React, { Component } from 'react';
import { Dialog } from '@reach/dialog';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { CreateMenu } from '../../components/CreateMenu';
import braintree from 'braintree-web-drop-in';
import LoaderButton from '../../components/LoaderButton';
import ReactTable from 'react-table';
import AlertModal from '../../components/AlertModal';
import HelpTooltip from '../../components/HelpTooltip';
import ToolTip from '../../components/ToolTip';
import AddTechCertification from './AddTechCertification';
import {
  updateShopInfo,
  updateShopPaymentInfo,
  getOEMRegionList,
  addUsersToShopCapacity,
  getSystemConfig,
  logSmartyStreets,
  updateOEMRegions,
  addReinstateOEMRegions,
  rechargeDisputedPayment,
  downgradeToStandard,
  removeDowngradeToStandard,
  getRegionOemToolCompatibility,
} from '../../libs/db-lib';
import { getBraintreeConfig } from '../../libs/utils-ts';
import { Text, TextOnly } from '../../components/Text';
import {
  nameValidate,
  fieldChangeCase,
  buildReceiptPdf,
  scalePdf,
  formatDateTime2Lines,
} from '../../libs/utils';
import { Header } from '../../components/Header';
import CountryPicker from '../../components/CountryPicker';
import StatePicker from '../../components/StatePicker';
import { toast } from 'react-toastify';
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import countries from 'i18n-iso-countries';
import { Document, Page, pdfjs } from 'react-pdf';
import { NavLink } from 'react-router-dom';
import { PAYMENT_STATES, SHOP_ROLES, SHOP_STATES, SHOP_TYPES } from '../../CONSTANTS';
import { capitalize } from '../../libs/utils-ts';
import ConfirmModal from '../../components/ConfirmModal';
import _ from 'underscore';
import CompatibleTools from "../../components/CompatibleTools/CompatibleTools";
import CompatibleToolsModal from '../../components/CompatibleTools/CompatibleToolsModal';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import PriceBreakdown from '../../components/PriceBreakdown';
import systemConfig from '../../config';

export default class ShopProfile extends Component {
  typeaheadRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      emailChanged: false,
      alertTitle: '',
      alertMessage: '',
      showModal: false,
      showAddUsersModal: false,
      shop: this.props?.currentShop?.shopName ?? '',
      shareInfo: this.props?.currentShop?.shareInfo || false,
      username: this.props.user.userName || '',
      shopPaymentInfo: {},
      paymentInfo: null,
      user: this.props.user,
      addressSuggestions: [],
      typeaheadOpen: undefined,
      streetAddress1: this.props.currentShop?.streetAddress1 || '',
      streetAddress2: this.props.currentShop?.streetAddress2 || '',
      city: this.props.currentShop?.city || '',
      state: this.props.currentShop?.state || '',
      zip: this.props.currentShop?.zip || '',
      country: this.props.currentShop?.country || 'US',
      shopPhoneNumber: this.props.currentShop?.phone || "",
      numToAdd: null,
      isEnterprise: this.props?.currentShop?.shopType === SHOP_TYPES.ENTERPRISE,
      isLoading: false,
      isLoadingIncreaseMaxUsers: false,
      isLoadingShopName: false,
      isLoadingCancelSubscription: false,
      isLoadingConfirmAddress: false,
      isLoadingChangingOEMRegions: false,
      isLoadingRepayDisputedPayment: false,
      isLoadingChargingRepayment: false,
      isLoadingCancelDowngradeSubscription: false,
      loadingAddress: '',
      paymentAmount: 0,
      showReceiptModal: false,
      currentReceipt: null,
      pdfScale: scalePdf(window.outerWidth),
      showCancelSubscriptionModal: false,
      showDowngradeSubscriptionModal: false,
      showPaymentHistoryModal: false,
      showVerifyAddressModal: false,
      addressesToVerify: [],
      internationalAddressesToVerify: [],
      addressToLog: {},
      smartyStreetsResponse: {},
      selectedAddress: {},
      showOEMRegionRemovalModal: false,
      showOEMRegionReinstateModal: false,
      showRepayDisputedPaymentModal: false,
      oemIDs: null,
      oemIDsSelected: new Set(),
      oemIDsAdded: new Set(),
      confirmOemRegionModal: false,
      showModalMO: false,
      oemIDsRemoved: new Set(),
      regionCode: this.props.currentShop?.regionCode,
      showPriceBreakdown: false,
      perTechCertRate: 0,
      chartData: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentShop?.phone !== prevProps.currentShop?.phone) {
      this.setState({
        shopPhoneNumber: this.props.currentShop?.phone,
      });
    }

    if (this.props.currentShop?.oemIDs !== prevProps.currentShop?.oemIDs) {
      if(this.props.currentShop.oemIDs) this.props.currentShop.oemIDs.forEach((oemID) => {
        this.state.oemIDsSelected.add(oemID);
      });
    }

    if (this.props.lang !== prevProps.lang) {
      let self = this;
      const braintreeConfig = getBraintreeConfig(self.state.config.braintreeMode);

      this.state.braintreeDropin && this.state.braintreeDropin.teardown(() => {
        braintree.create(
          braintreeConfig,
          function (createErr, instance) {
            if (createErr) {
              console.error("braintree.create error: ", createErr);
              self.setState({
                alertTitle: "componentDidUpdate Braintree Error",
                alertMessage: "Braintree Error: " + JSON.stringify(createErr),
                showModal: true,
              });
            } else {
              self.setState({braintreeDropin: instance});
              let button = document.getElementById('changePayment');
              button.addEventListener('click', function () {
                let instance = self.state.braintreeDropin;
                instance.requestPaymentMethod(function (
                  requestPaymentMethodErr,
                  payload
                ) {
                  self.setState({paymentInfo: payload});
                  if (payload) {
                    self.handleChangePaymentInfo(payload);
                  } else {
                    toast.dismiss();
                    toast.error(TextOnly('enterPaymentMethod'), {
                      containerId: 'standard',
                    });
                  }
                });
              });
            }
          }
        );
      });
    }
  }

  async componentDidMount() {
    let self = this;
    let config = await getSystemConfig();
    this.props.updateConfig(config);

    const oemRegions = await getOEMRegionList();
    const oemIDsSelected = new Set();
    if(this.props?.currentShop?.oemIDs?.length) this.props.currentShop.oemIDs.forEach((oemRegionID) => {
      oemIDsSelected.add(oemRegionID);
    });

    this.setState({
      config: config,
      oemRegions: oemRegions,
      oemIDsSelected: oemIDsSelected,
    });

    let countryISO2Codes = Object.keys(
      countries.getNames('en', { select: 'official' })
    );
    if (countryISO2Codes.indexOf(this.state.country) === -1) {
      this.setState({
        country: '',
      });
    }

    if (
      this.props.currentShop.shopID &&
      this.props.user?.shopRole !== SHOP_ROLES.TECH
    ) {
      const braintreeConfig = getBraintreeConfig(config.braintreeMode);
      const self = this;
      if(!this.state.isEnterprise) braintree.create(
        braintreeConfig,
        function (createErr, instance) {
          if (createErr) {
            console.error("braintree.create error: ", createErr);
            self.setState({
              alertTitle: "componentDidMount Braintree Error",
              alertMessage: "Braintree Error: " + JSON.stringify(createErr),
              showModal: true,
            });
          } else {
            self.setState({braintreeDropin: instance});
            let button = document.getElementById('changePayment');
            if (button) {
              button.addEventListener('click', function () {
                let instance = self.state.braintreeDropin;
                instance.requestPaymentMethod(function (
                  requestPaymentMethodErr,
                  payload
                ) {
                  self.setState({paymentInfo: payload});
                  if (payload) {
                    self.handleChangePaymentInfo(payload);
                  } else {
                    toast.dismiss();
                    toast.error(TextOnly('enterPaymentMethod'), {
                      containerId: 'standard',
                    });
                  }
                });
              });
            }
          }
        }
      );
    }
    self.setState({ windowWidth: window.outerWidth });

    // Set timeout to get the compatibility data in the background
    setTimeout(async () => {
      const response = await getRegionOemToolCompatibility();
      if (!response.error)
      self.setState({
        chartData: response.data
      });
    }, 350);

    window.addEventListener(
      'resize',
      () => {
        this.setState({
          pdfScale: scalePdf(window.outerWidth),
        });

        this.setWindowWidth.bind(this);
      },
      true
    );
    window.addEventListener(
      'orientationchange',
      () => {
        this.setState({
          pdfScale: scalePdf(window.outerWidth),
        });

        this.setWindowWidth.bind(this);
      },
      true
    );
    if (this?.typeaheadRef?.current?._instance?.state) {
      this.typeaheadRef.current._instance.state.text =
        this.props.currentShop?.streetAddress1 || '';
    }

    if (this.props.viewReceipt === 'true') {
      const transactionFound =
        this.props.currentShop.shopPaymentInfo.transactions.some(
          (transaction) => transaction.id === this.props.transactionId
        );

      if (!transactionFound) {
        this.props.history.push('/myActivity');
        return;
      }
      this.setState(
        {
          showPaymentHistoryModal: true,
        },
        () => {
          if (this.props.transactionId) {
            this.handleViewReceipt(
              this.props.transactionId,
              new Event('click')
            );
          }
        }
      );
    }
  }

  // Calculating the price per user rate coinciding with the current year's number of oems.
  // This function is being used for adding tools/users to a current subscription year.
  calculatePerUserRate = (shop) => {
    const config = this.state?.config;
    if (config) {
      let perUserRate = config.prices?.[
        shop?.shopType.toLowerCase() + 'UserAddon'
      ];
      if (shop?.oemIDs?.length > 1) {
        perUserRate += config.prices?.oemRegionUserUpcharge * (shop.oemIDs.length - 1);
      }
      return perUserRate;
    }
  }

  // Calculating the price per oem rate coinciding with the number of user/tool addons.
  // This function is being used for adding oems to a current subscription year.
  calculatePerOemRate = (shop) => {
    const config = this.state?.config;
    if (config) {
      const baseUsers = config.shopBaseValues[shop.shopType.toLowerCase() + 'BaseUsers'];
      const userAddons = shop.shopMaxUsers > baseUsers
        ? shop.shopMaxUsers - baseUsers
        : 0;
      return (config.prices?.oemRegionAddon + (userAddons * config.prices?.oemRegionUserUpcharge));
    }
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  translateStatus(status) {
    if (status === 'submitted_for_settlement') {
      return (
        <b className="u-text-success">{TextOnly('submittedForSettlement')}</b>
      );
    } else if (status === 'settled') {
      return <b className="u-text-success">{TextOnly('settled')}</b>;
    } else if (
      status === 'gateway_rejected' ||
      status === 'processor_declined' ||
      status === 'settlement_declined'
    ) {
      return <b className="u-text-error">{TextOnly('failedCharge')}</b>;
    } else {
      return status;
    }
  }

  profileFieldsHaveChanged() {
    let shopNameValid = nameValidate(this.state.shop);
    let addressValid = this.state?.streetAddress1?.length > 0;
    let cityValid = this.state?.city?.length > 0;
    let stateValid = this.state?.state?.length > 0;
    let zipValid = this.state?.zip?.length > 0;
    let countryValid = this.state?.country?.length > 0;
    let phoneValid = this.state?.shopPhoneNumber?.length > 0;
    return (
      shopNameValid &&
      addressValid &&
      cityValid &&
      stateValid &&
      zipValid &&
      countryValid &&
      phoneValid &&
      (
        this.state.shop !== this.props.currentShop.shopName ||
        this.state.streetAddress1 !== this.props.currentShop.streetAddress1 ||
        this.state.streetAddress2 !== this.props.currentShop.streetAddress2 ||
        this.state.city !== this.props.currentShop.city ||
        this.state.state !== this.props.currentShop.state ||
        this.state.country !== this.props.currentShop.country ||
        this.state.zip !== this.props.currentShop.zip ||
        this.state.shopPhoneNumber !== this.props.currentShop?.phone ||
        this.state.shareInfo !== this.props.currentShop.shareInfo
      )
    );
  }

  handleNumToAddChange = (event) => {
    let numToAdd = event.target.value;
    if (numToAdd !== this.state.numToAdd) {
      this.setState({ numToAdd: numToAdd.toString() });
    }
  };

  handleCancel = () => {
    this.setState({ showModal: false, showRepayDisputedPaymentModal: false });
  };

  handleCancelSubscriptionButton = () => {
    this.setState({ showCancelSubscriptionModal: true });
  };

  eligibleToDowngrade = () => {
    const paymentState = this.props?.user?.paymentState || '';
    const suspendReason = this.props?.currentShop?.suspendReason || '';

    return (this.props?.currentShop?.shopUsers?.length < 5 &&
            this.props?.currentShop?.userShopTools?.length <= 5 &&
            (paymentState !== 'SUSPENDED' || suspendReason !== "PAST_DUE") &&
            paymentState !== "PAST_DUE");
  }

  handleDowngradeSubscriptionButton = () => {
    this.setState({ showDowngradeSubscriptionModal: true });
  };

  handleCancelDowngradeSubscriptionButton = async () => {
    this.setState({ isLoadingCancelDowngradeSubscription: true });

    let result = await removeDowngradeToStandard(
      this.props.currentShop.shopID,
    );

    if (result.error) {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: result.error,
        showModal: true,
        showDowngradingMessage: false,
        isLoadingCancelSubscription: false,
      });

    } else {
      const shopState = this.props?.currentShop?.shopSubscriptionState;
      let message = "cancelDowngradeSuccessful";
      if (shopState === "TERMINATED" || shopState === "USER_CANCEL") {
        message = "downgradeSuccessful";
      }
      toast.success(TextOnly(message), {
        containerId: 'standard',
      });

      this.setState({ isLoadingCancelDowngradeSubscription: false });

      await this.props.fetchUser();
    }
  }

  handleRepayDisputedPaymentButton = () => {
    if (this.props.currentShop?.shopPaymentInfo?.last4) {
      this.setState({
        isLoadingRepayDisputedPayment: true,
        showRepayDisputedPaymentModal: true
      });
    } else {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: TextOnly('noPaymentMethodToRepayDisputedPayment'),
        showModal: true,
      });
    }
  };

  handleChargeRepayment = async () => {
    this.setState({ isLoadingChargeRepayment: true });

    let result = await rechargeDisputedPayment(this.props.currentShop.shopID);

    if (result?.error) {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: result.error,
        showModal: true,
        showRepayDisputedPaymentModal: false,
        isLoadingRepayDisputedPayment: false,
        isLoadingChargeRepayment: false
      });
    } else {
      toast.dismiss();
      let toastMessage = TextOnly('shopReinstated');
      toastMessage += ' ' + TextOnly('youHaveBeenChargedAmount', {amount: Number(result.payment).toFixed(2)});
      toast.success(toastMessage, {
        containerId: 'standard',
        autoClose: true,
      });

      this.setState({
        showRepayDisputedPaymentModal: false,
        isLoadingRepayDisputedPayment: false,
        isLoadingRepayDisputedPayment: false,
        isLoadingChargeRepayment: false
      });

      await this.props.fetchUser(this.props.currentShop.shopID);
    }
  }

  handleConfirmCancelSubscriptionModal = async () => {
    if(this.state.showCancelingMessage) return;
    this.setState({ showCancelingMessage: true });

    let result = await updateShopInfo(
      this.props.currentShop.shopID,
      this.state.shop,
      'CANCEL'
    );

    if (result?.error) {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: result.error,
        showModal: true,
        showCancelSubscriptionModal: false,
        showCancelingMessage: false,
        isLoadingCancelSubscription: false,
      });
    } else {
      toast.success(TextOnly('shopSubscriptionCanceled'), {
        containerId: 'standard',
      });

      await this.props.fetchUser();

      this.setState({
        showCancelSubscriptionModal: false,
        showCancelingMessage: false,
        isLoadingCancelSubscription: false,
      });
    }
  };

  handleCancelCancelSubscription = () => {
    const self = this;
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      self.setState({ showCancelSubscriptionModal: false });
    }, 350);
  };

  handleConfirmDowngradeSubscriptionModal = async () => {
    const self = this;
    if(this.state.showDowngradingMessage) return;
    this.setState({ showDowngradingMessage: true });

    let result = await downgradeToStandard(
      this.props.currentShop.shopID,
    );

    if (result.error) {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: result.error,
        showModal: true,
        showDowngradingMessage: false,
        isLoadingCancelSubscription: false,
      });
      document.querySelector('.c-modal-slider').classList.add('closed');
      setTimeout(() => {
        self.setState({ showDowngradeSubscriptionModal: false });
      }, 350);

    } else {
      const shopState = this.props?.currentShop?.shopSubscriptionState;
      let message = "downgradeDelayedSuccessful";
      if (shopState === "TERMINATED" || shopState === "USER_CANCEL") {
        message = "downgradeSuccessful";
      }
      toast.success(TextOnly(message), {
        containerId: 'standard',
      });

      await this.props.fetchUser();
      document.querySelector('.c-modal-slider').classList.add('closed');

      this.setState({
        showCancelSubscriptionModal: false,
        showCancelingMessage: false,
        isLoadingCancelSubscription: false,
        showDowngradingMessage: false,
        showDowngradeSubscriptionModal: false,
      });
    }
  };

  handleCancelDowngradeSubscription = () => {
    const self = this;
    document.querySelector('.c-modal-slider').classList.add('closed');
    setTimeout(() => {
      self.setState({ showDowngradeSubscriptionModal: false });
    }, 350);
  };

  handlePaymentHistoryButton = (event) => {
    event.preventDefault();
    this.setState({ showPaymentHistoryModal: true });
  };

  validateAddress = () => {
    var xmlHttp = new XMLHttpRequest();
    const self = this;

    const isInternational = this.state.country !== 'US';

    const smartyStreetKey = this.state.config.smartyStreets.key;

    const apiHost = isInternational ? 'international-street' : 'us-street';
    const verifyPrefix = isInternational ? 'verify' : 'street-address';

    const streetAddress1 = this.state.streetAddress1.replace('#', 'Suite ');
    const streetAddress2 = this.state.streetAddress2.replace('#', 'Suite ');
    const streetAddress1Str = isInternational
      ? `&address1=${this.state.streetAddress1}`
      : `&street=${streetAddress1}`;
    const streetAddress2Str = this.state.streetAddress2
      ? isInternational
        ? `&address2=${this.state.streetAddress2}`
        : `&secondary=${streetAddress2}`
      : '';
    const cityStr = isInternational
      ? `&locality=${this.state.city}`
      : `&city=${this.state.city}`;
    const stateStr = isInternational
      ? `&administrative_area=${this.state.state}`
      : `&state=${this.state.state}`;
    const zipStr = isInternational
      ? `&postal_code=${this.state.zip}`
      : `&zipcode=${this.state.zip}`;
    const countryStr = isInternational ? `&country=${this.state.country}` : '';

    let url = `https://${apiHost}.api.smartystreets.com/${verifyPrefix}?key=${smartyStreetKey}`;
    url += streetAddress1Str;
    url += streetAddress2Str;
    url += cityStr;
    url += stateStr;
    url += zipStr;
    url += countryStr;
    url += isInternational ? '' : `&candidates=10`;
    url += isInternational ? '&license=international-global-plus-cloud' : '';

    const addressToLog = {
      isInternational: isInternational,
      requestUrl: url,
      streetAddress1: self.state.streetAddress1,
      streetAddress2: self.state.streetAddress2,
      city: self.state.city,
      state: self.state.state,
      zip: self.state.zip,
      country: self.state.country,
    };

    xmlHttp.onreadystatechange = async function () {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        const jsonResponse = JSON.parse(xmlHttp.response);
        let result = {};

        self.setState({
          addressToLog: addressToLog,
        });

        if (self.state.country === 'US') {
          self.setState({
            addressesToVerify: jsonResponse,
            smartyStreetsResponse: jsonResponse,
          });
          if (jsonResponse.length === 0) {
            result = {
              success: false,
              component: self.constructor.name,
              error: {
                type: 'API',
                statusText:
                  'Unable to find an address for the given input. Please check and try again.',
              },
            };
            await logSmartyStreets(result, addressToLog, jsonResponse, {});
          }
        } else {
          let internationalAddressesToVerify = jsonResponse.filter(
            (address) => {
              return address.analysis.verification_status !== 'None';
            }
          );
          self.setState({
            internationalAddressesToVerify: internationalAddressesToVerify,
            smartyStreetsResponse: jsonResponse,
          });
          if (internationalAddressesToVerify.length === 0) {
            result = {
              success: false,
              component: self.constructor.name,
              error: {
                type: 'API',
                statusText:
                  'Unable to find an address for the given input. Please check and try again.',
              },
            };
            await logSmartyStreets(result, addressToLog, jsonResponse, {});
          }
        }

        self.handleShowVerifyAddressModal();
      } else if (
        xmlHttp.status !== 200 &&
        xmlHttp.status !== 0 &&
        xmlHttp.readyState === 4
      ) {
        let result = {
          success: false,
          component: self.constructor.name,
          error: {
            type: 'HTTP',
            code: xmlHttp.status,
            responseText: JSON.parse(xmlHttp.responseText).message,
          },
        };
        await logSmartyStreets(result, addressToLog, {}, {});
        self.setState({
          alertTitle: 'Error',
          alertMessage: JSON.parse(xmlHttp.responseText).message,
          showModal: true,
        });
      }
    };

    xmlHttp.open('GET', url, true); // true for asynchronous
    xmlHttp.setRequestHeader('Content-Type', 'application/json');
    xmlHttp.send(null);
  };

  validateFreeformAddress = async (query) => {
    const smartyStreetKey = this.state.config.smartyStreets.key;
    if(this.state.country !== 'US') {
      let url = `https://international-autocomplete.api.smarty.com/v2/`;
      url += `lookup?key=${smartyStreetKey}`;
      url += `&search=${query}`;
      url += `&country=${countries.alpha2ToAlpha3(this.state.country)}`;
      url += '&license=international-autocomplete-v2-cloud';
      try {
        const result = await fetch(url);
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        } else {
          const response = await result.json();
          this.setState({
            addressSuggestions: response.candidates || [],
          });
          return response;
        }
      } catch (error) {
        console.error('Error:', error.message);
        return false;
      }
    } else {
      let url = `https://us-autocomplete-pro.api.smartystreets.com/`
      url += `lookup?key=${smartyStreetKey}`;
      url += `&search=${query}`;
      url += '&license=us-autocomplete-pro-cloud';
      try {
        const result = await fetch(url);
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        } else {
          const response = await result.json();
          this.setState({
            addressSuggestions: response.suggestions || [],
          });
          return response;
        }
      } catch (error) {
        console.error('Error:', error.message);
        return false;
      }
    }
  };

  getAddressInfo = async (option) => {
    const smartyStreetKey = this.state.config.smartyStreets.key;
    let url = `https://international-autocomplete.api.smarty.com/v2/`;
    url += `lookup/${option.address_id}`;
    url += `?key=${smartyStreetKey}`;
    url += `&country=${countries.alpha2ToAlpha3(this.state.country)}`;
    url += `&license=international-autocomplete-v2-cloud`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        const jsonResponse = await response.json();
        const result = jsonResponse.candidates;
        return result;
      }
    } catch (error) {
      console.error('Error:', error.message);
      return false;
    }
  };

  validateShopFields = (event) => {
    event.preventDefault();

    const currentShop = this.props?.currentShop;
    if (
      this.state.streetAddress1 !== currentShop.streetAddress1 ||
      this.state.streetAddress2 !== currentShop.streetAddress2 ||
      this.state.city !== currentShop.city ||
      this.state.state !== currentShop.state ||
      this.state.zip !== currentShop.zip ||
      this.state.phone !== currentShop.phone ||
      this.state.shareInfo !== currentShop.shareInfo
    ) {
      this.handleEditShop();
    }
  };

  compareOemIDs = (oemIDsSelected, oemIDsList) => {
    if (!oemIDsList) return
    if (oemIDsSelected.size !== oemIDsList.length) {
      return false;
    }
    let retVal = true;
    oemIDsList.forEach((oemID) => {
      if (!oemIDsSelected.has(oemID)) {
        retVal = false;
      }
    });
    return retVal;
  }

  minimumActiveOEMs = (oemIDsSelected, removedOemIDs) => {
    const oemIDsActive = [];
    oemIDsSelected.forEach((or) => {
      if (!removedOemIDs.includes(or)) {
        oemIDsActive.push(or);
      }
    });
    return oemIDsActive.length > 0;
  }

  calculateNumberToChargeToday = (shop, oemIDsSet) => {
    const numberAdded = oemIDsSet.size;
    const reinstatingOemIDs = shop.removedOemIDs ? shop.removedOemIDs.filter(oemID => oemIDsSet.has(oemID)).length : 0;
    return numberAdded - reinstatingOemIDs;
  };

  handleRemoveOEMChange = (oemID) => {
    let oemIDsRemoved = this.state.oemIDsRemoved;
    if (!oemIDsRemoved.has(oemID)) {
      oemIDsRemoved.add(oemID);
    }
    this.setState({
      oemIDsRemoved: oemIDsRemoved,
      showOEMRegionRemovalModal: true
    });
  };

  handleAddOEMChange = (event) => {
    let oemIDsAdded = this.state.oemIDsAdded;
    if (event.target.checked === true) {
      this.setState({ showModalMO: true });
      if (!oemIDsAdded.has(event.target.id)) {
        oemIDsAdded.add(event.target.id);
      }
    } else {
      if (oemIDsAdded.has(event.target.id)) {
        oemIDsAdded.delete(event.target.id);
      }
    }
    this.setState({ oemIDsAdded: oemIDsAdded });
  };

  handleEditShop = async () => {
    let shopInfo = {
      streetAddress1: this.state.streetAddress1,
      streetAddress2: this.state.streetAddress2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      phone: this.state.shopPhoneNumber,
      shareInfo: this.state.shareInfo,
    };

    let selectedAddress = {};
    Object.assign(selectedAddress, shopInfo);
    delete selectedAddress.phone;

    this.setState({
      isLoadingShopName: true,
      isLoadingConfirmAddress: true,
      selectedAddress: selectedAddress,
    });

    let result = await updateShopInfo(
      this.props.currentShop.shopID,
      this.state.shop,
      false,
      shopInfo
    );
    if (result?.error) {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: result.error,
        showModal: true,
        isLoadingShopName: false,
        isLoadingConfirmAddress: false,
      });
    } else {
      toast.success(TextOnly('shopUpdated'), { containerId: 'standard' });

      await this.props.fetchUser();

      this.setState({
        isLoadingShopName: false,
        isLoadingConfirmAddress: false,
      });
      this.handleCancelModal();
    }
    await logSmartyStreets(
      { success: true, component: this.constructor.name },
      this.state.addressToLog,
      this.state.smartyStreetsResponse,
      this.state.selectedAddress
    );
  };

  handleViewReceipt = (transactionId, e) => {
    e.preventDefault();
    let transaction = this.props.currentShop.shopPaymentInfo.transactions.find(
      (trans) => {
        return trans.id === transactionId;
      }
    );
    let description = '';
    if (transaction.type === 'upgradeProration') {
      description = TextOnly('userCapacityUpgrade');
    } else if (transaction.type === 'upgradePlusProration') {
      description = TextOnly('plusUpgrade');
    } else if (transaction.type === 'subscriptionRenew') {
      description = TextOnly('yearlySubscriptionRenewal');
    } else if (transaction.type === 'initialSubscriptionCharge') {
      description = TextOnly('initialSubscriptionCharge');
    } else if (transaction.type === 'paymentRefund') {
      description = TextOnly('paymentRefund');
    } else if (transaction.type === 'upgradeOEMRegionProration') {
      description = TextOnly('upgradeOEMRegionProration');
    } else if (transaction.type === 'downgradeOEMRegionProrationCredit') {
      description = TextOnly('downgradeOEMRegionProrationCredit');
    } else if (transaction.type === 'disputedPaymentRepay') {
      description = TextOnly('disputedPaymentRepay');
    } else if (transaction.type === 'upgradeTechCertProration') {
      description = TextOnly('upgradeTechCertProration');
    } else {
      description = transaction.type;
    }
    transaction.description = description;

    let receiptDoc = buildReceiptPdf(
      transaction,
      this.props.currentShop,
      this.props.user,
      this.state.oemRegions,
    );
    this.setState({ currentReceipt: receiptDoc });
    this.setState({ showReceiptModal: true });
  };

  handlePrintReceipt = (e) => {
    e.preventDefault();
    let doc = this.state.currentReceipt?.doc;
    window.open(doc.output('bloburl'), '_blank');
  };

  handleChangePaymentInfo = async (paymentInfo) => {
    if (!this.state.isLoadingChangePayment) {
      this.setState({ isLoadingChangePayment: true });
      try {
        let result = await updateShopPaymentInfo(
          this.props.currentShop.shopID,
          paymentInfo
        );
        if (result?.error) {
          toast.dismiss();
          toast.error(result.error, {
            containerId: 'standard',
          });
          this.setState({
            isLoadingChangePayment: false,
          });
          this.state.braintreeDropin.clearSelectedPaymentMethod();
        } else {
          const message = result.amountChargedToday ? `${TextOnly(
            'youHaveBeenCharged'
          )} $${result.amountChargedToday} ${TextOnly('today')}.` : ''
          toast.dismiss()
          toast.success(
            `${TextOnly('paymentMethodUpdated')}. ${message}`, {
            containerId: 'standard',
            autoClose: false,
          });

          this.setState({
            isLoadingChangePayment: false,
          });

          this.state.braintreeDropin.clearSelectedPaymentMethod();
          await this.props.fetchUser(this.props.currentShop.shopID);
        }
      } catch (error) {
        this.setState({
          alertTitle: TextOnly('error'),
          alertMessage: error.message,
          showModal: true,
          isLoadingChangePayment: false,
        });
      }
    }
  };

  handleReinstateOEMRegionButton = (e) => {
    e.preventDefault();
    this.setState({ showOEMRegionReinstateModal: true });
  }

  handleConfirmRemoveOEMRegion = async () => {
    if (!this.state.isLoadingChangingOEMRegions) {
      this.setState({ isLoadingChangingOEMRegions: true });

      const self = this;

      // Build the updated list of OEMs without the one removed
      let newOemIDs = this.props.currentShop?.oemIDs?.filter((oemID) => !this.state.oemIDsRemoved.has(oemID));

      let result = await updateOEMRegions(
        this.props.currentShop.shopID,
        this.state.regionCode,
        newOemIDs
      );
      if (result?.error) {
        toast.error(result.error, {
          containerId: 'standard',
        });
        self.setState({
          isLoadingChangingOEMRegions: false,
        });
      } else {
        toast.dismiss();
        let toastMessage = TextOnly('OEMRegionsUpdated');
        if (result.payment > 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenChargedAmount', {amount: result.payment});
        } else if (result.payment < 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenCreditedAmount', {amount: (-1 * result.payment)});
        }
        toast.success(toastMessage, {
          containerId: 'standard',
          autoClose: true,
        });

        this.setState({
          isLoadingChangingOEMRegions: false,
          showOEMRegionRemovalModal: false,
          oemIDsRemoved: new Set()
        });

        await this.props.fetchUser(this.props.currentShop.shopID);
      }
    }
  }

  handleConfirmOEMRegionReinstate = async () => {
    if (!this.state.isLoadingChangingOEMRegions) {
      this.setState({ isLoadingChangingOEMRegions: true });

      const self = this;

      let result = await addReinstateOEMRegions(
        this.props.currentShop.shopID,
        this.state.regionCode,
        [...this.state.oemIDsAdded],
      );
      if (result?.error) {
        toast.error(result.error, {
          containerId: 'standard',
        });
        self.setState({
          isLoadingChangingOEMRegions: false,
        });
      } else {
        toast.dismiss();
        let toastMessage = TextOnly('OEMRegionsUpdated');
        if (result.payment > 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenChargedAmount', {amount: result.payment});
        } else if (result.payment < 0) {
          toastMessage += ' ' + TextOnly('youHaveBeenCreditedAmount', {amount: (-1 * result.payment)});
        }
        toast.success(toastMessage, {
          containerId: 'standard',
          autoClose: true,
        });

        this.setState({
          isLoadingChangingOEMRegions: false,
          showOEMRegionReinstateModal: false,
          oemIDsAdded: new Set()
        });

        await this.props.fetchUser(this.props.currentShop.shopID);
      }
    }
  }

  handleAddUsersButton(e) {
    e.preventDefault();

    const hasLastFour = !!this.props.currentShop.shopPaymentInfo.last4 || null;

    if (!hasLastFour) {
      this.setState({
        alertTitle: TextOnly('noPaymentMethodAvailable'),
        alertMessage: (
          <p>
            <Text tid="noPaymentMethodForIncreasingUsers" />
          </p>
        ),
        showModal: true,
      });
    } else {
      this.setState({ showAddUsersModal: true });
    }
  }

  handleChange = (event) => {
    let newValue = event.target.value;
    if (event.target.id === 'email') {
      fieldChangeCase(this, event.target, 'lower', false);
      newValue = newValue.toLowerCase();
      if (newValue !== this.state.originalEmail) {
        this.setState({ emailChanged: true });
      } else {
        this.setState({ emailChanged: false });
      }
    } else if (event.target.id === 'country') {
      this.setState({
        addressSuggestions: [],
        country: event.target.value,
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        zip: '',
      });
      this.typeaheadRef &&
        this.typeaheadRef.current &&
        this.typeaheadRef.current._instance.clear();
      if (newValue === 'US') {
        let state = this.state.state?.toUpperCase();
        this.setState({
          state: state,
        });
      }
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleChangePhoneNumber = (number) => {
    let newNumber = number ? number : '';
    this.setState({
      shopPhoneNumber: newNumber,
    })
  }
  debounceAddressSearch = _.debounce((query) => {
    this.validateFreeformAddress(query);
  }, 500);

  handleSearchAddress = (query) => {
    this.debounceAddressSearch(query);
  };

  handleCancelReceiptModal() {
    const self = this;
    document.querySelector('.c-modal').classList.add('closed');
    setTimeout(() => {
      self.setState({ showReceiptModal: false });
    }, 350);
  }

  handleShowVerifyAddressModal() {
    this.setState({
      showVerifyAddressModal: true,
    });
  }

  handleCancelModal() {
    const self = this;
    const modalSlider = document.querySelector('.c-modal-slider');
    if (modalSlider) {
      modalSlider.classList.add('closed');
    }
    setTimeout(() => {
      self.setState({
        showOEMRegionRemovalModal: false,
        oemIDsRemoved: new Set(),
        showAddUsersModal: false,
        showReceiptModal: false,
        showAddUsersCompleteModal: false,
        showOEMRegionReinstateModal: false,
        isLoadingIncreaseMaxUsers: false,
        showPaymentHistoryModal: false,
        showVerifyAddressModal: false,
        showRepayDisputedPaymentModal: false,
        isLoadingRepayDisputedPayment: false,
        showModal: false,
      });
    }, 350);
  }

  handleCancelModalMO = () => {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      this.setState({showModalMO: false})
    }, 250);
  };

  async handleConfirmModal(e) {
    e.preventDefault();

    const self = this;

    this.setState({
      isLoadingIncreaseMaxUsers: true,
    });

    let numToAdd = parseInt(this.state.numToAdd, 10);
    let ret = await addUsersToShopCapacity(
      this.props.currentShop.shopID,
      numToAdd
    );
    if (ret?.error) {
      this.setState({
        showAddUsersModal: false,
        isLoadingIncreaseMaxUsers: false,
        alertMessage: ret.error,
        showModal: true,
      });
    } else {
      await this.props.fetchUser();

      const numAdded = this.state.numToAdd;

      document.querySelector('.c-modal').classList.add('closed');
      setTimeout(() => {
        self.setState({
          showAddUsersModal: false,
          isLoadingIncreaseMaxUsers: false,
          numToAdd: null,
        });
      }, 350);

      toast.success(
        `${TextOnly('usersAddedToShop', {'num': numAdded})} ${TextOnly(
          'youHaveBeenCharged'
        )} $${ret.payment} ${TextOnly('today')}.`,
        { containerId: 'standard' }
      );
    }
  }

  compareDates(a, b, desc){
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (desc) {
      if (dateA > dateB) {
        return 1;
      } else  {
        return -1;
      }
    } else {
      if (dateA < dateB) {
        return -1;
      } else {
        return 1;
      }
    }
  };


  render() {
    const self = this;
    const { shopPaymentInfo, newBillAmount, upgradeNeeded } = this.props.currentShop;
    const nextBillDate = shopPaymentInfo.nextBillingDate
      ? shopPaymentInfo.nextBillingDate
      : null;
    const nextPaymentAmount = newBillAmount && upgradeNeeded ?
      parseFloat(newBillAmount).toFixed(2) : shopPaymentInfo.nextBillAmount;
    // const nextBillAmount = upgradeNeeded ? nextPaymentAmount : this.calculateSubPrice(this.props.currentShop);
    const nextBillAmount = nextPaymentAmount;
    // const subscriptionCredit = shopPaymentInfo.balance && shopPaymentInfo.balance !== "0.00" ?
    //     -1 * shopPaymentInfo.balance
    //   : !upgradeNeeded ?
    //     (parseFloat(nextBillAmount) - parseFloat(nextPaymentAmount)).toFixed(2)
    //     : 0;
    const lastFourStr = shopPaymentInfo.last4
      ? ' ...' + shopPaymentInfo.last4
      : null;
    const isEnterprise = this.state.isEnterprise;
    const isPlus = this.props?.currentShop?.shopType === SHOP_TYPES.PLUS;
    const isDowngradingAtRenewal = this.props?.currentShop?.downgradeToStandardPending ? true : false;
    const renewalTier = (isDowngradingAtRenewal || !isPlus) && !upgradeNeeded ? 'AutoAuth STANDARD' : 'AutoAuth PLUS';

    const perUserRate = this.calculatePerUserRate(this.props?.currentShop);
    const perOemRate = this.calculatePerOemRate(this.props?.currentShop);
    const userBasePrice = this.state?.config?.prices[this.props?.currentShop.shopType.toLowerCase() + 'UserAddon'];
    const oemBasePrice = this.state?.config?.prices.oemRegionAddon;
    const oemUserUpcharge = this.state?.config?.prices.oemRegionUserUpcharge;
    const perTechCertRate = this.state?.config?.prices[this.props.currentShop.shopType.toLowerCase() + 'TechCertAddon'];
    const maxUsers =
      this.props?.currentShop?.shopUserCap ||
      this.state?.config?.shopMaxValues?.[
        this.props?.currentShop?.shopType.toLowerCase() + 'MaxUsers'
      ];

    const collapseColumns = this.state.windowWidth < 574;

    const dateColWidth = this.state.windowWidth > 700 ? 140 : 120;
    const amountColWidth = this.state.windowWidth > 700 ? 100 : 90;
    const statusColWidth = this.state.windowWidth > 700 ? 100 : 80;
    const descColWidth = this.state.windowWidth > 700 ? 200 : 150;

    const transactionColDefs = [];
    transactionColDefs.push(
      {
        Header: <Text tid="date" />,
        accessor: 'fullDate',
        minWidth: 120,
        maxWidth: dateColWidth,
        sortMethod: this.compareDates,
        Cell: (row) => {
          const dateObj = formatDateTime2Lines(row.original.fullDate);
          return (
            <div>
              {dateObj.date}
              <br />
              {dateObj.time}
            </div>
          );
        },
      },
      {
        Header: <Text tid="amount" />,
        id: 'amount',
        accessor: 'amount',
        minWidth: 90,
        maxWidth: amountColWidth,
        Cell: (row) => {
          let amount = parseFloat(row.original.amount);
          if (amount < 0) {
            return `-$${(-amount).toFixed(2)}`;
          }
          return `$${row.original.amount}`;
        },
      },
      {
        Header: <Text tid="chargeDescription" />,
        id: 'chargeDescription',
        accessor: 'type',
        minWidth: 100,
        maxWidth: descColWidth,
      },
      {
        Header: (
          <div className="u-text-center">
            <Text tid="status" />
          </div>
        ),
        id: 'status',
        accessor: 'status',
        minWidth: 85,
        maxWidth: statusColWidth,
        Cell: (row) => {
          let status = row.original.status;
          let failedCharge = status === TextOnly('failedCharge');

          return (
            <div
              className={`c-tag-${
                failedCharge ? 'error' : 'success'
              } u-margin-none u-margin-right`}
            >
              <span className={`c-tag__item`}>
                <span
                  className={`c-btn__icon fal fa-${
                    failedCharge ? 'times' : 'check'
                  }-circle`}
                />
                {this.state.windowWidth < 1348 && this.state.windowWidth >= 500 ? <br /> : null}
                {this.state.windowWidth > 500 ? status : null}
              </span>
            </div>
          );
        },
      },
      {
        Header: <Text tid="receipt" />,
        id: 'receipt',
        minWidth: 80,
        maxWidth: 80,
        Cell: (row) => {
          return (
            <div style={{ justifyContent: 'center' }}>
              <button
                className="c-btn-icon-outline u-margin-right"
                onClick={this.handleViewReceipt.bind(this, row.original.id)}
              >
                <div className="c-btn__inner">
                  <span
                    className="c-btn__icon fas fa-receipt"
                    title={TextOnly('viewReceipt')}
                  />
                </div>
              </button>
            </div>
          );
        },
      }
    );

    const isInternational = this.state.country !== 'US';

    let transactions = !shopPaymentInfo.transactions
      ? []
      : shopPaymentInfo.transactions.map((tran, index) => {
          let dateStr =
            tran.time.indexOf('T') > 0
              ? tran.time.substring(0, tran.time.indexOf('T'))
              : tran.time;
          let typeStr = '';
          let refundAmount;
          if (tran.type === 'upgradeProration') {
            typeStr = TextOnly('userCapacityUpgrade');
          } else if (tran.type === 'upgradePlusProration') {
            typeStr = TextOnly('plusUpgrade');
          } else if (tran.type === 'subscriptionRenew') {
            typeStr = TextOnly('yearlySubscriptionRenewal');
          } else if (tran.type === 'initialSubscriptionCharge') {
            typeStr = TextOnly('initialSubscriptionCharge');
          } else if (tran.type === 'paymentRefund') {
            typeStr = TextOnly('paymentRefund');
            refundAmount = tran.amount[0] === '-'
              ? tran.amount
              : `-${tran.amount}`;
          } else if (tran.type === 'upgradeOEMRegionProration') {
            typeStr = TextOnly('upgradeOEMRegionProration');
          } else if (tran.type === 'downgradeOEMRegionProrationCredit') {
            typeStr = TextOnly('downgradeOEMRegionProrationCredit');
          } else if (tran.type === 'disputedPaymentRepay') {
            typeStr = TextOnly('disputedPaymentRepay');
          } else if (tran.type === 'upgradeTechCertProration') {
            typeStr = TextOnly('upgradeTechCertProration');
          } else {
            typeStr = tran.type;
          }

          let failedCharge =
            [
              'gateway_rejected',
              'processor_declined',
              'settlement_declined',
            ].indexOf(tran.status) !== -1;

          return {
            id: tran.id,
            date: dateStr,
            fullDate: tran.time,
            amount: tran.type === 'paymentRefund'
              ? refundAmount
              : tran.amount,
            type: typeStr,
            status: failedCharge
              ? TextOnly('failedCharge')
              : TextOnly('success'),
          };
        });

    const paymentState = this.props?.user?.paymentState || '';
    const shopState = this.props?.currentShop?.shopState || '';
    const suspendReason = this.props?.currentShop?.suspendReason || '';
    const paidThruDate = this.props?.currentShop?.shopPaymentInfo
      ? new Date(shopPaymentInfo.paidThroughDate).getTime()
      : 0;
    const today = new Date().getTime();
    const expired = paidThruDate - today < 0;

    const maxAddUsers = maxUsers - this.props?.user?.shopMaxUsers;

    const intAddrToVerify = this.state.internationalAddressesToVerify;

    // US Street Addresses can be validated to mutiple possibilities
    const addressOptions =
      this.state.country === 'US' &&
      this.state.addressesToVerify.map((address) => {
        // If address contains a secondary number (apt, suite, unit)
        // Separate it, since we divide them into two components
        let primaryAddress = '';
        let secondaryAddress = '';
        if (address.components.secondary_designator) {
          let index = address.delivery_line_1.indexOf(
            address.components.secondary_designator
          );
          primaryAddress = address.delivery_line_1.substring(0, index).trim();
          secondaryAddress = address.delivery_line_1.substring(index).trim();
        }
        return (
          <div key={address.delivery_line_1}>
            <div className="c-verify-address u-margin-top">
              <div className="c-verify-address__fields">
                <dl key="1">
                  <dt>
                    <Text tid="streetAddress1" />
                  </dt>
                  <dd>{primaryAddress || address.delivery_line_1 || ''}</dd>
                </dl>
                {address.components.secondary_designator ? (
                  <dl key="2">
                    <dt>
                      <Text tid="streetAddress2" />
                    </dt>
                    <dd>{secondaryAddress}</dd>
                  </dl>
                ) : null}

                <dl key="3">
                  <dt>
                    <Text tid="city" />
                  </dt>
                  <dd>{address.components.city_name}</dd>
                </dl>
                <dl key="4">
                  <dt>
                    <Text tid="state" />
                  </dt>
                  <dd>{address.components.state_abbreviation || ''}</dd>
                </dl>
                <dl key="5">
                  <dt>
                    <Text tid="zipCode" />
                  </dt>
                  <dd>{address.components.zipcode || ''}</dd>
                </dl>
                <dl key="6">
                  <dt>
                    <Text tid="country" />
                  </dt>
                  <dd>{this.state.country}</dd>
                </dl>
              </div>
              <div className="c-verify-address__footer">
                <div />
                <div className="c-verify-address__footer-right">
                  <LoaderButton
                    className="u-margin-top"
                    type="button"
                    isLoading={
                      this.state.isLoadingConfirmAddress &&
                      address.delivery_line_1 === this.state.loadingAddress
                    }
                    text={TextOnly('confirm')}
                    loadingText={TextOnly('savingChanges')}
                    onClick={() => {
                      self.setState(
                        {
                          loadingAddress: address.delivery_line_1,
                          streetAddress1: address.components
                            .secondary_designator
                            ? primaryAddress
                            : address.delivery_line_1,
                          streetAddress2: address.components
                            .secondary_designator
                            ? secondaryAddress
                            : '',
                          city: address.components.city_name,
                          state: address.components.state_abbreviation,
                          zip: address.components.zipcode,
                          country: self.state.country,
                          phone: self.state.phone,
                        },
                        () => {
                          self.handleEditShop();
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });

    // International addresses
    const intAddressOptions = intAddrToVerify.map((address, index) => {
      return (
        <div className="c-verify-address u-margin-top" key={index}>
          <div className="c-verify-address__fields">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => {
              if (address[`address${num}`]) {
                return (
                  <dl key={num}>
                    <dt />
                    <dd>{address[`address${num}`]}</dd>
                  </dl>
                );
              }
              return null;
            })}
            <dl>
              <dt />
              <dd>{address.components.country_iso_3}</dd>
            </dl>
          </div>
          <div className="c-verify-address__footer">
            <div />
            <div className="c-verify-address__footer-right">
              <LoaderButton
                className="u-margin-top"
                type="button"
                isLoading={this.state.isLoadingConfirmAddress}
                text={TextOnly('confirm')}
                loadingText={TextOnly('verifying')}
                onClick={() => {
                  let streetAddr = address.address1;
                  if (
                    Object.keys(address.metadata).length === 0 ||
                    address.metadata.address_format
                      .split('|')[0]
                      .indexOf('premise') === -1
                  ) {
                    streetAddr =
                      address.components.premise +
                      ' ' +
                      address.components.thoroughfare;
                  }
                  let postalCode = address.components.postal_code;
                  if (
                    address.components.postal_code_short &&
                    address.components.postal_code_extra
                  ) {
                    postalCode = `${address.components.postal_code_short}-${address.components.postal_code_extra}`;
                  }
                  self.setState(
                    {
                      streetAddress1: streetAddr,
                      city: address.components.dependent_locality
                        ? address.components.dependent_locality +
                          ', ' +
                          address.components.locality
                        : address.components.locality,
                      state: address.components.administrative_area,
                      zip: postalCode,
                      country: self.state.country,
                      phone: self.state.phone,
                    },
                    () => {
                      self.handleEditShop();
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>
      );
    });

    const freeformProps = {};
    freeformProps.renderMenu = (results, menuProps) => {
      return CreateMenu(
        results,
        menuProps,
        'label',
        ['', 'label'],
        true,
        '',
        true
      );
    };

    const oemRegionsToAdd = [];

    if (this.state.oemRegions && this.state.oemIDsRemoved) {
      this.state.oemRegions.forEach((oemRegion) => {
        if (oemRegion.regionCode === this.props?.currentShop?.regionCode && (this.props?.currentShop?.removedOemIDs?.find((or) => or === oemRegion.oemID) ||
            this.props?.currentShop?.oemIDs?.find((or) => or === oemRegion.oemID) === undefined)) {
          oemRegionsToAdd.push(
            <div
              key={oemRegion.oemID}
              className={`u-margin-top-large ${this.state.windowWidth > 1000 ? "u-margin-left-small" : ""}`}>
              <div className="l-flex-wrap u-align-center">
                <div className={"l-inline-flex"}>
                  <input
                    id={oemRegion.oemID}
                    type="checkbox"
                    checked={this.state.oemIDsAdded.has(oemRegion.oemID)}
                    onChange={this.handleAddOEMChange}
                    disabled={false}
                    className="u-margin-right-large"
                  />
                  <div className="l-flex-column">
                    <label className="c-field__label left-padding u-padding-bottom-none">
                      {oemRegion.oemName}{' '}
                      {oemRegion.countries ?
                        <span className="u-font-weight-bold u-font-size-small u-text-warning">{'('+oemRegion.countries.join(', ')+' '+TextOnly('only')+')'}</span> : ''}
                      {oemRegion.hint ?
                        <ToolTip text={oemRegion.hint?.text ? oemRegion.hint?.text : oemRegion.hint} >
                          <span className="u-margin-left-small">
                            <i className={"c-btn__icon color-btn-blue u-font-size-small fal fa-question-circle"} />
                          </span>
                        </ToolTip>
                      : ''
                      }
                    </label>
                    { this.props.currentShop?.removedOemIDs?.includes(oemRegion.oemID) ?
                        <label className="u-font-size-small"><Text tid="expiresOn" sub={{date: shopPaymentInfo.paidThroughDate}}/></label>
                      : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });
    }

    let regions = {};
    const oemByRegion = {};
    let currentRegionName = '';

    if (this.state.oemRegions) {
      this.state.oemRegions.forEach(oemRegion => {
        if (oemRegion.regionCode === this.state.regionCode) {
          currentRegionName = oemRegion.regionName;
        }
        if (oemByRegion[oemRegion.regionName]) {
          oemByRegion[oemRegion.regionName].push(oemRegion);
          regions[oemRegion.regionName] = oemRegion.regionCode;
        } else {
          oemByRegion[oemRegion.regionName] = [oemRegion];
          regions[oemRegion.regionName] = oemRegion.regionCode;
        }
      })
    }

    const oemByRegionArraySorted = Object.keys(oemByRegion).sort();
    const removeOEMAllowed = (this.props.currentShop?.oemIDs?.length - (this.props.currentShop?.removedOemIDs?.length ?? 0)) > 1;
    const oemByRegionList = {};

    let disputeAmount = Number(parseFloat(this.props.currentShop?.disputeAmount).toFixed(2));
    if (this.props?.currentShop?.shopType !== "ENTERPRISE" && (!disputeAmount || disputeAmount === "")) {
      for (let i = 0; i < this.props.currentShop.shopPaymentInfo.transactions.length; i++) {
        if (this.props.currentShop.shopPaymentInfo.transactions[i].type !== 'paymentRefund') {
          disputeAmount = Number(parseFloat(this.props.currentShop.shopPaymentInfo.transactions[i].amount).toFixed(2));
          break;
        }
      }
    }

    if (oemByRegionArraySorted.length) {
      oemByRegionArraySorted.forEach((regionKey) => {
        oemByRegionList[regions[regionKey]] = (
          <table className={"t-table"}>
            <tbody>
              {oemByRegion[regionKey].sort((a,b)=>a.oemName>b.oemName?1:-1).map(oemRegion => {
                if (!this.props.currentShop?.oemIDs?.includes(oemRegion.oemID)) {
                  return <tr key={oemRegion.oemID} style={{display: 'none'}}></tr>;
                } else {
                  return (
                    <tr key={oemRegion.oemID}>
                      <td className="u-font-weight-bold u-font-size-lg">{'\u2022'}</td>
                      <td>
                        <label className="u-font-size-med">{oemRegion.oemName}{' '}
                          {oemRegion.countries ? <span className="u-font-weight-bold u-font-size-small u-text-warning">{'('+oemRegion.countries.join(', ')+' '+TextOnly('only')+')'}</span> : ''}
                        {oemRegion.hint ?
                          <ToolTip text={oemRegion.hint?.text ? oemRegion.hint?.text : oemRegion.hint} >
                            <span className="u-margin-left-small">
                              <i className={"c-btn__icon color-btn-blue u-font-size-small fal fa-question-circle"} />
                            </span>
                          </ToolTip>
                        : ''
                        }
                        </label>
                        {this.props.currentShop?.removedOemIDs?.includes(oemRegion.oemID) ?
                          <label className="u-font-size-small"><Text tid="activeUntilRenewal" sub={{date: shopPaymentInfo.paidThroughDate}}/></label>
                        : ''}
                      </td>
                      <td className="u-padding-left-none u-padding-right-none">
                      {!isEnterprise && removeOEMAllowed && !this.props.currentShop?.removedOemIDs?.includes(oemRegion.oemID) ?
                        <a
                          className="c-btn__icon fal fa-circle-xmark color-btn-blue u-padding-left-none u-padding-right-none"
                          onClick={this.handleRemoveOEMChange.bind(this, oemRegion.oemID)}
                        />
                        : ''
                      }
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
        );
      });
    }

    const oemRegionModalList = [];
    this.state.oemIDsRemoved.forEach((oemID) => {
      const oemRegionRec = this.state.oemRegions.find((oemRegion) => {
        return oemRegion?.oemID === oemID && oemRegion?.regionCode === this.props.currentShop?.regionCode;
      });
      oemRegionModalList.push(<li key={oemRegionRec?.oemID}>{ oemRegionRec?.oemName } {oemRegionRec?.hint ? oemRegionRec.hint?.text ? oemRegionRec.hint?.text : oemRegionRec.hint : ''}</li>);
    });

    const reinstateOemRegionModalList = [];

    this.state.oemIDsAdded.forEach((oemID) => {
      const oemRegionRec = this.state.oemRegions.find((or) => {
        return or.oemID === oemID
      });
      reinstateOemRegionModalList.push(<li key={oemRegionRec?.oemID}>{ oemRegionRec?.oemName } {oemRegionRec?.hint ? oemRegionRec.hint?.text ? oemRegionRec.hint?.text : oemRegionRec.hint : ''}</li>);
    });

    const config = this.state.config;
    const removalPriceChange = renewalTier === 'AutoAuth STANDARD'
      ? this.state.oemIDsRemoved.size * config?.prices?.oemRegionAddon
      : this.state.oemIDsRemoved.size * perOemRate;
    const chargeToday =
      this.props.currentShop ?
        this.calculateNumberToChargeToday(this.props.currentShop, this.state.oemIDsAdded) * perOemRate : 0;

    const shopRegionName = this.state.regionCode ?
      this.state.oemRegions?.find((or) => or.regionCode === this.state.regionCode )?.regionName : '';

    let OEMAddPriceChangeStatement = '';
    if (chargeToday) {
      OEMAddPriceChangeStatement =
        <Text tid={"willBeChargedChangingOEMRegion"}
          sub={{chargeToday: chargeToday,
            amount: renewalTier !== 'AutoAuth STANDARD'
              ? this.state.oemIDsAdded.size * perOemRate
              : this.state.oemIDsAdded.size * config?.prices?.oemRegionAddon}}
        />
    } else {
      OEMAddPriceChangeStatement = <Text tid={"noChargeTodayAddedPriceOEMRegions"}
          sub={{amount: renewalTier !== 'AutoAuth STANDARD'
          ? this.state.oemIDsAdded.size * perOemRate
          : this.state.oemIDsAdded.size * config?.prices?.oemRegionAddon}}
        />
    }

    return (
      <div className="home">
        <Header title={TextOnly('shopProfile')} />

        <div className={!this.props.user?.shops || !this.state?.oemRegions ? 'hide-element' : ''}>
          <div className="l-flex-wrap-center">
            <form onSubmit={this.validateShopFields}>
              <div className="l-container-sm l-container-flex-box">
                <h2>
                  <Text tid="shopInfo" />
                </h2>
                <div className="c-field">
                  <label htmlFor="shop" className="c-field__label">
                    <span className="u-text-error">*</span>{' '}
                    <Text tid="shopName" />
                  </label>
                  <input
                    id="shop"
                    type="text"
                    maxLength="50"
                    className={
                      !nameValidate(this.state.shop)
                        ? 'c-input__error'
                        : 'c-input'
                    }
                    placeholder={TextOnly('shopName')}
                    value={this.state.shop}
                    onChange={this.handleChange}
                  />
                  {!nameValidate(this.state.shop) ? (
                    <div className="c-field__error">
                      <Text tid="shopNameHint" />
                    </div>
                  ) : null}
                </div>
                <>
                  <CountryPicker
                    country={this.state.country}
                    countryVerify={true}
                    handleChange={this.handleChange}
                  />
                  <div className={`c-field`}>
                    <label
                      htmlFor="streetAddress1"
                      className="c-field__label"
                    >
                      <span className="u-text-error">*</span>{' '}
                      <Text tid="streetAddress1" />
                    </label>
                    <AsyncTypeahead
                      filterBy={() => true}
                      id="streetAddress1"
                      inputProps={{
                        id: 'streetAddress1',
                        className:
                          this.state.streetAddress1?.length > 0
                            ? 'c-input'
                            : 'c-input__error',
                      }}
                      defaultInputValue={this.state.streetAddress1}
                      isLoading={this.state.isLoading}
                      clearButton
                      onClear={this.handleClear}
                      flip={false}
                      minLength={1}
                      labelKey={(option) =>
                        isInternational
                          ? `${this.state.streetAddress1 || option.address_text}`
                          : `${option.street_line}`
                      }
                      maxResults={100}
                      onSearch={this.handleSearchAddress}
                      onInputChange={(text) => {
                        if (text.trim().length === 0) {
                          this.setState({
                            addressSuggestions: [],
                          });
                        }
                        this.setState({
                          streetAddress1: text,
                        });
                      }}
                      onChange={async (option) => {
                        option = option[0];
                        //User is deleting/backspacing or clicked clearButton
                        if (!option || option.length === 0) {
                          this.setState({
                            addressSuggestions: [],
                            streetAddress1: '',
                            streetAddress2: '',
                            city: '',
                            state: '',
                            zip: '',
                          });
                          return;
                        }

                        if (isInternational) {
                          const result = await this.getAddressInfo(option);
                          if(result) {
                            if(result.length > 1) {
                              this.setState({
                                typeaheadOpen: true,
                                addressSuggestions: result || [],
                                streetAddress2: '',
                                city: '',
                                state: '',
                                zip: '',
                              });
                            } else {
                              this.setState({
                                typeaheadOpen: false,
                                streetAddress1: result[0].street,
                                streetAddress2: '',
                                city: result[0].locality,
                                state: result[0].administrative_area || result[0].locality,
                                zip: result[0].postal_code,
                              });
                            }
                          }
                        } else {
                          let needsExpansion =
                            option.secondary && option.entries > 1;
                          if (needsExpansion) {
                            this.setState({
                              typeaheadOpen: true,
                              streetAddress1: option.street_line,
                              streetAddress2: '',
                              city: option.city,
                              state: option.state,
                              zip: option.zipcode,
                            });
                            let selectedQuery = `${encodeURIComponent(this.state.streetAddress1)}&selected=${encodeURIComponent(`${option.street_line} ${option.secondary} (${option.entries}) ${option.city} ${option.state} ${option.zipcode}`)}`;
                            this.handleSearchAddress(selectedQuery);
                          } else {
                            this.setState({
                              typeaheadOpen: undefined,
                              streetAddress1: option.street_line,
                              streetAddress2: option.secondary || '',
                              city: option.city,
                              state: option.state,
                              zip: option.zipcode,
                            });
                          }
                        }
                      }}
                      options={this.state.addressSuggestions}
                      paginate={false}
                      open={
                        this.state.addressSuggestions.length === 0
                          ? false
                          : this.state.typeaheadOpen
                      }
                      placeholder={TextOnly('streetAddress1')}
                      renderMenuItemChildren={(option, props) => {
                        if (isInternational) {
                          return (
                            <div>
                              {option.address_text}
                            </div>
                          );
                        }

                        let secondaryStr =
                          option.secondary.length !== 0
                            ? `, ${option.secondary}`
                            : '';
                        let needsExpansion =
                          option.secondary && option.entries > 1;
                        if (needsExpansion) {
                          secondaryStr = (
                            <span>
                              , {option.secondary} #
                              <b>
                                {'('}
                                {option.entries} results{')'}
                              </b>
                            </span>
                          );
                        }
                        return (
                          <div>
                            {option.street_line}
                            {secondaryStr}, {option.city} {option.state},{' '}
                            {option.zipcode}
                          </div>
                        );
                      }}
                    />  {/* AsyncTypeahead */}
                    {this.state.streetAddress1?.trim().length === 0 ? (
                      <p className="u-text-error">
                        <Text tid="fieldCannotBeBlank" />
                      </p>
                    ) : null}
                  </div>
                  <div className={`c-field`}>
                    <label
                      htmlFor="streetAddress2"
                      className="c-field__label"
                    >
                      <Text tid="streetAddress2" />:
                    </label>
                    <input
                      id="streetAddress2"
                      type="text"
                      maxLength="50"
                      className="c-input"
                      placeholder={TextOnly('streetAddress2')}
                      value={this.state.streetAddress2}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className={`c-field`}>
                    <label htmlFor="city" className="c-field__label">
                      <span className="u-text-error">*</span>{' '}
                      <Text tid="city" />
                    </label>
                    <input
                      id="city"
                      type="text"
                      maxLength="50"
                      className={
                        this.state.city?.length > 0
                          ? 'c-input'
                          : 'c-input__error'
                      }
                      placeholder={TextOnly('cityTown')}
                      value={this.state.city}
                      onChange={this.handleChange}
                    />
                    {this.state.city?.trim().length === 0 ? (
                      <p className="u-text-error">
                        <Text tid="fieldCannotBeBlank" />
                      </p>
                    ) : null}
                  </div>
                  <div className="l-flex-wrap l-flex-gap">
                    {this.state.country === 'US' ? (
                      <StatePicker
                        state={this.state.state || ''}
                        handleChange={this.handleChange}
                      />
                    ) : (
                      <div className={`c-field l-container-xs`}>
                        <label htmlFor="state" className="c-field__label">
                          <span className="u-text-error">*</span>{' '}
                          <Text tid="state" />
                        </label>
                        <input
                          id="state"
                          type="text"
                          maxLength="50"
                          className={`u-input-side-by-side c-input${
                            this.state.state?.trim().length === 0
                              ? '__error'
                              : ''
                          }`}
                          placeholder={TextOnly('state')}
                          value={this.state.state}
                          onChange={this.handleChange}
                        />
                        {this.state.state?.trim().length === 0 ? (
                          <p className="u-text-error">
                            <Text tid="fieldCannotBeBlank" />
                          </p>
                        ) : null}
                      </div>
                    )}

                    <div className={`c-field l-container-xs`}>
                      <label htmlFor="zip" className="c-field__label">
                        <span className="u-text-error">*</span>{' '}
                        <Text tid="zipCode" />
                      </label>
                      <input
                        id="zip"
                        type="text"
                        maxLength="50"
                        className={`u-input-side-by-side c-input${
                          this.state.zip?.trim().length === 0 ? '__error' : ''
                        }`}
                        placeholder={TextOnly('zipCode')}
                        value={this.state.zip}
                        onChange={this.handleChange}
                      />
                      {this.state.zip?.trim().length === 0 ? (
                        <p className="u-text-error">
                          <Text tid="fieldCannotBeBlank" />
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={`c-field`}>
                    <label htmlFor="phone" className="c-field__label">
                      <span className="u-text-error">*</span>{' '}
                      <Text tid="phoneNumber" />
                    </label>
                    <PhoneInput
                      id="phone"
                      type="tel"
                      maxLength="50"
                      className={`c-input ${
                        !isPossiblePhoneNumber(this.state.shopPhoneNumber)
                          ? "phoneError"
                          : ""
                      }`}
                      value={this.state.shopPhoneNumber}
                      defaultCountry={this.state.country || ""}
                      onChange={this.handleChangePhoneNumber}
                    />
                  </div>
                  <div className={`c-field`}>
                    <div className="l-flex-wrap u-align-center">
                      <div><Text tid='shareInfo'/>:</div>
                      <div className={"l-inline-flex"}>
                        <input
                          id="shareInfoYes"
                          type="radio"
                          name="shareInfo"
                          value={true}
                          checked={this.state.shareInfo}
                          onChange={()=>this.setState({ shareInfo: !this.state.shareInfo })}
                          className="u-margin-right-large"
                        />
                        <label
                          className="c-field__label left-padding u-padding-bottom-none u-margin-right-large"
                        >
                          <Text tid="yes" />
                        </label>
                        <input
                          id="shareInfoNo"
                          type="radio"
                          name="shareInfo"
                          value={false}
                          checked={!this.state.shareInfo}
                          onChange={()=>this.setState({ shareInfo: !this.state.shareInfo })}
                          className="u-margin-right-large"
                        />
                        <label
                          htmlFor="shareInfoNo"
                          className="c-field__label left-padding u-padding-bottom-none"
                        >
                          <Text tid="no" />
                        </label>
                      </div>
                    </div>
                  </div>
                </>
                <div className="c-field">
                  <LoaderButton
                    type="submit"
                    disabled={
                      !this.profileFieldsHaveChanged() ||
                      !nameValidate(this.state?.shop)
                    }
                    isLoading={this.state.isLoadingShopName}
                    text={TextOnly('saveChanges')}
                    loadingText={TextOnly('savingChanges')}
                  />
                </div>
              </div>
            </form>
            <div className="l-container-sm l-container-flex-box">
              <h2>
                <Text tid="subscription" />
              </h2>
              <div className="c-box">
                <section className="c-section">
                  <label className="c-section__label">
                    <Text tid="currentPlan" />
                  </label>
                  <div className="c-section__content">
                    <span className="c-section__key">
                      AutoAuth {this.props.currentShop.shopType}
                    </span>
                  </div>
                </section>

                <form>
                  <section className="c-section">
                    <label className="c-section__label">
                      <Text tid="region" />
                    </label>
                    <div className="c-section__content">
                      <span className="c-section__key">
                        {shopRegionName}
                      </span>
                    </div>
                  </section>
                  <section className="c-section">
                    <div className="c-field u-margin-top-large">
                      <CompatibleToolsModal
                        openButtonText={TextOnly('vehicleToolCompatibility')}
                        closeButtonText={TextOnly('close')}
                        showOnlyRegion={currentRegionName}
                        chartData={this.state.chartData}
                        whiteButton={true}
                        noCloseButton={true}
                      />
                    </div>
                  </section>
                  <section className="c-section l-margin-top-2rem">
                    <label className="c-section__label">
                      <Text tid="activeOems" />
                    </label>
                    <div className="c-section__content">
                      <span className="c-section__key">
                        <ul className='u-padding-none'>
                          {oemByRegionList[this.state.regionCode] ?? ''}
                        </ul>
                      </span>
                    </div>
                  </section>
                </form>

              { !isEnterprise && oemRegionsToAdd.length ?
                <form>
                  <section className="c-section u-margin-bottom-small">
                    <label className="c-section__label">
                      <Text tid="inactiveOEMs" />
                    </label>
                    <div className="c-section__content">
                      <span className="c-section__key">
                        {oemRegionsToAdd}
                      </span>
                    </div>
                    <div className="c-field u-margin-top-large">
                      <LoaderButton
                        id="changeOemRegions"
                        className="c-btn-full"
                        type="button"
                        isLoading={this.state.isLoadingChangeOemRegions}
                        disabled={
                          this.state.oemIDsAdded.size === 0
                        }
                        text={TextOnly('addOems')}
                        loadingText={TextOnly('savingOemRegions')}
                        onClick={this.handleReinstateOEMRegionButton.bind(this)}
                      />
                    </div>
                  </section>

                </form>
                : '' }

                { systemConfig.CERTIFIED_TECH_STATE === "ACTIVE" ?

                  <>
                    <section className="c-section u-padding-top-large">
                      <label className="c-section__label">
                        <Text tid="availableTechCertifications" />
                      </label>
                      <div className="c-section__content">
                        <span className="c-section__key text-bold">
                          { (this.props?.currentShop.shopMaxTechCerts ?? 0)
                              - (this.props?.currentShop.numUsedTechCerts ?? 0) }
                        </span>
                      </div>
                    </section>

                    <section className="c-section u-padding-top-large">
                      <label className="c-section__label">
                        <Text tid="numPurchasedTechCertifications" />
                      </label>
                      <div className="c-section__content">
                        <span className="c-section__key text-bold">
                          { (this.props?.currentShop.shopMaxTechCerts ?? 0)
                           }
                        </span>
                      </div>
                    </section>

                    <AddTechCertification
                      perTechCertRate={perTechCertRate}
                      currentShop={this.props.currentShop}
                      fetchUser={this.props.fetchUser}
                    />
                  </>
                : null }

                <section className="c-section u-padding-top-large">
                  <label className="c-section__label">
                    <Text tid="maximumShopUsers" />
                  </label>
                  <div className="c-section__content">
                    <span className="c-section__key text-bold">
                      {this.props.user.shopMaxUsers}
                    </span>
                  </div>
                </section>

                <div className="u-margin-top-large">
                  {!isEnterprise && paymentState !== PAYMENT_STATES.NONE ? (
                    <>
                      <form>
                        <div className="c-select">
                          <select
                            id="addUsers"
                            onChange={this.handleNumToAddChange.bind(this)}
                            value={this.state.numToAdd || 'default'}
                          >
                            <option key="default" value="default" disabled>
                              {TextOnly("addShopUsersTools")}
                            </option>
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 80,
                                100, 150, 200, 300,
                                ].filter((num) => maxAddUsers >= num)
                                .map((num) => {
                                      let rate = num * perUserRate;
                                      return (
                                        <option key={num} value={num.toString()}>
                                          {num} (+${rate}/{TextOnly('year')})
                                        </option>
                                      );
                                    })}
                          </select>
                        </div>

                        <div className="c-field u-margin-top-large u-padding-bottom-large">
                          <LoaderButton
                            id="increaseMaxUsers"
                            className="c-btn-full"
                            type="button"
                            isLoading={this.state.isLoadingIncreaseMaxUsers}
                            disabled={
                              this.state.numToAdd === null ||
                              isNaN(this.state.numToAdd)
                            }
                            text={TextOnly('upgradeUsersToolsCapacity')}
                            loadingText={TextOnly('increasingUsers')}
                            onClick={this.handleAddUsersButton.bind(this)}
                          />
                          {maxAddUsers <= 0 ?
                            this.props.currentShop.shopType === "STANDARD" ?
                            (
                              <p className="u-font-weight-bold">
                                <Text
                                  tid="standardReachedMaxUsers"
                                  sub={{
                                    maxUserCount: (
                                      <span>
                                        {this.props?.currentShop?.shopUserCap
                                          ? this.props?.currentShop?.shopUserCap
                                          : isPlus
                                          ? this.state?.config?.shopMaxValues
                                              .plusMaxUsers
                                          : this.state?.config?.shopMaxValues
                                              .standardMaxUsers}
                                      </span>
                                    ),
                                    upgradePlus: (
                                      <a
                                        href="/upgradeToPlus/comparePlans"
                                        to="/upgradeToPlus/comparePlans"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {capitalize(TextOnly('upgradeToStandardPlus'))}
                                      </a>
                                    ),
                                  }}
                                />
                              </p>
                            ) :
                            (
                              <p className="u-font-weight-bold">
                                <Text
                                  tid="reachedMaxUsers"
                                  sub={{
                                    userCount: (
                                      <span>
                                        {this.props.user.shopMaxUsers}
                                      </span>
                                    ),
                                    maxUserCount: (
                                      <span>
                                        {this.props?.currentShop?.shopUserCap
                                          ? this.props?.currentShop?.shopUserCap
                                          : isPlus
                                          ? this.state?.config?.shopMaxValues
                                              .plusMaxUsers
                                          : this.state?.config?.shopMaxValues
                                              .standardMaxUsers}
                                      </span>
                                    ),
                                    contactLink: (
                                      <a
                                        href="https://info.autoauth.com/contact/"
                                        to="https://info.autoauth.com/contact/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {capitalize(TextOnly('contactUs'))}
                                      </a>
                                    ),
                                  }}
                                />
                              </p>
                            ) : null}
                        </div>
                      </form>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="l-container-sm l-container-flex-box">
              {!isEnterprise && paymentState !== PAYMENT_STATES.NONE ? (
                <div>
                  <h2>
                    <Text tid="paymentInfo" />
                  </h2>
                  {lastFourStr ? (
                    <div>
                      <div>
                        <label>
                          <Text tid="savedPaymentInfo" />:
                        </label>
                        <div>
                          <label>
                            {shopPaymentInfo.cardType} {lastFourStr}
                          </label>
                          <label>
                            <Text tid="paidThru" />{' '}
                            {shopPaymentInfo.paidThroughDate}
                          </label>
                          <label>
                            <Text tid="exp" />:{' '}
                            {shopPaymentInfo.expirationDate}
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {expired ? (
                        <div className="c-field__error">
                          <Text tid="noPaymentMethodAvailable" />{' '}
                          <Text tid="yourSubscriptionEnded" />{' '}
                          {shopPaymentInfo.paidThroughDate}
                        </div>
                      ) : (
                        <div className="c-field__error">
                          <Text tid="noPaymentMethodAvailable" />{' '}
                          <Text tid="yourSubscriptionWillEnd" />{' '}
                          {shopPaymentInfo.paidThroughDate}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
              {!isEnterprise && paymentState !== PAYMENT_STATES.NONE ? (
                <>
                  <h2 className="u-margin-bottom-none">
                    <Text tid="changePaymentInfo" />
                  </h2>
                  <form
                    onSubmit={function (event) {
                      event.preventDefault();
                    }}
                  >
                    <div className="col-sm-5 margin-top-40">
                      <div id="braintree-container"></div>
                    </div>
                    <div className='l-container-center u-margin-bottom-small'>
                      <LoaderButton
                        id="changePayment"
                        type="submit"
                        isLoading={this.state.isLoadingChangePayment}
                        text={
                          lastFourStr
                            ? TextOnly('changePayment')
                            : paymentState === PAYMENT_STATES.TERMINATED ||
                              paymentState === PAYMENT_STATES.USER_CANCEL
                            ?
                              <>
                                {TextOnly('reinstateShop')}
                                <br/>
                                (${ paymentState === PAYMENT_STATES.TERMINATED ?
                                    this.props.currentShop.shopPaymentInfo.nextBillAmount
                                  : '0.00' })
                              </>
                            : TextOnly('addPayment')
                        }
                        loadingText={TextOnly('savingPayment')}
                      />
                    </div>
                    {lastFourStr &&
                      nextBillAmount &&
                      !isEnterprise &&
                      paymentState !== PAYMENT_STATES.NONE ? (
                        <div className="c-box-sm-padding">
                          <section className="c-section">
                            <label className="c-section__label">
                              <Text tid="nextPaymentAmount" />
                            </label>
                            <div>
                              <span className="c-section__key c-section__content">
                                <span>
                                  <span className="text-bold">${nextPaymentAmount} ({renewalTier})</span>
                                  <br />
                                  { paymentState !== PAYMENT_STATES.PAST_DUE ?
                                    <>
                                      <Text tid="toBeChargedOn" />{' '}
                                        <span className="text-bold">{nextBillDate}</span>
                                    </>
                                    : <span className="c-field__error">{ TextOnly("pastDue") }</span>
                                  }
                                </span>
                                <span>
                                  <button
                                    className="c-btn-icon"
                                    onClick={() => {
                                      this.setState({
                                        title: TextOnly('priceBreakdown'),
                                        showPriceBreakdown: true,
                                        description:
                                          <PriceBreakdown
                                            config={config}
                                            renewalTier={renewalTier}
                                            shop={this.props.currentShop}
                                          />,
                                      });
                                    }}
                                  >
                                    <div className="c-btn__inner">
                                      <i className="c-btn__icon fal fa-question-circle" />
                                    </div>
                                  </button>
                                </span>
                              </span>
                            </div>
                          </section>
                        </div>
                      ) : null}

                    <div className="c-box--white">
                      <div className="u-padding-bottom-large">
                        <button
                          className="c-btn-outline c-btn-full"
                          onClick={this.handlePaymentHistoryButton.bind(this)}
                        >
                          <Text tid="viewPaymentHistory" />
                        </button>
                      </div>
                      <div>
                        {paymentState !== PAYMENT_STATES.TERMINATED &&
                        paymentState !== PAYMENT_STATES.USER_CANCEL &&
                        !isEnterprise &&
                        paymentState !== PAYMENT_STATES.NONE ? (
                          <LoaderButton
                            type="button"
                            className="c-btn-outline c-btn-full"
                            isLoading={this.state.isLoadingCancelSubscription}
                            text={TextOnly('cancelSubscription')}
                            loadingText={TextOnly('canceling')}
                            onClick={this.handleCancelSubscriptionButton.bind(
                              this
                            )}
                          />
                        ) : null}
                      </div>
                      {this.props?.currentShop?.shopType ===
                        SHOP_TYPES.STANDARD && (
                        <div className="u-margin-top-large">
                          <NavLink
                            className="c-btn-full"
                            to="/upgradeToPlus/comparePlans"
                          >
                            <Text tid="upgradeToStandardPlus" />
                          </NavLink>
                        </div>
                      )}
                      { (this.props?.currentShop?.shopType === SHOP_TYPES.PLUS && !isDowngradingAtRenewal) ?
                          <div className="u-margin-top-large">
                            <ToolTip
                              text={TextOnly('downgradeDisableExplain')}
                              // additionalStyles={{ 'margin-left': '-100px' }}
                            >
                              <div>
                                <LoaderButton
                                  type="button"
                                  className="c-btn-outline c-btn-full"
                                  isLoading={this.state.isLoadingDowngradeSubscription}
                                  disabled={!this.eligibleToDowngrade()}
                                  text={TextOnly('downgradeToStandard')}
                                  loadingText={TextOnly('downgrading')}
                                  onClick={this.handleDowngradeSubscriptionButton.bind(
                                    this
                                  )}
                                />
                              </div>
                            </ToolTip>
                          </div>
                          : ''
                      }
                      { (this.props?.currentShop?.shopType === SHOP_TYPES.PLUS && isDowngradingAtRenewal) ?
                          <div className="u-margin-top-large">
                            <LoaderButton
                              type="button"
                              className="c-btn-full"
                              isLoading={this.state.isLoadingCancelDowngradeSubscription}
                              disabled={!this.eligibleToDowngrade()}
                              text={TextOnly('cancelDowngradeToStandard')}
                              loadingText={TextOnly('cancelingDowngrading')}
                              onClick={this.handleCancelDowngradeSubscriptionButton.bind(
                                this
                              )}
                            />
                          </div>
                          : ''
                      }
                      <div className='l-container-flex-box'>
                        { (shopState === SHOP_STATES.TERMINATED ||
                          shopState === SHOP_STATES.USER_CANCEL ||
                          shopState === SHOP_STATES.SUSPENDED) && suspendReason === 'DISPUTE_LOST' ?
                          <LoaderButton
                            id="repayDisputedPayment"
                            type="button"
                            className="red-btn"
                            isLoading={this.state.isLoadingRepayDisputedPayment}
                            text={
                              <>
                                { TextOnly('repayDisputedPayment') }
                                <br />
                                (${disputeAmount} + $15.00)
                              </> }
                              onClick={this.handleRepayDisputedPaymentButton.bind(this)}
                              loadingText={TextOnly('repayingPayment')}
                          />
                        : '' }
                      </div>
                    </div>
                  </form>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className={this.props.user?.shops && this.state?.oemRegions ? 'hide-element' : 'loading-panel'}>
          <i
            className="fal fa-spinner-third spinning"
            title={TextOnly('loading')}
          />
        </div>
        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          size="small"
          handleCancel={this.handleCancel.bind(this)}
        />

        <Dialog
          isOpen={this.state.showAddUsersModal}
          onDismiss={ ()=> {if(!this.state.isLoadingIncreaseMaxUsers) this.handleCancelModal.bind(this)}}
          className="c-modal"
          aria-label={TextOnly('confirmAddUsers')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelModal.bind(this)}
            disabled={this.state.isLoadingIncreaseMaxUsers}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmAddUsers" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouWantToAdd" sub={{num: this.state.numToAdd}} />
            </p>
            <p>
              <Text tid="youWillBeCharged" /> $
              {parseInt(this.state.numToAdd) * perUserRate}/
              <Text tid="year" /> <Text tid="proratedPaymentExplain" />
            </p>
            <p className={perUserRate > config?.prices?.plusUserAddon ? "u-text-error" : 'hide-element'}>
              <Text tid="note" />
              <Text tid={"userUpchargeExplain"} sub={{base: userBasePrice, upcharge: oemUserUpcharge}} />
            </p>
            <button
              className="c-btn-outline"
              onClick={this.handleCancelModal.bind(this)}
              disabled={this.state.isLoadingIncreaseMaxUsers}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={this.state.isLoadingIncreaseMaxUsers}
              text={TextOnly('confirm')}
              loadingText={TextOnly('savingChanges')}
              onClick={this.handleConfirmModal.bind(this)}
            />
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showOEMRegionRemovalModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal"
          aria-label={TextOnly('confirmOEMRegions')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          { removeOEMAllowed ?
            <>
              <h1 className="c-modal__heading">
                <Text tid="confirmOEMRegions" />
              </h1>

              <div className="c-modal__body">
                <p>
                  <Text tid="areYouSureYouWantToRemoveOEMRegions" />
                </p>
                <span>
                  <ul>
                    { oemRegionModalList }
                  </ul>
                </span>
                <p>
                  <Text tid={"oemRegionExplanation"} sub={{ date: nextBillDate }}/>
                </p>
                <p>
                  <Text tid={"subscriptionPriceReductionOEMRegion"} sub={{amount: removalPriceChange}}/>
                </p>
                <p className={removalPriceChange > config?.prices?.oemRegionAddon * this.state.oemIDsRemoved.size
                    ? "u-text-error" : 'hide-element'}>                  <Text tid="note" />
                  <Text tid={"oemUpchargeExplain"} sub={{base: oemBasePrice, upcharge: oemUserUpcharge}} />
                </p>
                <button
                  className="c-btn-outline"
                  onClick={this.handleCancelModal.bind(this)}
                  disabled={this.state.isLoadingChangingOEMRegions}
                >
                  <Text tid="cancel" />
                </button>{' '}
                <LoaderButton
                  type="button"
                  isLoading={this.state.isLoadingChangingOEMRegions}
                  text={TextOnly('confirm')}
                  loadingText={TextOnly('savingChanges')}
                  onClick={this.handleConfirmRemoveOEMRegion.bind(this)}
                />
              </div>
            </>
          :
            <>
              <h1 className="c-modal__heading">
                <Text tid="minimumNumberOfOEMs" />
              </h1>
              <div className="c-modal__body">
                <p>
                  <Text tid="cannotRemoveLastOEM" />
                </p>
              </div>
            </>
          }
        </Dialog>

        <Dialog
          isOpen={this.state.showOEMRegionReinstateModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal"
          aria-label={TextOnly('confirmReinstateOEMRegions')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmReinstateOEMRegions" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouWantToReinstateOEMRegions" />
            </p>
            <span>
              <ul>
                { reinstateOemRegionModalList }
              </ul>
            </span>
            <p>
              <Text tid={"oemRegionReinstatedExplanation"} />
            </p>
            <p>
              { OEMAddPriceChangeStatement }
            </p>
            <p className={chargeToday && chargeToday > config?.prices?.oemRegionAddon
              ? "u-text-error"
              : 'hide-element'}>
              <Text tid={renewalTier === 'AutoAuth STANDARD'
                ? "noteForPlus"
                : "note"}/>
              <Text
                tid="oemUpchargeExplain"
                sub={{base: oemBasePrice, upcharge: oemUserUpcharge}}/>
            </p>
            <button
              className="c-btn-outline"
              onClick={this.handleCancelModal.bind(this)}
              disabled={this.state.isLoadingChangingOEMRegions}
            >
              <Text tid="cancel" />
            </button>{' '}
            <LoaderButton
              type="button"
              isLoading={this.state.isLoadingChangingOEMRegions}
              text={TextOnly('confirm')}
              loadingText={TextOnly('savingChanges')}
              onClick={this.handleConfirmOEMRegionReinstate.bind(this)}
            />
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showRepayDisputedPaymentModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal--medium"
          aria-label={TextOnly('confirmRepaymentDisputedPayment')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmRepaymentDisputedPayment" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="consentToRepayPlusFifteen" />
            </p>
            <p>
              <Text tid={"doYouConsent"} sub={{amount: disputeAmount, chargeAmount: Number(Number(disputeAmount)+15).toFixed(2)}}/>
            </p>
            <p>
              <button
                className="c-btn-outline"
                onClick={this.handleCancelModal.bind(this)}
                disabled={this.state.isLoadingChargeRepayment}
              >
                <Text tid="cancel" />
              </button>{' '}
              <LoaderButton
                type="button"
                isLoading={this.state.isLoadingChargeRepayment}
                text={TextOnly('confirm')}
                loadingText={TextOnly('chargingRepayment')}
                onClick={this.handleChargeRepayment.bind(this)}
              />
            </p>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showReceiptModal}
          onDismiss={this.handleCancelReceiptModal.bind(this)}
          className="c-modal"
          aria-label={TextOnly('autoAuthReceipt')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelReceiptModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>

          <div className="c-modal__body">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '9rem',
              }}
            >
              <Document
                file={this.state.currentReceipt?.data || null}
                loading={TextOnly('loading')}
              >
                <Page pageNumber={1} scale={this.state.pdfScale} />
              </Document>
            </div>
            <div>
              <button
                className="c-btn"
                onClick={this.handlePrintReceipt.bind(this)}
              >
                <Text tid="print" />
              </button>{' '}
              <button
                className="c-btn-outline"
                onClick={this.handleCancelReceiptModal.bind(this)}
              >
                <Text tid="dismiss" />
              </button>
            </div>
          </div>
        </Dialog>
        <ConfirmModal
          showConfirmModal={this.state.showCancelSubscriptionModal}
          closeModal={this.handleCancelCancelSubscription.bind(this)}
          onConfirm={this.handleConfirmCancelSubscriptionModal.bind(this)}
          textModal={TextOnly('confirmCancelSubscriptionMessage')}
          className="c-modal-slider"
          titleModal={TextOnly('confirmCancelSubscription')}
          textCancelButton={TextOnly('dismiss')}
          textLoadingConfirmButton={TextOnly('canceling')}
        />

        <Dialog
          isOpen={this.state.showDowngradeSubscriptionModal}
          onDismiss={this.handleCancelDowngradeSubscription.bind(this)}
          className="c-modal-slider"
          aria-label={TextOnly('confirmDowngradeSubscription')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelDowngradeSubscription.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmDowngradeSubscription" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="confirmDowngradeSubscriptionMessage" />
            </p>
            <button
              onClick={this.handleConfirmDowngradeSubscriptionModal.bind(this)}
              className="c-btn"
              disabled={this.state.showDowngradingMessage}
            >
              {this.state.showDowngradingMessage
                ? TextOnly('downgrading')
                : TextOnly('confirm')}
            </button>{' '}
            <button
              className="c-btn-outline"
              onClick={this.handleCancelDowngradeSubscription.bind(this)}
            >
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showPaymentHistoryModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal-slider"
          aria-label={TextOnly('paymentHistory')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="paymentHistory" />
          </h1>
          <div className={this.state.windowWidth > 700 ? "c-modal__body" : "c-modal__body-xs"}>
            <ReactTable
              columns={transactionColDefs}
              data={transactions}
              className="dropdown -highlight"
              showPaginationTop={true}
              previousText={TextOnly('previousPage')}
              nextText={TextOnly('nextPage')}
              pageText={TextOnly('page')}
              ofText={TextOnly('of')}
              rowsText={TextOnly('rows')}
              noDataText={TextOnly('noDataYet')}
              defaultPageSize={10}
              defaultSorted={[
                {
                  id: 'fullDate',
                  desc: true,
                },
              ]}
              getTdProps={(state, rowInfo, column) => {
                return {
                  style: {
                    justifyContent:
                      column.id === 'status' ?
                      'center' :
                      column.id === 'amount' ? 'right' : 'left',
                    display:
                      (column.id === 'chargeDescription'  | column.id === 'receipt') && collapseColumns
                        ? 'none'
                        : 'flex',
                    paddingRight: (column.id === 'amount' && this.state.windowWidth > 574) ? 20 : 10,
                  },
                };
              }}
              getTheadThProps={(state, rowInfo, column) => {
                return {
                  style: {
                    display:
                      (column.id === 'chargeDescription'  | column.id === 'receipt') &&
                      collapseColumns
                        ? 'none'
                        : 'block',
                  },
                };
              }}
              SubComponent={
                this.state.windowWidth < 574
                  ? (row) => {
                      return (
                        <div>
                          <div>
                            <span className="text-bold">
                              <Text tid="chargeDescription" />:
                            </span><br />
                            {row.original.type}
                          </div>
                          <div
                            className={`c-tag-${
                              row.original.failedCharge ? 'error' : 'success'
                            } x-small u-margin-none u-margin-right`}
                          >
                            <span className={`c-tag__item`} >
                              <span
                                className={`c-btn__icon fal fa-${
                                  row.original.failedCharge ? 'times' : 'check'
                                }-circle`}
                              />
                              {row.original.status}
                            </span>
                          </div>
                          <div>
                            <div style={{ justifyContent: 'center' }}>
                              <button
                                className="c-btn-icon-outline u-margin-right"
                                onClick={this.handleViewReceipt.bind(this, row.original.id)}
                              >
                                <div className="c-btn__inner">
                                  <span
                                    className="c-btn__icon fas fa-receipt"
                                    title={TextOnly('viewReceipt')}
                                  />
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  : null
              }
            />
          </div>
        </Dialog>
        <Dialog
          isOpen={this.state.showVerifyAddressModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal-slider"
          aria-label={TextOnly('verifyAddress')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>

          {addressOptions.length > 0 || intAddressOptions.length > 0 ? (
            <>
              <h2 className="c-verify-address__title">
                <Text tid="verify" />
              </h2>
              {this.state.country === 'US' ? addressOptions : intAddressOptions}
            </>
          ) : (
            <h2 className="c-verify-address__title">
              <Text tid="unableToFindAddress" />
            </h2>
          )}
        </Dialog>
        <Dialog
          isOpen={this.state.showModalMO}
          className="c-modal"
          aria-label={TextOnly('oemCompatibility')}
        >
          <h1 className="c-modal__heading">
            <Text tid="oemCompatibility" />
          </h1>

          <div className="c-modal__body">
            <CompatibleTools
              showOnlyRegion={currentRegionName}
              closeFunction={this.handleCancelModalMO}
              closeButtonText={TextOnly('confirm')}
              moveCloseButtonToTop={true}
              chartData={this.state.chartData}
            />
          </div>
        </Dialog>
        <AlertModal
          showModal={this.state.showPriceBreakdown}
          handleCancel={() => this.setState({showPriceBreakdown: false})}
          title={this.state.title}
          message={this.state.description}
        />
      </div>
    );
  }
}
