import React, { useState } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import { isValidEmail, nameValidate } from '../../../libs/utils';
import BasicPhoneInput from '../../../components/BasicPhoneInput';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import { crmUpdateShopCustomer } from '../../../libs/db-lib';
import { languageOptions } from '../../../libs/languages';


const EditCustomerForm: React.FC<any> = (props) => {
  const {
    customer,
    shopCountry,
    setIsLoading,
    isLoading,
    updateSingleCustomer,
  } = props;

  const langOptions = Object.keys(languageOptions).map((key) => {
    return (
      <option key={key} value={key}>
        {languageOptions[key as keyof typeof languageOptions]}
      </option>
    );
  });

  const [firstName, setFirstName] = useState<string>(customer.firstName);
  const [lastName, setLastName] = useState<string>(customer.lastName);
  const [emailInput, setEmailInput] = useState<string>(customer.email);
  const [phoneNumber, setPhoneNumber] = useState<string>(customer.phoneNumber || '');
  const [contactPref, setContactPref] = useState<
  'EMAIL' | 'PHONE' | 'BOTH' | ''
  >(customer.contactPreference);
  const [language, setLanguage] = useState<string>(customer.language);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false);
  const [crmSmsDisclaimer, setCrmSmsDisclaimer] = useState<boolean>(!!customer.crmSmsDisclaimer);

  const validateSubmit = () => {
    if (
      nameValidate(firstName) &&
      nameValidate(lastName) &&
      isValidEmail(emailInput) &&
      isValidPhone &&
      Object.keys(languageOptions).includes(language) &&
      ['EMAIL', 'PHONE', 'BOTH'].includes(contactPref) &&
      (
        firstName !== customer.firstName ||
        lastName !== customer.lastName ||
        emailInput !== customer.email ||
        contactPref !== customer.contactPreference ||
        language !== customer.language ||
        crmSmsDisclaimer !== customer.crmSmsDisclaimer ||
        phoneNumber !== customer.phoneNumber
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (validateSubmit()) {
      setIsLoading(true);
      const response: any = await crmUpdateShopCustomer(
        customer.shopID,
        customer.customerID,
        firstName,
        lastName,
        emailInput,
        phoneNumber,
        contactPref,
        language,
        crmSmsDisclaimer,
      );
      if (!response.error) {
        updateSingleCustomer(response);
        toast.success(TextOnly('customerUpdated'), { containerId: 'standard' });
      } else {
        toast.error(`${TextOnly('error')}: ${response.message ?? response.error}`, { containerId: 'standard' });
      }
      setIsLoading(false);
    }
  };


  return (
    <div className='u-width-100-percent'>
      <form className="u-margin-left u-margin-top">
        <div className='display-flex u-width-100-percent'>
          <div className={`c-field u-width-50-percent `}>
            <label className={`c-field__label`} htmlFor="firstName">
              <span className="u-text-error">*</span> {`${TextOnly('firstName')}`}:
            </label>
            <input
              type="text"
              id="firstName"
              className={`c-input${
                (firstName?.length > 0) &&
                !nameValidate(firstName)
                  ? '__error'
                  : ''
              }`}
              placeholder={`${TextOnly('firstName')}`}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            {(firstName?.length > 0) &&
              !nameValidate(firstName) && (
                <div className="c-field__error">
                  <Text tid="firstNameHint" />
                </div>
              )}
          </div>
          <div className={`c-field u-width-50-percent `}>
            <label className={`c-field__label`} htmlFor="lastName">
              <span className="u-text-error">*</span> {`${TextOnly('lastName')}`}:
            </label>
            <input
              type="text"
              id="lastName"
              className={`c-input${
                (lastName?.length > 0) && !nameValidate(lastName)
                  ? '__error'
                  : ''
              }`}
              placeholder={`${TextOnly('lastName')}`}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            {(lastName?.length > 0) &&
              !nameValidate(lastName) && (
                <div className="c-field__error">
                  <Text tid="lastNameHint" />
                </div>
              )}
          </div>
        </div>
        <div className='display-flex u-width-100-percent'>
          <div className={`c-field u-width-50-percent `}>
            <label className={`c-field__label`} htmlFor="email">
              <span className="u-text-error">*</span> {`${TextOnly('email')}`}:
            </label>
            <input
              type="text"
              id="email"
              className={`c-input${
                (emailInput?.length > 0) &&
                !isValidEmail(emailInput)
                  ? '__error'
                  : ''
              }`}
              placeholder={`${TextOnly('email')}`}
              value={emailInput}
              onChange={(event) => setEmailInput(event.target.value)}
            />
            {(emailInput?.length > 0) &&
              !isValidEmail(emailInput) && (
                <div className="c-field__error">
                  <Text tid="emailHint" />
                </div>
              )}
          </div>
        </div>
        <div className='display-flex u-width-100-percent'>
          <div className={`c-field u-width-50-percent `}>
            <div>
              <label className={`c-field__label`} htmlFor="phone">
                <span className="u-text-error">*</span>{' '}
                {`${TextOnly('phoneNumber')}`}:
              </label>
              <BasicPhoneInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setIsValidPhone={setIsValidPhone}
                country={shopCountry}
              />
            </div>
            <div>
              <div className="display-flex u-align-center">
                <input
                  id="crmSmsDisclaimer"
                  type="checkbox"
                  checked={crmSmsDisclaimer}
                  onChange={()=>setCrmSmsDisclaimer(!crmSmsDisclaimer)}
                  className="u-margin-right-large"
                />
                <label
                  htmlFor="crmSmsDisclaimer"
                  className="c-field__label left-padding u-padding-bottom-none"
                >
                  <Text tid="crmSmsDisclaimer" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='display-flex u-width-100-percent'>
          <div className={`c-field u-width-50-percent `}>
            <label className={`c-field__label`}>
              <span className="u-text-error">*</span>{' '}
              <Text tid="contactPreference" />:
            </label>
            <div
              className={`c-select background-white`}
            >
              <select
                value={contactPref}
                onChange={(event) =>
                  setContactPref(event.target.value as 'EMAIL' | 'PHONE' | 'BOTH')
                }
                disabled={isLoading}
              >
                <option value="" disabled>
                  {TextOnly('chooseContactPreference')}
                </option>
                <option value="EMAIL">{TextOnly('email')}</option>
                <option value="PHONE">{TextOnly('phoneNumber')}</option>
                <option value="BOTH">{TextOnly('both')}</option>
              </select>
            </div>
          </div>
          <div className={`c-field u-width-50-percent `}>
            <label className={`c-field__label`} htmlFor="customerLanguage">
              <span className="u-text-error">*</span>{' '}
              {`${TextOnly('language')}`}:
            </label>
            <div
              className={`c-select background-white`}
            >
              <select
                id="customerLanguage"
                onChange={(event)=>setLanguage(event.target.value)}
                value={language}
              >
                <option value="" disabled>
                  {TextOnly('selectLanguage')}
                </option>
                {langOptions}
              </select>
            </div>
            {language?.length === 0 && (
              <div className="c-field__error">
                <Text tid="chooseContactPreference" />
              </div>
            )}
          </div>
        </div>
        <div className="c-field display-flex justifycontent-center">
          <div className='flex-colum-center'>
            <LoaderButton
              text={TextOnly('saveChanges')}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
              isLoading={isLoading}
              loadingText={TextOnly('savingChanges')}
              disabled={isLoading || (!validateSubmit())}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCustomerForm;
