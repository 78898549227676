import { Sidebar } from './Sidebar';
import React from 'react';
import { Dialog } from '@reach/dialog';
import { useMenu } from '../context/Menu';
import { ShopRole, ShopType, User } from '../types';

type MobileSidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  user: User;
  mbRole: string;
  shopName: string;
  shopRole: ShopRole;
  shopType: ShopType;
  gotBanner: number;
  certifiedTech: boolean;
};

export function MobileSidebar({
  isAuthenticated,
  logout,
  user,
  mbRole,
  shopName,
  shopRole,
  shopType,
  gotBanner,
  certifiedTech,
}: MobileSidebarProps) {
  const { showMobileNav, toggleShowMobileNav } = useMenu();
  return (
    <Dialog
      isOpen={showMobileNav}
      onDismiss={toggleShowMobileNav}
      className="c-modal-menu c-modal-menu-slider"
      aria-label="mobile menu"
    >
      <Sidebar
        user={user}
        mbRole={mbRole}
        shopName={shopName}
        shopRole={shopRole}
        shopType={shopType}
        isAuthenticated={isAuthenticated}
        logout={() => {
          logout();
          toggleShowMobileNav();
        }}
        toggleShowMobileNav={toggleShowMobileNav}
        gotBanner={gotBanner}
        certifiedTech={certifiedTech}
      />
    </Dialog>
  );
}
