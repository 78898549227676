import React, { Component } from 'react';
import { Text, TextOnly } from '../components/Text';

export const statesUSA: { [key: string]: string } = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

interface StatePickerProps {
  state: string;
  stateVerify: boolean;
  disabled: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => React.ChangeEventHandler<HTMLSelectElement>;
}

export default class StatePicker extends Component<StatePickerProps> {
  render() {
    let stateOptions = Object.keys(statesUSA).map((key) => {
      return (
        <option key={key} value={key}>
          {key} : {statesUSA[key]}
        </option>
      );
    });

    let isValidState = Object.keys(statesUSA).includes(this.props.state);

    return (
      <div className={`c-field l-container-xs`}>
        <label htmlFor="state" className="c-field__label">
          <span className="u-text-error">*</span> <Text tid="state" />
        </label>
        <div
          className={
            this.props.stateVerify && !isValidState
              ? 'c-select top-padding u-input-side-by-side c-input__error'
              : 'c-select top-padding u-input-side-by-side'
          }
        >
          <select
            id="state"
            className={!this.props.stateVerify || isValidState ? '' : 'invalid'}
            onChange={this.props.handleChange}
            value={this.props.state || ''}
            disabled={this.props.disabled}
          >
            <option value="" disabled>
              {TextOnly('state')}
            </option>
            {stateOptions}
          </select>
        </div>
        {this.props.stateVerify && !isValidState ? (
          <p className="u-text-error">
            <Text tid="fieldCannotBeBlank" />
          </p>
        ) : null}
      </div>
    );
  }
}
