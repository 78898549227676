import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import LoaderButton from '../../../components/LoaderButton';
import AddEditVehicleForm from './AddEditVehicleForm';
import { Dialog } from '@reach/dialog';
import HelpTooltip from '../../../components/HelpTooltip';

const AddEditVehicle: React.FC<any> = (props) => {
  const {
    currentShop,
    vehicle,
    isIconButton,
    customer,
    updateSingleCustomer,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.add-edit-vehicle')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);

  };

  return (
    <div>
      { !!vehicle ?
        <HelpTooltip label={TextOnly('edit')}>
          <button
            className="c-btn-icon-compact"
            onClick={() => setShowModal(true)}
          >
            <div className="c-btn__inner">
              <span className="c-btn__icon fal fa-pen-to-square" />
            </div>
          </button>
        </HelpTooltip>
        : isIconButton ?
        <button
          className="c-btn-icon-compact"
          onClick={() => setShowModal(true)}
        >
          <div className="c-btn__inner">
            <span className="c-btn__icon fal fa-car" />
          </div>
        </button>
        :
        <LoaderButton
          text={TextOnly(!!vehicle ? 'editVehicle' : 'addVehicle')}
          icon={<i className="c-btn__icon fal fa-plus" />}
          onClick={() => setShowModal(true)}
          className='textwrap-nowrap'
        />
      }
      <Dialog
        isOpen={showModal}
        className="c-modal-slider add-edit-vehicle"
        id="addVehicle"
        onDismiss={handleCancelModal}
        aria-label={TextOnly(!!vehicle ? 'editVehicle' : 'addVehicle')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <div className="c-modal__heading textwrap-nowrap">
          <span className="h1">{TextOnly(!!vehicle ? 'editVehicle' : 'addVehicle')}{customer ? ` - ${customer.firstName} ${customer.lastName}` : ''}</span>
        </div>
        <div className="c-modal__body">
          <AddEditVehicleForm
            currentShop={currentShop}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            handleCloseModal={handleCancelModal}
            vehicle={vehicle}
            customer={customer}
            updateSingleCustomer={updateSingleCustomer}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AddEditVehicle;
