import React from 'react';
import KanbanCard from './KanbanCard';
import { Droppable } from './Droppable';

interface ColumnProps {
  title: string;
  value: string;
  columnNum: number;
  data: any[];
  currentShop: any;
  shopCustomers: any[];
  handleOrderChange: any;
  user: any;
}

const KanbanColumn: React.FC<ColumnProps> = (props) => {
  const {
    title,
    value,
    columnNum,
    data,
    currentShop,
    handleOrderChange,
    shopCustomers,
    user,
  } = props;

  return (
    <div className="kanban-column">
      <div className={`kanban-column--title status-border-color-${columnNum}`}>
        <div className="h2">{title}</div>
      </div>

      <Droppable id={value}>
        <div className={"c-box--kanban u-height-min-800"}>
          {data.map(order => (
            <KanbanCard
              key={order.orderID}
              data={order}
              currentShop={currentShop}
              shopCustomers={shopCustomers}
              onChange={handleOrderChange}
              user={user}
            />
          ))}
        </div>
      </Droppable>
    </div>
  );
};

export default KanbanColumn;