import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './containers/Login';
import VerifyEmail from './containers/VerifyEmail';
import ShopProfile from './containers/ShopProfile/ShopProfile';
import ResetPassword from './containers/ResetPassword';
import ResendConfirmCode from './containers/ResendConfirmCode';
import Signup from './containers/Signup';
import ReleaseTool from './containers/ReleaseTool';
import RequestInvite from './containers/RequestInvite';
import AddServiceCenter from './containers/AddServiceCenter';
import ServiceCenterSignup from './containers/ServiceCenterSignup';
import UserCreate from './containers/UserCreate';
import UserProfile from './containers/UserProfile';
import ManageUsers from './containers/ManageUsers';
import { ShopActions } from './containers/ShopActions';
import { ToolActions } from './containers/ToolActions';
import MyActivity from './containers/MyActivity';
import SystemInfo from './containers/SystemInfo';
import UserFAQ from './containers/UserFAQ';
import ManageShopTools from './containers/ManageShopTools';
import { StatsAndTrends } from './containers/StatsAndTrends';
import NotFound from './containers/NotFound';
import OEMSignup from './containers/OEMSignup';
import ShopReportsPromo from './containers/ShopReportsPromo';
import ToolReportsPromo from './containers/ToolReportsPromo';
import StatsAndTrendsPromo from './containers/StatsAndTrendsPromo';
import UpgradeToStandardPlus from './containers/UpgradeToStandardPlus';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import NewReview from './containers/NewReview';
import { SHOP_ROLES, SHOP_TYPES } from './CONSTANTS';
import MatrixBoard from './containers/MessageBoard/MatrixBoard';
import OwnerVerifications from './containers/OwnerVerifications';
import DetailedOwnerVerification from './containers/DetailedOwnerVerification';
import VehicleOwnerVerificationPromo from './containers/VehicleOwnerVerificationPromo';
import VehicleOwnerVerificationVerifyIdentity from './containers/VehicleOwnerVerificationVerifyIdentity';
import OemToolRegionCompatibility from './containers/OemToolRegionCompatibility';
import ManageCustomers from './containers/Crm/ManageCustomers';
import EventManagement from './containers/Crm/Calendar/EventManagement';
import ManageOrders from './containers/Crm/ManageOrders';
import CustomerDetail from './containers/Crm/ManageCustomers/CustomerDetail';

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Login} props={childProps} />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/verifyUserEmail"
      exact
      component={VerifyEmail}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/resetPassword"
      exact
      component={ResetPassword}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/resendConfirmCode"
      exact
      component={ResendConfirmCode}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/OEMSignup"
      exact
      component={OEMSignup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/requestInvite"
      exact
      component={RequestInvite}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/signup"
      exact
      component={Signup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/releaseTool/:toolReleaseCode"
      exact
      component={ReleaseTool}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/shopSignup"
      exact
      component={ServiceCenterSignup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/serviceCenterSignup"
      exact
      component={UpgradeToStandardPlus}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/SystemInfo"
      exact
      component={SystemInfo}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/toolCompatibility"
      exact
      component={OemToolRegionCompatibility}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/userCreate"
      exact
      component={UserCreate}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/addServiceCenter"
      exact
      component={AddServiceCenter}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/userProfile"
      exact
      component={UserProfile}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/manageTools/manageShopTools"
      exact
      component={ManageShopTools}
      props={childProps}
      permissions={{ roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER] }}
    />
    <AuthenticatedRoute
      path="/manageUsers/currentUsers"
      exact
      component={ManageUsers}
      props={childProps}
      permissions={{ roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER] }}
    />
    <AuthenticatedRoute
      path="/reports/statsAndTrends"
      exact
      component={StatsAndTrends}
      props={childProps}
      permissions={{
        roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER],
        shopTypes: [SHOP_TYPES.ENTERPRISE, SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/reports/shopActions"
      exact
      component={ShopActions}
      props={childProps}
      permissions={{
        roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER],
        shopTypes: [SHOP_TYPES.ENTERPRISE, SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/reports/toolActions"
      exact
      component={ToolActions}
      props={childProps}
      permissions={{
        roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER],
        shopTypes: [SHOP_TYPES.ENTERPRISE, SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/myActivity"
      exact
      component={MyActivity}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/SystemInfo"
      exact
      component={SystemInfo}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/UserFAQ"
      exact
      component={UserFAQ}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/shopProfile"
      exact
      component={ShopProfile}
      props={childProps}
      permissions={{ roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER] }}
    />
    <AuthenticatedRoute
      path="/upgradeToPlus/shopReportsPromo"
      exact
      component={ShopReportsPromo}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/upgradeToPlus/toolReportsPromo"
      exact
      component={ToolReportsPromo}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/upgradeToPlus/statsAndTrendsPromo"
      exact
      component={StatsAndTrendsPromo}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/upgradeToPlus/comparePlans"
      exact
      component={UpgradeToStandardPlus}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/upgradeToPlus/upgradeExplanation"
      exact
      component={UpgradeToStandardPlus}
      props={{...childProps, auto: true}}
    />
    <AuthenticatedRoute
      path="/messageBoard"
      component={MatrixBoard}
      props={childProps}
      permissions={{ roles: [SHOP_ROLES.ADMIN, SHOP_ROLES.OWNER, SHOP_ROLES.TECH] }}
    />
    <AuthenticatedRoute
      path="/vehicleOwnerVerification"
      exact
      component={OwnerVerifications}
      props={childProps}
      permissions={{
        shopTypes: [SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/crm/manageCustomers"
      exact
      component={ManageCustomers}
      props={childProps}
      permissions={{
        shopTypes: [SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/crm/manageOrders"
      exact
      component={ManageOrders}
      props={childProps}
      permissions={{
        shopTypes: [SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/crm/calendar"
      exact
      component={EventManagement}
      props={childProps}
      permissions={{
        shopTypes: [SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/crm/customerDetail/:customerID?"
      exact
      component={CustomerDetail}
      props={childProps}
      permissions={{
        shopTypes: [SHOP_TYPES.PLUS],
      }}
    />
    <AuthenticatedRoute
      path="/vehicleOwnerVerificationPromo"
      exact
      component={VehicleOwnerVerificationPromo}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/vehicleOwnerVerificationVerifyIdentity"
      exact
      component={VehicleOwnerVerificationVerifyIdentity}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/detailedOwnerVerification/:verificationID?"
      exact
      component={DetailedOwnerVerification}
      props={childProps}
      permissions={{
        shopTypes: [SHOP_TYPES.PLUS],
      }}
    />
    {/* <AuthenticatedRoute
      path="/new"
      exact
      component={NewReview}
      props={childProps}
    />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
