import React, { useState } from "react";
import { Dialog } from "@reach/dialog";
import { TextOnly } from "./Text";

const ConfirmModal = (
  {
    showConfirmModal,
    closeModal,
    onConfirm,
    textModal,
    className,
    titleModal,
    textConfirmButton,
    textCancelButton,
    textLoadingConfirmButton,
  } : {
    showConfirmModal: boolean;
    closeModal: Function;
    onConfirm: Function;
    textModal: string;
    className?: string;
    titleModal?: string;
    textConfirmButton?: string;
    textCancelButton?: string;
    textLoadingConfirmButton?: string;
  }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleCloseModal = () => {
      if(isLoading) return;
      else closeModal();
    }
    const handleOnConfirm = async () => {
      setIsLoading(true);
      const response = await onConfirm();
      setIsLoading(false);
      if(!response?.error) closeModal();
    }
    return (
      <Dialog
        isOpen={showConfirmModal}
        onDismiss={()=>handleCloseModal()}
        className={className ?? "c-modal"}
        aria-label="ConfirmModal"
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={()=>handleCloseModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className='margin-2rem'>{titleModal ?? TextOnly('confirm')}</h1>
        <h2 className="c-modal__heading">
          <>
            {textModal}
          </>
        </h2>
        <div className='display-flex-justify-content-center alignitems-baseline margin-16px'>
          <button
            className="c-btn"
            disabled={isLoading}
            onClick={() => handleOnConfirm()}
          >
            {
              isLoading ?
                textLoadingConfirmButton ?? TextOnly('loading')
              :
              textConfirmButton ?? TextOnly('confirm')
            }
          </button>
          {'\u00A0'}
          <button
            className="c-btn-outline"
            disabled={isLoading}
            onClick={() => {
              handleCloseModal();
            }}
          >
            {textCancelButton ?? TextOnly('cancel')}
          </button>
        </div>
      </Dialog>
    )
  }


export default ConfirmModal;