import React, { useState } from 'react';
import { TextOnly } from '../../../components/Text';
import ReactTable from 'react-table';
import { Loading } from '../../../components/Loading';
import { formatDateTime2Lines, convertOrderNumToDisplay } from '../../../libs/utils';
import HelpTooltip from '../../../components/HelpTooltip';
import AddEditOrderForm from './AddEditOrderForm';
import { Dialog } from '@reach/dialog';
import { OrdersSubComponent } from '../../../components/subComponents';
import { ORDER_STATUS_OPTIONS } from '../CrmConstants';

const OrderTable: React.FC<any> = (props) => {
  const {
    handleOrderChange,
    orders,
    currentShop,
    shopCustomers,
    user,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingOrder, setEditingOrder] =useState<any>();
  const [filterString, setFilterString] = useState<string>('');

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider.edit-order-dialog')?.classList.add('closed');
    setTimeout(() => {
      setShowModal(false);
    }, 350);
  };

  const orderCol = [
    {
      Header: TextOnly('orderNumber'),
      accessor: 'orderNumber',
      id: 'orderNumber',
      Cell: (row: any) => {
        if (row.original.orderNumber) {
          const displayOrderNum = convertOrderNumToDisplay(row.original.orderNumber);
          return (
            <div>
              {displayOrderNum}
            </div>
          );
        }
      },
    },
    {
      Header: TextOnly('status'),
      accessor: 'displayStatus',
      id: 'status',
      Cell: (row: any) => {
        const colorIndex: number = ORDER_STATUS_OPTIONS.findIndex((status: any) => status.value === row.original.status);
        return (
          <div>
            <i className={`fa fa-circle status-color-${colorIndex} u-padding-right`} aria-hidden="true"></i>
            {row.original.displayStatus}
          </div>
        )
      }
    },
    {
      Header: TextOnly('title'),
      accessor: 'title',
      id: 'title',
    },
    {
      Header: TextOnly('vehicle'),
      accessor: 'makeModelYear',
      id: 'makeModelYear',
    },
    {
      Header: TextOnly('customer'),
      accessor: 'customerName',
      id: 'customerName',
    },
    {
      Header: TextOnly('technician'),
      accessor: 'tech',
      id: 'tech',
      Cell: (row: any) => {
        if (row.original.tech) {
          return `${row.original.tech.firstName} ${row.original.tech.lastName}`
        }
      }
    },
    {
      Header: TextOnly('createdOn'),
      accessor: 'createdOn',
      id: 'createdOn',
      sortMethod: (a: any, b: any, desc: boolean) => {
        const externalAStr = new Date(a);
        const externalBStr = new Date(b);
        if (desc) {
          if (externalAStr > externalBStr) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (externalAStr < externalBStr) {
            return -1;
          } else {
            return 1;
          }
        }
      },
      Cell: (row: any) => {
        const dateObj = formatDateTime2Lines(row.original.createdOn);
        return (
          <div>
            {dateObj.date}
            <br />
            {dateObj.time}
          </div>
        );
      },
    },
    {
      Header: TextOnly('actions'),
      accessor: 'actions',
      id: 'actions',
      Cell: (row: any) => {
        return (
          <div>
            <HelpTooltip label={TextOnly('editInformation')}>
              <button
                className="c-btn-icon-compact"
                onClick={() => {
                  setEditingOrder(row.original);
                  setShowModal(true);
                }}
              >
                <div className="c-btn__inner">
                  <span className="c-btn__icon fal fa-pen-to-square" />
                </div>
              </button>
            </HelpTooltip>
          </div>
        );
      },
    },
  ];

  const containsExpandFields = (row: any) => {
    const expandFields = [
      'description',
    ];
    for (const field in row) {
      if (expandFields.includes(field) && row[field]) return true;
    }
    return false;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <div className="c-field no-spacing u-width-100-percent u-margin-top-large">
        <input
          id="filterActions"
          type="text"
          maxLength={50}
          className="c-input"
          placeholder={TextOnly('searchOrder') + '...'}
          onChange={(event) => setFilterString(event.target.value)}
          value={filterString}
        />
        <i className="c-field__input-icon fal fa-search" />
      </div>
      <ReactTable
        columns={[
          {
            expander: true,
            Header: '',
            accessor: 'expander',
            Expander: ({ isExpanded, ...row }) => {
              if (containsExpandFields(row.original)) {
                return (
                  <div>
                    <i
                      className={`fa fa-chevron-right ${
                        isExpanded ? 'accordion__icon rotate' : 'accordion__icon'
                      }`}
                      aria-hidden="true"
                    ></i>
                  </div>
                );
              } else {
                return (
                  <div className="u-cursor-none"></div>
                )
              }
            },
          },
          ...orderCol]}
        data={orders.filter((order: any) => {
          return order?.filterValue?.includes(filterString.toLocaleLowerCase());
        })}
        className="-highlight u-margin-top tablelayout"
        previousText={TextOnly('previousPage')}
        nextText={TextOnly('nextPage')}
        pageText={TextOnly('page')}
        ofText={TextOnly('of')}
        rowsText={TextOnly('rows')}
        noDataText={TextOnly('noOrders')}
        defaultPageSize={10}
        defaultSorted={[
          {
            id: 'orderNumber',
            desc: false,
          },
        ]}
        getTheadThProps={() => {
          return {
            style: {
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'left',
              width: '100%',
            },
          };
        }}
        SubComponent={row => {
          if (containsExpandFields(row.original)) {
            return (
              <OrdersSubComponent
                row={row}
                shop={currentShop}
                currentUser={user}
                className="accordion__content"
              />
            );
          } else {
            return null;
          }
        }}
      />
      <Dialog
        isOpen={showModal}
        onDismiss={handleCancelModal}
        className="c-modal-slider edit-order-dialog"
        id="addEditOrder"
        aria-label={'addEditOrder'}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <AddEditOrderForm
          currentShop={currentShop}
          shopCustomers={shopCustomers}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          handleCloseModal={handleCancelModal}
          orderData={editingOrder}
          onSubmit={handleOrderChange}
          user={user}
        />
      </Dialog>
    </div>
  );
};

export default OrderTable;
