import React, { useState } from 'react';
import EditCustomerForm from './EditCustomerForm';
import VehicleTable from '../ManageVehicles/VehicleTable';


const CustomerDetail: React.FC<any> = (props) => {

  const {
    currentShop,
    customer,
    updateSingleCustomer,
  } = props;

  const [isLoadingEditCustomer, setIsLoadingEditCustomer] = useState<boolean>(false);

  return (
    <div className='display-flex l-flex-wrap u-margin-bottom-large'>
      <div className='u-width-100-percent u-margin-none overflow-visible u-margin-bottom-large'>
        <EditCustomerForm
          customer={customer}
          updateSingleCustomer={updateSingleCustomer}
          setIsLoading={setIsLoadingEditCustomer}
          isLoading={isLoadingEditCustomer}
        />
      </div>
      <div className='u-width-100-percent display-flex-justify-content-center'>
        <VehicleTable
          allVehicles={customer.vehicles}
          customer={customer}
          currentShop={currentShop}
          updateSingleCustomer={updateSingleCustomer}
        />
      </div>
    </div>
  );
};

export default CustomerDetail;
