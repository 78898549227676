import React, { useState, useEffect } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import { isValidEmail, nameValidate } from '../../../libs/utils';
import BasicPhoneInput from '../../../components/BasicPhoneInput';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import { crmCreateShopCustomer } from '../../../libs/db-lib';
import { languageOptions } from '../../../libs/languages';
import { useLanguage } from '../../../libs/Language';


const AddCustomerForm: React.FC<any> = (props) => {
  const {
    currentShop,
    setIsLoading,
    isLoading,
    getAllCustomers,
    handleCloseModal,
    setNewCustomerInfo,
  } = props;

  const { userLanguage } = useLanguage();

  const langOptions = Object.keys(languageOptions).map((key) => {
    return (
      <option key={key} value={key}>
        {languageOptions[key as keyof typeof languageOptions]}
      </option>
    );
  });

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emailInput, setEmailInput] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [contactPref, setContactPref] = useState<
  'EMAIL' | 'PHONE' | 'BOTH' | ''
  >('');
  const [language, setLanguage] = useState<string>(userLanguage ?? 'en');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = useState<boolean>(false);
  const [crmSmsDisclaimer, setCrmSmsDisclaimer] = useState<boolean>(false);

  useEffect(() => {
    if (setNewCustomerInfo) {
      setNewCustomerInfo({
        firstName,
        lastName,
        email: emailInput,
        phoneNumber,
        contactPreference: contactPref,
        language,
        'isValidated': validateSubmit(),
      })
    }
  }, [
    firstName,
    lastName,
    emailInput,
    phoneNumber,
    contactPref,
    language,
    isValidPhone
  ]);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmailInput('');
    setPhoneNumber('');
    setContactPref('');
    setSubmitClicked(false);
  };

  const validateSubmit = () => {
    if (
      nameValidate(firstName) &&
      nameValidate(lastName) &&
      isValidEmail(emailInput) &&
      isValidPhone &&
      Object.keys(languageOptions).includes(language) &&
      ['EMAIL', 'PHONE', 'BOTH'].includes(contactPref)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    setSubmitClicked(true);
    if (validateSubmit()) {
      setIsLoading(true);
      const response: any = await crmCreateShopCustomer(
        currentShop.shopID,
        firstName,
        lastName,
        emailInput,
        phoneNumber,
        contactPref,
        language,
        crmSmsDisclaimer,
      );
      setIsLoading(false);
      if (!response.error) {
        clearForm();
        toast.success(TextOnly('newCustomerCreated'), { containerId: 'standard' });
        handleCloseModal();
        setTimeout(() => {
          getAllCustomers();
        }, 360);
      } else {
        toast.error(
          `${TextOnly('error')}: ${response.error}`,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
  };

  return (
    <div className='l-container-sm'>
      {!setNewCustomerInfo && (
        <div className="h2">{TextOnly('customerInformation')}</div>
      )}
      <form className={`u-margin-top ${!setNewCustomerInfo && 'u-margin-left'}`}>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor="firstName">
            <span className="u-text-error">*</span> {`${TextOnly('firstName')}`}:
          </label>
          <input
            type="text"
            id="firstName"
            className={`c-input${
              (firstName.length > 0 || submitClicked) &&
              !nameValidate(firstName)
                ? '__error'
                : ''
            }`}
            placeholder={`${TextOnly('firstName')}`}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          {(firstName.length > 0 || submitClicked) &&
            !nameValidate(firstName) && (
              <div className="c-field__error">
                <Text tid="firstNameHint" />
              </div>
            )}
        </div>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor="lastName">
            <span className="u-text-error">*</span> {`${TextOnly('lastName')}`}:
          </label>
          <input
            type="text"
            id="lastName"
            className={`c-input${
              (lastName.length > 0 || submitClicked) && !nameValidate(lastName)
                ? '__error'
                : ''
            }`}
            placeholder={`${TextOnly('lastName')}`}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
          {(lastName.length > 0 || submitClicked) &&
            !nameValidate(lastName) && (
              <div className="c-field__error">
                <Text tid="lastNameHint" />
              </div>
            )}
        </div>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor="email">
            <span className="u-text-error">*</span> {`${TextOnly('email')}`}:
          </label>
          <input
            type="text"
            id="email"
            className={`c-input${
              (emailInput.length > 0 || submitClicked) &&
              !isValidEmail(emailInput)
                ? '__error'
                : ''
            }`}
            placeholder={`${TextOnly('email')}`}
            value={emailInput}
            onChange={(event) => setEmailInput(event.target.value)}
          />
          {(emailInput.length > 0 || submitClicked) &&
            !isValidEmail(emailInput) && (
              <div className="c-field__error">
                <Text tid="emailHint" />
              </div>
            )}
        </div>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor="phone">
            <span className="u-text-error">*</span>{' '}
            {`${TextOnly('phoneNumber')}`}:
          </label>
          <BasicPhoneInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            submitClicked={submitClicked}
            setIsValidPhone={setIsValidPhone}
            country={currentShop.country}
          />
        </div>
        <div className="c-field">
          <div className="display-flex u-align-center u-margin-top-closer-c-field">
            <input
              id="crmSmsDisclaimer"
              type="checkbox"
              checked={crmSmsDisclaimer}
              onChange={()=>setCrmSmsDisclaimer(!crmSmsDisclaimer)}
              className="u-margin-right-large"
            />
            <label
              htmlFor="crmSmsDisclaimer"
              className="c-field__label left-padding u-padding-bottom-none"
            >
              <Text tid="crmSmsDisclaimer" />
            </label>
          </div>
        </div>
        <div className="c-field">
          <label className={`c-field__label`}>
            <span className="u-text-error">*</span>{' '}
            <Text tid="contactPreference" />:
          </label>
          <div
            className={`c-select ${
              contactPref.length === 0 && submitClicked ? 'error' : ''
            } background-white`}
          >
            <select
              value={contactPref}
              onChange={(event) =>
                setContactPref(event.target.value as 'EMAIL' | 'PHONE' | 'BOTH')
              }
              disabled={isLoading}
              defaultValue={"PHONE"}
            >
              <option value="" disabled>
                {TextOnly('chooseContactPreference')}
              </option>
              <option value="EMAIL">{TextOnly('email')}</option>
              <option value="PHONE">{TextOnly('phoneNumber')}</option>
              <option value="BOTH">{TextOnly('both')}</option>
            </select>
          </div>
          {contactPref.length === 0 && submitClicked && (
            <div className="c-field__error">
              <Text tid="chooseContactPreference" />
            </div>
          )}
        </div>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor="customerLanguage">
            <span className="u-text-error">*</span>{' '}
            {`${TextOnly('language')}`}:
          </label>
          <div
            className={`c-select ${
              language.length === 0 && submitClicked ? 'error' : ''
            } background-white`}
          >
            <select
              id="customerLanguage"
              onChange={(event)=>setLanguage(event.target.value)}
              value={language}
            >
              <option value="" disabled>
                {TextOnly('selectLanguage')}
              </option>
              {langOptions}
            </select>
          </div>
          {language.length === 0 && submitClicked && (
            <div className="c-field__error">
              <Text tid="chooseContactPreference" />
            </div>
          )}
        </div>
        {!setNewCustomerInfo && (
          <div className={`c-field display-flex justifycontent-center`}>
            <LoaderButton
              text={TextOnly('cancel')}
              onClick={(event) => {
                event.preventDefault();
                handleCloseModal();
              }}
              disabled={isLoading}
            />&nbsp;
            <LoaderButton
              text={TextOnly('save')}
              onClick={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
              isLoading={isLoading}
              disabled={isLoading || (submitClicked && !validateSubmit())}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default AddCustomerForm;
