import React, { Component } from 'react';
import { Text, TextOnly } from '../../components/Text';
import LoaderButton from '../../components/LoaderButton';
import { SignupLinkLed } from '../../components/SignupLinkLed';
import { formatDateTime } from '../../libs/utils';
import {
  getShopSignupLinks,
  updateSignupLinkForShop,
  getSystemConfig,
} from '../../libs/db-lib';
import { Loading } from '../../components/Loading';
import { Dialog } from '@reach/dialog';
import { toast } from 'react-toastify';
import { SHOP_TYPES } from '../../CONSTANTS';

import AddSignupLink from './AddSignupLink';
import EditSignupLink from './EditSignupLink';

export default class SignupLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      user: props.user,
      currentShop: props.currentShop,
      rowsText: TextOnly('rows'),
      shopLinks: null,
      linkToDelete: {},
      interfaceToDelete: {},
      showAddLinkModal: false,
      showConfirmLinkModal: false,
      showEditLinkModal: false,
      showAddDirectoryModal: false,
      showEditDirectoryModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      this.setState({ rowsText: TextOnly('rows') });
    }
  }

  async componentDidMount() {
    try {
      let config = await getSystemConfig();

      this.setState({ baseURL: config.baseUrl });

      if (this.state?.currentShop?.shopType !== SHOP_TYPES.ENTERPRISE) {
        this.props.history.push('/myActivity');
        toast.error(TextOnly('upgradeToEnterpriseToView'), {
          containerId: 'standard',
        });
      }

      const shop = this.props.currentShop;
      const shopUsers = [
        ...shop.shopUsers,
        {
          firstName: this.state.user.firstName,
          lastName: this.state.user.lastName,
          userID: this.state.user.userID,
          userName: this.state.user.userName,
        },
      ];
      let shopLinks = [];
      if (this.state?.currentShop?.shopType === 'ENTERPRISE') {
        let results = await getShopSignupLinks(shop.shopID);
        shopLinks = this.modifyResults(results.linkList, shopUsers);
      }
      this.setState({
        shopID: shop.shopID,
        shopLinks: shopLinks,
      });
    } catch (e) {
      this.props.handleShowAlert(TextOnly('error'), e.message, true);
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  modifyResults = (shopLinks, shopUsers) => {
    return shopLinks.map((shopLink) => {
      return {
        ...shopLink,
        name: `${
          shopUsers.filter((user) => user.userID === shopLink.addedBy)[0]
            .lastName
        } ${
          shopUsers.filter((user) => user.userID === shopLink.addedBy)[0]
            .firstName
        }`,
      };
    });
  };

  handleCopyAndToast = (linkID) => {
    navigator.clipboard.writeText(
      (this.state.baseURL
        ? this.state.baseURL
        : 'https://webapp.autoauth.com') +
        '/signup?linkID=' +
        linkID
    );
    toast.success(TextOnly('copiedLink'), { containerId: 'standard' });
  };

  handleAddSignupLink = (linkRec) => {
    let shopLinks = this.state.shopLinks;
    linkRec.name = `${this.state.user.lastName} ${this.state.user.firstName}`;
    shopLinks.push(linkRec);
    this.setState({
      shopLinks: shopLinks,
    });
    toast.success(TextOnly('signupLinkAdded'), { containerId: 'standard' });
  };

  handleEditSignupLink = (linkRec) => {
    let shopLinks = this.state.shopLinks.map((link) =>
      link.linkID === linkRec.linkID
        ? {
            ...link,
            friendlyName: linkRec.friendlyName,
            linkType: linkRec.linkType,
            startDate: linkRec.startDate,
            endDate: linkRec.endDate,
            additionalIDFields: linkRec.additionalFields,
          }
        : link
    );

    this.setState({
      shopLinks: shopLinks,
    });
  };

  handleShowAddLinkModal = () => {
    this.setState({
      showAddLinkModal: true,
    });
  };

  handleHideAddLinkModal = () => {
    this.setState({
      showAddLinkModal: false,
    });
  };

  handleShowEditLinkModal = (linkRec) => {
    this.setState({
      linkRec: linkRec,
      showEditLinkModal: true,
    });
  };

  handleHideEditLinkModal = () => {
    const self = this;
    document.querySelector('#editSignupLink').classList.add('closed');
    setTimeout(() => {
      self.setState({
        showEditLinkModal: false,
        linkRec: null,
      });
    }, 350);
  };

  handleRemoveLinkButton = (linkRec) => {
    this.setState({ linkToDelete: linkRec, showConfirmLinkModal: true });
  };

  handleCancelLinkModal(e) {
    if (e) {
      e.preventDefault();
    }
    const self = this;
    document
      .querySelector('#confirmRemoveSignupLinkFromShop')
      .classList.add('closed');

    setTimeout(() => {
      self.setState({ showConfirmLinkModal: false, linkToDelete: '' });
    }, 350);
  }

  async handleConfirmLinkModal() {
    // Handle delete of signup link
    let linkRec = this.state.linkToDelete;
    const result = await updateSignupLinkForShop(
      this.state.currentShop.shopID,
      linkRec.linkID,
      linkRec.friendlyName,
      linkRec.linkType,
      'DELETED',
      linkRec.additionalIDFields,
      linkRec.startDate,
      linkRec.endDate
    );

    if (!result?.error) {
      const self = this;
      document
        .querySelector('#confirmRemoveSignupLinkFromShop')
        .classList.add('closed');

      setTimeout(() => {
        self.setState({ showConfirmLinkModal: false, linkToDelete: '' });
      }, 350);
      // Update the list of links
      let linkList = this.state.shopLinks;
      let newLinkList = linkList.filter((l) => l.linkID !== linkRec.linkID);
      this.setState({ shopLinks: newLinkList });
    } else {
      this.setState({
        alertMessage: result.error,
        showConfirmLinkModal: false,
        showModal: true,
      });
    }
  }

  handleShowAlert = (alertTitle, alertMessage, showModal) => {
    this.props.handleShowAlert(alertTitle, alertMessage, showModal);
  };

  render() {
    const { onDismiss } = this.props;

    const signupLink = (link) => {
      let validityCriteria;

      if (link.linkType === SHOP_TYPES.STANDARD) {
        validityCriteria = <Text tid={link.linkState} />;
      } else {
        validityCriteria =
          formatDateTime(link.startDate, { utc: true }) +
          ' - ' +
          formatDateTime(link.endDate, { utc: true });
      }
      return (
        <div className="c-signup-link u-margin-top">
          <h2 className="c-signup-link__title">{link.friendlyName}</h2>
          <div className="c-signup-link__fields">
            <dl>
              <dt>
                <Text tid="link" />
              </dt>
              <dd>
                {
                  <button
                    className="c-btn-link c-btn-wrap u-padding-none u-text-transform-initial"
                    onClick={() => {
                      this.handleCopyAndToast(link.linkID);
                    }}
                  >
                    {(this.state.baseURL
                      ? this.state.baseURL
                      : 'https://webapp.autoauth.com') +
                      '/signup?linkID=' +
                      link.linkID}
                  </button>
                }
              </dd>
            </dl>
            <dl>
              <dt>
                <Text tid="validity" />
              </dt>
              <dd>
                {link.linkType === SHOP_TYPES.STANDARD ? 'ACTIVE' : 'Time'}
              </dd>
              {link.linkType !== SHOP_TYPES.STANDARD && (
                <>
                  <dt />
                  <dd>{validityCriteria}</dd>
                </>
              )}
            </dl>
            <dl>
              {link.additionalIDFields.length > 0 &&
                link.additionalIDFields.map((field, index) => {
                  return (
                    <>
                      {index === 0 ? (
                        <dt>
                          <Text tid="additionalFields" />
                        </dt>
                      ) : (
                        <dt />
                      )}
                      <dd>{field}</dd>
                    </>
                  );
                })}
            </dl>
            <dl>
              <dt>
                <Text tid="createdBy" />
              </dt>
              <dd>{link.name}</dd>
            </dl>
            <dl>
              <dt>
                <Text tid="createdOn" />
              </dt>
              <dd>{formatDateTime(link.addedOn, null, true)}</dd>
            </dl>
          </div>
          <div className="c-signup-link__footer">
            <div>
              <SignupLinkLed
                linkState={link.linkState}
                linkType={link.linkType}
                startDate={link.startDate}
                endDate={link.endDate}
              />
            </div>
            <div className="c-signup-link__footer-right">
              <button
                className="c-btn u-margin-right"
                onClick={() => {
                  this.handleCopyAndToast(link.linkID);
                }}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-link" />
                  <Text tid="copyLink" />
                </div>
              </button>{' '}
              <button className="c-btn-icon-alt u-margin-right">
                <div
                  className="c-btn__inner"
                  onClick={() => this.handleShowEditLinkModal(link)}
                >
                  <i className="c-btn__icon fal fa-edit" />
                </div>
              </button>
              <button
                className="c-btn-icon-alt"
                onClick={() => this.handleRemoveLinkButton(link)}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-trash-alt" />
                </div>
              </button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <Dialog
        onDismiss={onDismiss}
        className="c-modal-slider"
        aria-label={TextOnly('signupLinks')}
      >
        {!this.state.user || this.state.shopLinks === null ? (
          <Loading />
        ) : (
          <>
            <div className="l-container u-padding-bottom-large">
              <button
                className="c-btn-icon c-modal-slider__close"
                onClick={onDismiss}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-times" />
                </div>
              </button>
              <h1>
                <Text tid="signupLinks" />
              </h1>

              {this.state.shopLinks.map((linkRec) => signupLink(linkRec))}

              {this.state.showAddLinkModal ? (
                <AddSignupLink
                  user={this.state.user}
                  handleShowAlert={this.handleShowAlert}
                  handleAddSignupLink={this.handleAddSignupLink}
                  onDismiss={this.handleHideAddLinkModal}
                />
              ) : (
                <LoaderButton
                  type="button"
                  className="u-margin-top u-align-center"
                  isLoading={this.state.isLoading}
                  onClick={this.handleShowAddLinkModal}
                  text={TextOnly('addSignupLink')}
                />
              )}
            </div>
          </>
        )}

        {this.state.showEditLinkModal && (
          <EditSignupLink
            user={this.state.user}
            onDismiss={this.handleHideEditLinkModal}
            link={this.state.linkRec}
            handleEditSignupLink={this.handleEditSignupLink}
          />
        )}

        <Dialog
          isOpen={this.state.showConfirmLinkModal}
          onDismiss={this.handleCancelLinkModal.bind(this)}
          className="c-modal-slider"
          id="confirmRemoveSignupLinkFromShop"
          aria-label={TextOnly('confirmRemoveSignupLinkFromShop')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelLinkModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmRemoveUserSignupLinkFromShop" />
          </h1>

          <div className="c-modal__body">
            <p>
              <b>{this.state.linkToDelete.friendlyName}</b>
            </p>
            <button
              className="c-btn u-margin-right"
              bsStyle="primary"
              onClick={this.handleConfirmLinkModal.bind(
                this,
                this.state.linkToDelete
              )}
            >
              <Text tid="confirm" />
            </button>
            <button
              className="c-btn-outline"
              onClick={this.handleCancelLinkModal.bind(this)}
            >
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>
      </Dialog>
    );
  }
}
