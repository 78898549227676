import React from 'react';

type EmptyCardType = {
  title: string;
  description: string;
  buttons: {
    name: string;
    onClick: () => void;
    disabled: boolean;
  }[];
};

export function EmptyCard({ title, description, buttons }: EmptyCardType) {
  return (
    <div className="c-notice u-margin-top-none u-margin-bottom-none">
      <div className="c-notice__content-alt">
        <h2 className="c-notice__title">{title}</h2>
        <p className="c-notice__text-alt">{description}</p>
      </div>
      <div className="c-notice__buttons">
        {buttons.map((button, index) => (
          <button
            className={`c-btn ${index > 0 ? 'u-margin-right-large' : ''}`}
            onClick={button.onClick}
            disabled={button.disabled}
            key={index}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-plus" />
              {button.name}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
