import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TextOnly } from '../../components/Text';
import EventCard from './EventCard';
import { useUser } from '../../context/User';
import {
  crmGetShopEvents,
} from '../../libs/db-lib';

const EventsList: React.FC<any> = (props) => {
  const { shopAppointments } = props;

  const userContext = useUser();
  const userLanguage = userContext?.user?.language;
  const currentShop = userContext?.currentShop;
  const timeframeOptions = [
    {
      value: 'TODAY',
      label: TextOnly('today'),
    },
    {
      value: 'TOMORROW',
      label: TextOnly('tomorrow'),
    },
    {
      value: 'THISWEEK',
      label: TextOnly('thisWeek'),
    },
    {
      value: 'NEXTWEEK',
      label: TextOnly('nextWeek'),
    }];
  const [groupedEvents, setGroupedEvents] = useState<any>({});
  const [timeframe, setTimeframe] = useState<string>('THISWEEK');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currentFullDate = new Date();
  const todaysDate = currentFullDate.getDate();
  const todaysMonth = currentFullDate.getMonth();
  const todaysYear = currentFullDate.getFullYear();
  const todaysKey = `${todaysYear}-${todaysMonth}-${todaysDate}`;

  const startOfCurrentWeek = new Date(
    currentFullDate.getFullYear(),
    currentFullDate.getMonth(),
    currentFullDate.getDate() - currentFullDate.getDay(), // Start of the week (Sunday)
  );
  const endOfCurrentWeek = new Date(
    currentFullDate.getFullYear(),
    currentFullDate.getMonth(),
    currentFullDate.getDate() - currentFullDate.getDay() + 6, // End of the week (Saturday)
  );
  /*
    .getDay() returns the day of the week, Sunday is 0, Saturday 6.
    .getDate() returns the date of month, 1-31.
    Today is Tuesday (day = 2) the 21st... (05/21/2024)
    So 21 - 2 = 19, this week started on the 19th (Sunday) and ends on the 25th (19+6, Saturday).
  */
  //Week Key as '2024-05-19 - 2024-05-25'
  const currentWeekKey = `${startOfCurrentWeek.toISOString().split('T')[0]} - ${endOfCurrentWeek.toISOString().split('T')[0]}`;

  const tomorrowFullDate = new Date(currentFullDate);
  tomorrowFullDate.setDate(todaysDate + 1);
  const tomorrowsDate = tomorrowFullDate.getDate();
  const tomorrowsMonth = tomorrowFullDate.getMonth();
  const tomorrowsYear = tomorrowFullDate.getFullYear();
  const tomorrowsKey = `${tomorrowsYear}-${tomorrowsMonth}-${tomorrowsDate}`;

  const startOfNextWeek = new Date(
    tomorrowFullDate.getFullYear(),
    tomorrowFullDate.getMonth(),
    tomorrowFullDate.getDate() - tomorrowFullDate.getDay() + 7, // Start of the week (Sunday)
  );
  const endOfNextWeek = new Date(
    tomorrowFullDate.getFullYear(),
    tomorrowFullDate.getMonth(),
    tomorrowFullDate.getDate() - tomorrowFullDate.getDay() + 13, // End of the week (Saturday)
  );
  //Week Key as '2024-05-19 - 2024-05-25'
  const nextWeekKey = `${startOfNextWeek.toISOString().split('T')[0]} - ${endOfNextWeek.toISOString().split('T')[0]}`;

  const groupEventsByDate = (events: any[]) => {
    const newGroupEvents = events.reduce((acc: any, event: any) => {
      const eventDateObj = new Date(event.startDate);
      const eventsDate = eventDateObj.getDate();
      const eventsMonth = eventDateObj.getMonth();
      const eventsYear = eventDateObj.getFullYear();

      if (timeframe === 'TODAY' &&
        todaysDate === eventsDate &&
        todaysMonth === eventsMonth &&
        todaysYear === eventsYear
      ) {
        if (!acc[todaysKey]) acc[todaysKey] = [];
        acc[todaysKey].push(event);

      } else if (timeframe === 'TOMORROW' &&
        tomorrowsDate === eventsDate &&
        tomorrowsMonth === eventsMonth &&
        tomorrowsYear === eventsYear
      ) {
        if (!acc[tomorrowsKey]) acc[tomorrowsKey] = [];
        acc[tomorrowsKey].push(event);

      } else if (timeframe === 'THISWEEK' &&
        eventDateObj >= startOfCurrentWeek &&
        eventDateObj <= endOfCurrentWeek &&
        eventsDate >= todaysDate
      ) {
        if (!acc[currentWeekKey]) acc[currentWeekKey] = [];
        acc[currentWeekKey].push(event);

      } else if (timeframe === 'NEXTWEEK' &&
        eventDateObj >= startOfNextWeek &&
        eventDateObj <= endOfNextWeek &&
        eventsDate >= todaysDate
      ) {
        if (!acc[nextWeekKey]) acc[nextWeekKey] = [];
        acc[nextWeekKey].push(event);
      }

      return acc;
    }, {});

    setGroupedEvents(newGroupEvents);
  }

  useEffect(()=>{
    groupEventsByDate(shopAppointments.sort((a: any, b: any) => new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime()));
  },[timeframe, shopAppointments]);


  return (
    <div className='c-box--white u-padding-small'>
      <div className='c-crm-dashboard-box'>
        <div className="flex-space-between align-items-center u-margin-bottom">
          <div className='h2 color-aa-red-alt'>
            {((timeframe === 'TODAY' || timeframe === 'TOMORROW')) ?
              <span>
                {
                  new Date(
                    timeframe === 'TODAY' ? currentFullDate : tomorrowFullDate
                  ).toLocaleString((userLanguage || 'en'), {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }
              </span>
            : (timeframe === 'THISWEEK' || timeframe === 'NEXTWEEK') ?
              <span>
                {
                  new Date(timeframe === 'THISWEEK' ? startOfCurrentWeek : startOfNextWeek).toLocaleString((userLanguage || 'en'), {
                    day: "numeric",
                    month: "long",
                  })
                } - {
                  new Date(timeframe === 'THISWEEK' ? endOfCurrentWeek : endOfNextWeek).toLocaleString((userLanguage || 'en'), {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                }
              </span>
            : null
            }
          </div>
          <div className="c-select">
            <select
              id="upcomingTimeframe"
              onChange={(event) => setTimeframe(event.target.value)}
              value={timeframe}
              className='u-text-capitalize'
            >
              {
                timeframeOptions.map((tf: any) => {
                  return (
                    <option key={tf.value} value={tf.value}>
                      {tf.label}
                    </option>
                  )
                })
              }
            </select>
          </div>
        </div>
        <NavLink
          to="/crm/calendar"
          className="u-width-100-percent"
        >
          <div className='l-flex-column u-width-100-percent'>
            { Object.values(groupedEvents).length === 0 ?
                <div className='u-padding-large u-margin-center'>
                  <span>{TextOnly('noAppointments')}</span>
                </div>
              : Object.values(groupedEvents).map( (arrEvents: any, index: any) => {
                return (
                  arrEvents.map((event: any, index: number) => {
                    const nextEvent: any = arrEvents[index+1];
                    const lastEvent: any = arrEvents[index-1];
                    const currentIsNewDay: boolean = new Date(lastEvent?.startDate)?.getDate() !== new Date(event.startDate).getDate();
                    const nextIsNewDay: boolean = new Date(event.startDate).getDate() !== new Date(nextEvent?.startDate)?.getDate();
                    return (
                      <div key={index}>
                        { (timeframe === 'THISWEEK' || timeframe === 'NEXTWEEK' || timeframe === 'TODAY' || timeframe === 'TOMORROW') &&
                          (index === 0 || currentIsNewDay) &&
                          <div className='h3 u-border-radius-small'>
                            <span>
                              {
                                new Date(event.startDate).toLocaleString((userLanguage || 'en'), {
                                  weekday: 'long',
                                  month: 'numeric',
                                  day: 'numeric',
                                })
                              }
                            </span>
                          </div>
                        }
                        <div className="l-flex u-flex-expand">
                          <div className={`u-flex-expand ${!nextIsNewDay ? 'crm-split-line' : ''}`}>
                            <EventCard event={event} />
                          </div>
                        </div>
                      </ div>
                    );
                  })
                )
              })
            }
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default EventsList;
