import { ShopType } from './types';

// app environments
export const AA_ENV = {
  DEV: 'DEV',
  STAGE: 'STAGE',
  PROD: 'PROD',
};

export const SHOP_TYPES: Record<ShopType, ShopType> = {
  ENTERPRISE: 'ENTERPRISE',
  STANDARD: 'STANDARD',
  PLUS: 'PLUS',
};

export const SHOP_ROLES = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  TECH: 'USER',
};

export const SHOP_STATES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CHARGEBACKRECEIVED: 'CHARGEBACKRECEIVED', // CHARGEBACKRECEIVED: when a customer does a chargeback with their bank on our payment.
  SUSPENDED: 'SUSPENDED', // SUSPENDED: when lose a dispute of the chargeback. At that point the user's tools will not work and they will need to talk to us to get their shop into an ACTIVE state again. So I suppose we should have some text upon login when the shop is SUSPENDED as well.
  PENDING: 'PENDING', // PENDING: when the shop is created but not yet active.
  TERMINATED: 'TERMINATED', // TERMINATED: when the shop is terminated because of user canceling or from non-payment or lost dispute
};

export const PAYMENT_STATES = {
  PAST_DUE: 'PAST_DUE',
  TERMINATED: 'TERMINATED',
  USER_CANCEL: 'USER_CANCEL',
  VALID: 'VALID',
  NONE: 'NONE',
};

export const LOG_EVENTS = {
  CREATE_SELF_ACCOUNT: 'CREATE_SELF_ACCOUNT',
  CONFIRM_SELF_ACCOUNT: 'CONFIRM_SELF_ACCOUNT',
  IMPORT_USERS: 'IMPORT_USERS',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  CREATE_SHOP: 'CREATE_SHOP',
  UPDATE_SHOP_INFO: 'UPDATE_SHOP_INFO',
  UPDATE_SHOP_PAYMENT_INFO: 'UPDATE_SHOP_PAYMENT_INFO',
  REMOVE_SHOP_PAYMENT_INFO: 'REMOVE_SHOP_PAYMENT_INFO',
  UPDATE_SHOP_MAX_USERS: 'UPDATE_SHOP_MAX_USERS',
  ADD_USER_TO_SHOP: 'ADD_USER_TO_SHOP',
  REMOVE_USER_FROM_SHOP: 'REMOVE_USER_FROM_SHOP',
  RESPOND_TO_SHOP_INVITE: 'RESPOND_TO_SHOP_INVITE',
  SET_USER_STATE_AT_SHOP: 'SET_USER_STATE_AT_SHOP',
  REGISTER_TOOL_FOR_SHOP: 'REGISTER_TOOL_FOR_SHOP',
  REMOVE_TOOL_FROM_SHOP: 'REMOVE_TOOL_FROM_SHOP',
  IMPORT_SHOP_TOOLS: 'IMPORT_SHOP_TOOLS',
  USER_TOOL_LOGIN: 'USER_TOOL_LOGIN',
  USER_TOOL_ADINITIATE: 'USER_TOOL_ADINITIATE',
  USER_TOOL_ADREQUEST: 'USER_TOOL_ADREQUEST',
  USER_TOOL_ADEXECUTE: 'USER_TOOL_ADEXECUTE',
  INVITE_USERS: 'INVITE_USERS',
  REVOKE_INVITE: 'REVOKE_INVITE',
  CREATE_SIGNUP_LINK: 'CREATE_SIGNUP_LINK',
  UPDATE_SIGNUP_LINK: 'UPDATE_SIGNUP_LINK',
  USER_TOOL_ADINITIATE_ERROR: 'USER_TOOL_ADINITIATE_ERROR',
  USER_TOOL_ADREQUEST_ERROR: 'USER_TOOL_ADREQUEST_ERROR',
  USER_TOOL_ADEXECUTE_ERROR: 'USER_TOOL_ADEXECUTE_ERROR',
  USER_UPGRADE_TO_PLUS: 'USER_UPGRADE_TO_PLUS',
  USER_REPAY_CANCELED_PAYMENT: 'USER_REPAY_CANCELED_PAYMENT',
  USER_DOWNGRADE_SHOP_TO_STANDARD: 'USER_DOWNGRADE_SHOP_TO_STANDARD',
  USER_CANCEL_DOWNGRADE_SHOP: 'USER_CANCEL_DOWNGRADE_SHOP',
  REINSTATE_SHOP_OEM_REGIONS: 'REINSTATE_SHOP_OEM_REGIONS',
  REMOVE_SHOP_OEM_REGIONS: 'REMOVE_SHOP_OEM_REGIONS',
  CERTIFIED_TECH_STATUS_CHANGE: 'CERTIFIED_TECH_STATUS_CHANGE',
  UPDATE_SHOP_MAX_TECH_CERTS: 'UPDATE_SHOP_MAX_TECH_CERTS',
  EDIT_TOOL_FOR_SHOP: 'EDIT_TOOL_FOR_SHOP',
  REMOVE_SHOP_TOOLS: 'REMOVE_SHOP_TOOLS',
  AUTO_SHOP_SUB_STATE_CHANGE: 'AUTO_SHOP_SUB_STATE_CHANGE',
  INITIATE_TOOL_RECOVERY: 'INITIATE_TOOL_RECOVERY',
  CRM_CREATE_CUSTOMER: 'CRM_CREATE_CUSTOMER',
  CRM_UPDATE_CUSTOMER: 'CRM_UPDATE_CUSTOMER',
  CRM_CREATE_EVENT: 'CRM_CREATE_EVENT',
  CRM_UPDATE_EVENT: 'CRM_UPDATE_EVENT',
};

export const TOOL_EVENTS = [
  LOG_EVENTS.USER_TOOL_LOGIN,
  LOG_EVENTS.USER_TOOL_ADINITIATE,
  LOG_EVENTS.USER_TOOL_ADREQUEST,
  LOG_EVENTS.USER_TOOL_ADEXECUTE,
  LOG_EVENTS.USER_TOOL_ADINITIATE_ERROR,
  LOG_EVENTS.USER_TOOL_ADREQUEST_ERROR,
  LOG_EVENTS.USER_TOOL_ADEXECUTE_ERROR,
];

export const SHOP_EVENTS = [
  LOG_EVENTS.CREATE_SELF_ACCOUNT,
  LOG_EVENTS.CONFIRM_SELF_ACCOUNT,
  LOG_EVENTS.IMPORT_USERS,
  LOG_EVENTS.UPDATE_USER_INFO,
  LOG_EVENTS.CREATE_SHOP,
  LOG_EVENTS.UPDATE_SHOP_INFO,
  LOG_EVENTS.UPDATE_SHOP_PAYMENT_INFO,
  // LOG_EVENTS.REMOVE_SHOP_PAYMENT_INFO, // not being used anymore
  LOG_EVENTS.UPDATE_SHOP_MAX_USERS,
  LOG_EVENTS.ADD_USER_TO_SHOP,
  LOG_EVENTS.REMOVE_USER_FROM_SHOP,
  LOG_EVENTS.RESPOND_TO_SHOP_INVITE,
  LOG_EVENTS.SET_USER_STATE_AT_SHOP,
  LOG_EVENTS.REGISTER_TOOL_FOR_SHOP,
  LOG_EVENTS.REMOVE_TOOL_FROM_SHOP,
  LOG_EVENTS.IMPORT_SHOP_TOOLS,
  LOG_EVENTS.INVITE_USERS,
  LOG_EVENTS.REVOKE_INVITE,
  LOG_EVENTS.CREATE_SIGNUP_LINK,
  LOG_EVENTS.UPDATE_SIGNUP_LINK,
  LOG_EVENTS.USER_UPGRADE_TO_PLUS,
  LOG_EVENTS.USER_REPAY_CANCELED_PAYMENT,
  LOG_EVENTS.USER_DOWNGRADE_SHOP_TO_STANDARD,
  LOG_EVENTS.REINSTATE_SHOP_OEM_REGIONS,
  LOG_EVENTS.REMOVE_SHOP_OEM_REGIONS,
  LOG_EVENTS.CERTIFIED_TECH_STATUS_CHANGE,
  LOG_EVENTS.UPDATE_SHOP_MAX_TECH_CERTS,
  LOG_EVENTS.EDIT_TOOL_FOR_SHOP,
  LOG_EVENTS.REMOVE_SHOP_TOOLS,
  LOG_EVENTS.AUTO_SHOP_SUB_STATE_CHANGE,
  LOG_EVENTS.USER_CANCEL_DOWNGRADE_SHOP,
  LOG_EVENTS.INITIATE_TOOL_RECOVERY,
  LOG_EVENTS.CRM_CREATE_CUSTOMER,
  LOG_EVENTS.CRM_UPDATE_CUSTOMER,
  LOG_EVENTS.CRM_CREATE_EVENT,
  LOG_EVENTS.CRM_UPDATE_EVENT,
];
