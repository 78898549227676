import React from 'react';
import { TextOnly } from '../components/Text';

interface genericObj {
  [key: string]: any;
}

interface OrdersSubComponentProps {
  row: any;
  shop: genericObj;
  currentUser: genericObj;
  className?: string;
}
interface LogSubComponentProps {
  oemRegions?: [];
  shop: genericObj;
  row: any;
  currentUser: genericObj;
  className?: string;
}

export const OrdersSubComponent: React.FC<OrdersSubComponentProps> = (props) => {
  const order: genericObj = props.row.original;

  // Matching log entry fields with respective phrase text
  const detailFieldsText: genericObj = {
    // field name in log: text ID
    'description': 'description',
  }

  const detailsObj: genericObj = {};

  for (const field in order) {
    if (detailFieldsText.hasOwnProperty(field)) {
      detailsObj[TextOnly(detailFieldsText[field])] = order[field];
    }
  };

  const detailsCard = cardBodyBuilder(detailsObj, null);

  return (
    <div className='u-margin-bottom'>
      <div className="c-card">
        <h3 className="c-card__title u-margin-none">{TextOnly('details')}</h3>
        <div className="c-card__description u-margin-none">
          <table className="card-table">
            <colgroup>
              <col className="card-table-col-labels-25" />
              <col />
            </colgroup>
            <tbody>{detailsCard}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export const LogSubComponent: React.FC<LogSubComponentProps> = (props) => {
  const { actionCode,
          oemIDs,
          shopChanges,
          userChanges,
          targetUserID,
          addUserID,
          removedOemIDs,
          reinstatedOems,
          addedOems,
          removeFirstName,
          removeLastName,
          removeUserName, } = props.row.original;
  const logEntry: genericObj = props.row.original;
  const oemRegions: [] = props?.oemRegions || [];
  const shop: genericObj = props?.shop || {};
  const currentUser: genericObj = props?.currentUser;

  const getOemNames = (oemIDs?: string[]) => {
    return oemIDs?.map((oemID: string) => {
      const oemRegion: genericObj = oemRegions.find((oem: genericObj) => oem.oemID === oemID) || {};
      return oemRegion?.oemName;
    });
  }

  const oemNames: any[] | undefined = getOemNames(oemIDs);
  const removedOemNames: any[] | undefined = getOemNames(removedOemIDs);
  const reinstatedOemNames: any[] | undefined = getOemNames(reinstatedOems);
  const addedOemNames: any[] | undefined = getOemNames(addedOems);

  // Matching log entry fields with respective phrase text
  const detailFieldsText: genericObj = {
    // field name in log: text ID
    'numToAdd': 'numberAdded',
    'response': 'response',
    'shopUserState': 'userState',
    'shopUserRole': 'userRole',
    'toolName': 'toolNameEdited',
    'toolNotes': 'toolNotesEdited',
    'toolManufacturer': 'toolManufacturer',
    'toolModel': 'toolModel',
    'toolSerial': 'toolSerial',
    'statusChange': 'shopState',
    'oemIDs': 'oems',
    'removedOemIDs': 'removedOems',
    'reinstatedOems': 'reinstatedManufacturers',
    'addedOems': 'addedOems',
  }

  const logObj: genericObj = {};

  switch (actionCode) {
    case 'UPDATE_SHOP_INFO':
      const shopChangesText: genericObj = {
        // field name in log: text ID
        'shopName': 'shopName',
        'shopType': 'shopType',
        'streetAddress1': 'streetAddress1',
        'streetAddress2': 'streetAddress2',
        'city': 'city',
        'state': 'state',
        'zip': 'zipCode',
        'country': 'country',
        'phone': 'phoneNumber'
      };
      shopChanges?.forEach((change: any) => {
        const changeField = TextOnly(shopChangesText[change.field]);
        logObj[changeField] = change.newVal;
      });
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          const value = (field === 'oemIDs')
            ? oemNames?.join(', ')
            : logEntry[field];

          logObj[TextOnly(detailFieldsText[field])] = value;
        }
      };
      break;

    case 'UPDATE_USER_INFO':
      const userChangesText: genericObj = {
        'firstName': 'firstName',
        'lastName': 'lastName',
        'email': 'email',
        'language': 'language',
        'userState': 'userState',
        'phoneNumber': 'phoneNumber',
      };
      userChanges?.forEach((change: any) => {
        const changeField = TextOnly(userChangesText[change.field]);
        logObj[changeField] = change.newVal;
      });
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          logObj[TextOnly(detailFieldsText[field])] = logEntry[field];
        }
      };
      break;

    case 'UPDATE_SHOP_MAX_TECH_CERTS':
      const addedTechCerts = logEntry.numToAdd;
      logObj[TextOnly('addedTechCerts')] = addedTechCerts;
      break;

    case 'UPDATE_SHOP_MAX_USERS':
      const usersAdded = logEntry.numToAdd;
      logObj[TextOnly('addedUsers')] = usersAdded;
      break;

    case 'SET_USER_STATE_AT_SHOP':
      const targetUser = currentUser.userID === targetUserID
        ? currentUser
        : shop.shopUsers.find((user: any) => user.userID === targetUserID);
      logObj[TextOnly('editedUser')] = targetUser?.userName || `<${TextOnly('removedUser')}>`;
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          logObj[TextOnly(detailFieldsText[field])] = logEntry[field];
        }
      };
      break;

    case 'ADD_USER_TO_SHOP':
      const addedUser = currentUser.userID === addUserID
        ? currentUser
        : shop.shopUsers.find((user: any) => user.userID === addUserID);
      logObj[TextOnly('userAdded')] = addedUser?.userName || `<${TextOnly('removedUser')}>`;
      break;

    case 'REINSTATE_SHOP_OEM_REGIONS':
      if (reinstatedOems?.length || addedOems?.length) {
        if (reinstatedOems.length) logObj[TextOnly('reinstatedManufacturers')] = reinstatedOemNames?.join(', ');
        if (addedOems.length) logObj[TextOnly('addedOems')] = addedOemNames?.join(', ');
      } else {
        logObj[TextOnly('reinstatedOems')] = oemNames?.join(', ');
      }
      break;

    case 'REMOVE_SHOP_OEM_REGIONS':
      logObj[TextOnly('removedOems')] = removedOemNames?.join(', ');
      break;

    case 'REGISTER_TOOL_FOR_SHOP':
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          let value = (field === 'toolName') ? logEntry[TextOnly('toolName')]
            : (field === 'toolNotes') ? logEntry[TextOnly('toolNotes')]
            : logEntry[field];

          logObj[TextOnly(detailFieldsText[field])] = value;
        }
      };
      break;

    case 'REMOVE_USER_FROM_SHOP':
      logObj[TextOnly('removedUser')] = `${removeFirstName} ${removeLastName}`;
      logObj[TextOnly('username')] = removeUserName;
      break;

    default:
      for (const field in logEntry) {
        if (detailFieldsText.hasOwnProperty(field)) {
          let value = (field === 'oemIDs')
            ? oemNames?.join(', ')
            // checking length to find if tool name = "" or tool notes = []
            : ((field === 'toolName' || field === 'toolNotes') && !logEntry[field].length)
            ? `<${TextOnly('removed')}>`
            : logEntry[field];

          logObj[TextOnly(detailFieldsText[field])] = value;
        }
      };
  }

  const logCard = cardBodyBuilder(logObj, null);

  return (
    <div className='u-margin-bottom'>
      <div className="c-card">
        <div className="h3 c-card__title u-margin-none">{TextOnly('details')}</div>
        <div className="c-card__description u-margin-none">
          <table className="card-table">
            <colgroup>
              <col className="card-table-col-labels-25" />
              <col />
            </colgroup>
            <tbody>{logCard}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const cardBodyBuilder = (cardObj: genericObj, styles: any) => {
  if (
    Object.keys(cardObj).every((attr: any) => {
      return cardObj[attr] === undefined;
    })
  ) {
    return null;
  }
  return Object.keys(cardObj).map((attr) => {

    let attrValue = cardObj[attr];
    if (attrValue) {
      return (
        <tr className="card-tr" key={attr}>
          <th className={`card-th ${styles ? styles[attr] : ''}`}>{attr}: </th>
          <td className={`card-td ${styles ? styles[attr] : ''}`}>
            {attrValue instanceof Array && attrValue.length > 1 ? (
              <ul>
                {attrValue?.map((value: any, index: number) => (
                  <li key={index}>
                      {value}
                  </li>
                ))}
              </ul>
            ) : attrValue === `<${TextOnly('removedUser')}>`
                || attrValue === `<${TextOnly('removed')}>` ? (
              <div className="u-text-warning">
                {attrValue}
              </div>
            ) : (
              attrValue
            )}
          </td>
        </tr>
      );
    }
    return null;
  });
};