import React, { useState } from "react";
import { Text, TextOnly } from "../components/Text";
import { Dialog } from "@reach/dialog";
import ToolsPromoImage from "../assets/images/toolReportsPromo.png";
import { Header } from "../components/Header";
import { NavLink } from "react-router-dom";

export default function ToolReportsPromo() {
  const [showPromoModal, setShopPromoModal] = useState(false);

  function handleImageClick() {
    setShopPromoModal(true);
  }

  function handleCancelModal() {
    setShopPromoModal(false);
  }

  return (
    <>
      <Header
        context={TextOnly("viewReports")}
        title={TextOnly("toolActions")}
      />

      <div className="l-full-width">
        <div className="c-box c-box--promo-plus">
          <h2 className="c-box__title">
            <Text tid="upgradeToStandardPlus" />
          </h2>
          <div className="l-flex-wrap u-margin-none">
            <p className="u-margin-none">
              <Text tid="upgradeToStandardPlusDescription" />
            </p>
            <NavLink
              className="c-box__cta-btn c-btn"
              to="/upgradeToPlus/comparePlans"
            >
              <Text tid="upgradeNow" />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="l-view-layout__promo">
        <div className="l-container-sm u-margin-none">
          <div className="c-card u-margin-right-none">
            <h2 className="c-card__title u-padding-top-large">
              <Text tid="upgradeNowAndGetAccessToToolReport" />
            </h2>
            <p className="c-card__description">
              <i className="fa fa-plus u-text-success" />{" "}
              <Text tid="toolReportIsSearchableAndFilterable" />
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              <Text tid="seeWhichToolsAndTechsAreHavingErrors" />
              <br />
              <br />
              <i className="fa fa-plus u-text-success" />{" "}
              <Text tid="seeWhichTechsAreUsingToolsAfterHours" />
            </p>
          </div>
        </div>
        <div className="l-container-med-plus u-margin-none">
          <img
            className="promo-image"
            src={ToolsPromoImage}
            onClick={handleImageClick}
            alt={TextOnly("toolActions")}
          />
        </div>
      </div>

      <Dialog
        isOpen={showPromoModal}
        onDismiss={handleCancelModal}
        className="c-modal--wide"
        aria-label={TextOnly("toolActions")}
      >
        <button
          className="c-btn-icon c-modal__close"
          onClick={handleCancelModal}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__header">
          <Text tid="toolActions" />
        </h1>
        <div className="c-modal__body l-container">
          <img src={ToolsPromoImage} alt={TextOnly("toolActions")} />
        </div>
      </Dialog>
    </>
  );
}
