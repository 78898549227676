import React, { Component } from 'react';
import { Text, TextOnly } from '../../components/Text';
import LoaderButton from '../../components/LoaderButton';
import ReactTable from 'react-table';
import {
  getSystemConfig,
  getDirectoryInterfaces,
  deleteDirectoryInterface,
} from '../../libs/db-lib';
import { Dialog } from '@reach/dialog';
import { Loading } from '../../components/Loading';
import { SHOP_TYPES } from '../../CONSTANTS';

import AddDirectoryInterface from './AddDirectoryInterface';
import EditDirectoryInterface from './EditDirectoryInterface';
import { toast } from 'react-toastify';

export default class DirectoryServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      interfaceToDelete: {},
      directoryServices: null,
      directoryRec: null,
      showAddDirectoryModal: false,
      showEditDirectoryModal: false,
      showConfirmDirectoryModal: false,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      let config = await getSystemConfig();
      this.setState({ baseURL: config.baseUrl });

      if (!this.props.currentShop) {
        this.props.history.push('/myActivity');
      }

      let shop = this.props.currentShop;
      let directoryServices = null;
      if (this.props.currentShop.shopType === SHOP_TYPES.ENTERPRISE) {
        directoryServices = await getDirectoryInterfaces(shop.shopID);
      }

      this.setState({
        directoryServices: directoryServices
          ? directoryServices.interfaceList
          : [],
      });
    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener('resize', this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleAddDirectoryInterface = (interfaceRec) => {
    let directoryServices = this.state.directoryServices;
    directoryServices.push(interfaceRec);

    this.setState(
      {
        directoryServices: directoryServices,
      },
      () => {
        console.log(this.state.directoryServices);
      }
    );

    toast.success(TextOnly('directoryAdded'), { containerId: 'standard' });
  };

  handleEditDirectoryInterface = (interfaceRec) => {
    let directoryServices = this.state.directoryServices.map((directory) =>
      directory.directoryID === interfaceRec.directoryID
        ? {
            ...directory,
            friendlyName: interfaceRec.friendlyName,
            directoryID: interfaceRec.directoryID,
            directoryType: interfaceRec.directoryType,
            directoryState: interfaceRec.directoryState,
            configParams: {
              clientID: interfaceRec.configParams.clientID,
              clientSecret: interfaceRec.configParams.clientSecret,
              adfsDomain: interfaceRec.configParams.adfsDomain,
              redirectUrl: interfaceRec.configParams.redirectUrl,
              authorizationPath: interfaceRec.configParams.authorizationPath,
              tokenPath: interfaceRec.configParams.tokenPath,
              serverUrl: interfaceRec.configParams.serverUrl,
              tenantID: interfaceRec.configParams.tenantID,
              caCert: interfaceRec.configParams.caCert,
            },
          }
        : directory
    );

    this.setState({
      directoryServices: directoryServices,
    });

    toast.success(TextOnly('directoryUpdated'), { containerId: 'standard' });
  };

  handleDeleteDirectoryInterface(interfaceRec) {
    this.setState({
      interfaceToDelete: interfaceRec,
      showConfirmDirectoryModal: true,
    });
  }

  async handleConfirmDirectoryModal() {
    // Handle delete of directory interface
    const self = this;

    let interfaceRec = this.state.interfaceToDelete;
    const result = await deleteDirectoryInterface(
      interfaceRec.directoryID,
      interfaceRec.shopID
    );

    if (!result?.error) {
      document
        .querySelector('#confirmRemoveDirectoryFromShop')
        .classList.add('closed');
      setTimeout(() => {
        self.setState({
          showConfirmDirectoryModal: false,
          interfaceToDelete: '',
        });
      }, 350);
      // Update the list of interfaces
      let interfaceList = this.state.directoryServices;
      let newInterfaceList = interfaceList.filter(
        (d) => d.directoryID !== interfaceRec.directoryID
      );
      this.setState({ directoryServices: newInterfaceList });
    } else {
      this.props.handleAlert();

      document
        .querySelector('#confirmRemoveDirectoryFromShop')
        .classList.add('closed');
      setTimeout(() => {
        self.setState({
          alertMessage: result.error,
          showConfirmDirectoryModal: false,
          showModal: true,
        });
      }, 350);
    }
  }

  handleShowAddDirectoryModal = () => {
    this.setState({
      showAddDirectoryModal: true,
    });
  };

  handleHideAddDirectoryModal = () => {
    const self = this;
    document.querySelector('#addDirectory').classList.add('closed');

    setTimeout(() => {
      self.setState({
        showAddDirectoryModal: false,
      });
    }, 350);
  };

  handleShowEditDirectoryModal = (directoryRec) => {
    this.setState({
      directoryRec: directoryRec,
      showEditDirectoryModal: true,
    });
  };

  handleHideEditDirectoryModal = () => {
    const self = this;
    document.querySelector('#editDirectory').classList.add('closed');

    setTimeout(() => {
      self.setState({
        showEditDirectoryModal: false,
      });
    }, 350);
  };

  handleCancelDirectoryModal(e) {
    e.preventDefault();

    const self = this;
    document
      .querySelector('#confirmRemoveDirectoryFromShop')
      .classList.add('closed');
    setTimeout(() => {
      self.setState({
        showConfirmDirectoryModal: false,
        interfaceToDelete: '',
      });
    }, 350);
  }

  handleShowAlert = (alertTitle, alertMessage, showModal) => {
    this.props.handleShowAlert(alertTitle, alertMessage, showModal);
  };

  render() {
    const directoryColDefs = [];
    directoryColDefs.push(
      { Header: <Text tid="name" />, accessor: 'friendlyName', minWidth: 200 },
      {
        Header: <Text tid="directoryId" />,
        id: 'directoryID',
        accessor: 'directoryID',
        minWidth: 200,
      },
      {
        Header: <Text tid="directoryType" />,
        id: 'directoryType',
        accessor: 'directoryType',
        minWidth: 100,
      },
      {
        Header: <Text tid="state" />,
        id: 'directoryState',
        accessor: (directory) => {
          return <Text tid={directory.directoryState} />;
        },
        minWidth: 100,
      },
      {
        Header: this.state.windowWidth > 500 ? <Text tid="actions" /> : '',
        id: 'actions',
        minWidth: this.state.windowWidth > 500 ? 90 : 50,
        Cell: (row) => {
          return (
            <div className="l-flex">
              <button
                className="c-btn-icon"
                onClick={() => this.handleShowEditDirectoryModal(row.original)}
              >
                <div className="c-btn__inner">
                  <span
                    className="c-btn__icon fal fa-edit "
                    title={TextOnly('edit')}
                  />
                </div>
              </button>
              <button
                className="c-btn-icon"
                onClick={this.handleDeleteDirectoryInterface.bind(
                  this,
                  row.original
                )}
              >
                <div className="c-btn__inner">
                  <span
                    className="c-btn__icon fal fa-trash-alt "
                    title={TextOnly('remove')}
                  />
                </div>
              </button>
            </div>
          );
        },
        suppressSorting: true,
        suppressFilter: true,
      }
    );

    const { onDismiss } = this.props;

    return (
      <Dialog
        onDismiss={onDismiss}
        className="c-modal-slider"
        aria-label={TextOnly('signupLinks')}
      >
        {this.state.directoryServices === null ? (
          <Loading />
        ) : (
          <>
            <div className="l-container u-padding-bottom-large">
              <button
                className="c-btn-icon c-modal-slider__close"
                onClick={onDismiss}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-times" />
                </div>
              </button>
              <h1>{TextOnly('directoryServices')}</h1>

              <LoaderButton
                type="button"
                className="u-margin-bottom"
                isLoading={this.state.isLoading}
                onClick={this.handleShowAddDirectoryModal}
                text={TextOnly('addDirectory')}
              />
              <ReactTable
                columns={directoryColDefs}
                data={this.state.directoryServices}
                className="dropdown -highlight"
                showPaginationTop={true}
                previousText={TextOnly('previousPage')}
                nextText={TextOnly('nextPage')}
                pageText={TextOnly('page')}
                ofText={TextOnly('of')}
                rowsText={TextOnly('rows')}
                noDataText={TextOnly('noDirectoryServices')}
                defaultPageSize={10}
                defaultSorted={[
                  {
                    id: 'directoryName',
                    desc: false,
                  },
                ]}
              />
            </div>
          </>
        )}

        {this.state.showAddDirectoryModal && (
          <AddDirectoryInterface
            user={this.props.user}
            onDismiss={this.handleHideAddDirectoryModal}
            handleAddDirectoryInterface={this.handleAddDirectoryInterface}
            handleShowAlert={this.handleShowAlert}
          />
        )}

        {this.state.showEditDirectoryModal && (
          <EditDirectoryInterface
            user={this.props.user}
            onDismiss={this.handleHideEditDirectoryModal}
            directoryRec={this.state.directoryRec}
            handleEditDirectoryInterface={this.handleEditDirectoryInterface}
            handleShowAlert={this.handleShowAlert}
          />
        )}

        <Dialog
          isOpen={this.state.showConfirmDirectoryModal}
          onDismiss={this.handleCancelDirectoryModal.bind(this)}
          id="confirmRemoveDirectoryFromShop"
          className="c-modal-slider"
          aria-label={TextOnly('confirmRemoveDirectoryFromShop')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelDirectoryModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmRemoveDirectoryFromShop" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureRemoveDirectory" />{' '}
              <b>{this.state.interfaceToDelete.friendlyName}</b>
            </p>
            <button
              className="c-btn u-margin-right"
              bsStyle="primary"
              onClick={this.handleConfirmDirectoryModal.bind(
                this,
                this.state.interfaceToDelete
              )}
            >
              <Text tid="confirm" />
            </button>{' '}
            <button
              className="c-btn-outline"
              onClick={this.handleCancelDirectoryModal.bind(this)}
            >
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>
      </Dialog>
    );
  }
}
