import React, { useEffect, useState } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { validateVIN } from '../../../libs/utils';
import { checkAutoVin, crmCreateShopVehicle, crmUpdateShopVehicle } from '../../../libs/db-lib';


const AddEditVehicleForm: React.FC<any> = (props) => {

  const {
    currentShop,
    handleCloseModal,
    isLoading,
    setIsLoading,
    vehicle,
    customer,
    updateSingleCustomer,
    setNewVehicleInfo,
  } = props;

  const [vehicleVin, setVehicleVin] = useState<string>(vehicle?.vin ?? '');
  const [vehicleOdometer, setVehicleOdometer] = useState<string>(vehicle?.odometer ??'');
  const [showVinInput, setShowVinInput] = useState<boolean>(false);
  const [vehicleVinVerified, setVehicleVinVerified] = useState<boolean>(false);
  const [vinVerificationFailed, setVinVerificationFailed] = useState<boolean>(false);
  const [vehicleMake, setVehicleMake] = useState<string>(vehicle?.make ?? '');
  const [vehicleModel, setVehicleModel] = useState<string>(vehicle?.model ?? '');
  const [vehicleYear, setVehicleYear] = useState<string>(vehicle?.year ?? '');
  const [loadingInfoVin, setLoadingInfoVin] = useState<boolean>(false);
  const [vehicleLicensePlate, setVehicleLicensePlate] = useState<string>(vehicle?.licensePlate ?? '');
  const [vehicleColor, setVehicleColor] = useState<string>(vehicle?.color ?? '');

  useEffect(() => {
    if (setNewVehicleInfo) {
      setNewVehicleInfo({
        vin: vehicleVin,
        odometer: vehicleOdometer,
        make: vehicleMake,
        model: vehicleModel,
        year: vehicleYear,
        licensePlate: vehicleLicensePlate,
        color: vehicleColor,
        isValidated: validateSubmit()
      })
    }
  }, [
    vehicleVin,
    vehicleOdometer,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleLicensePlate,
    vehicleColor
  ]);


  const validateSubmit = () => {
    // Making sure all values are valid
    if (
      !!vehicleMake &&
      !!vehicleModel &&
      !!vehicleYear
    ) {
      //It's create view
      if (!vehicle) return true;
      //It's update view
      else {
        // Checking if at least one value changed
        if (
          vehicleVin === vehicle.vin &&
          vehicleOdometer === vehicle.odometer &&
          vehicleMake === vehicle.make &&
          vehicleModel === vehicle.model &&
          vehicleYear === vehicle.year &&
          vehicleLicensePlate === vehicle.licensePlate &&
          vehicleColor === vehicle.color
        ) return false;
        else return true;
      }
    } else {
      //At least one value is not valid
      return false;
    }
  };

  const handleChangeVinInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleVinVerified(false);
    setVinVerificationFailed(false);
    setVehicleMake('');
    setVehicleModel('');
    setVehicleYear('');
    setVehicleVin(event.target.value.toUpperCase());
  }

  const handleGetVinInfo = async () => {
    setLoadingInfoVin(true);
    const response = await checkAutoVin(vehicleVin);
    if(!response.error) {
      setVehicleMake(response.make);
      setVehicleModel(response.model);
      setVehicleYear(response.year);
      setVehicleVinVerified(true);
      setVinVerificationFailed(false);
    } else {
      setVehicleMake('');
      setVehicleModel('');
      setVehicleYear('');
      setVehicleVinVerified(false);
      setVinVerificationFailed(true);

      if (response.error.includes('Invalid VIN')) {
        toast.error(`${TextOnly('invalidVin', {vin: vehicleVin})}`, {
          containerId: 'standard',
        });
      } else {
        toast.error(response.error, {
          containerId: 'standard',
        });
      }
    }
    setLoadingInfoVin(false);
  }

  const handleSubmitVehicle = async () => {
    setIsLoading(true);
    const response =  !vehicle ?
      await crmCreateShopVehicle(
        currentShop.shopID,
        customer.customerID,
        vehicleVin,
        vehicleMake,
        vehicleModel,
        vehicleYear,
        vehicleColor,
        vehicleOdometer,
        vehicleLicensePlate
      ) :
      await crmUpdateShopVehicle(
        currentShop.shopID,
        vehicle?.vehicleID,
        vehicleVin,
        vehicleMake,
        vehicleModel,
        vehicleYear,
        vehicleColor,
        vehicleOdometer,
        vehicleLicensePlate
      );
    if (!response.error) {
      updateSingleCustomer(customer, response, (!vehicle ? 'ADD' : 'UPDATE'));
      toast.success(TextOnly(!vehicle ? 'vehicleAdded' : 'vehicleUpdated'), {
        containerId: 'standard',
      });
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
    setIsLoading(false);
  }

  return (
    <div className='l-container-sm'>
      {!setNewVehicleInfo && (
        <div className="h2">{TextOnly('vehicleInformation')}</div>
      )}
      <div className={`c-box column-flow-centered ${setNewVehicleInfo && 'u-margin-top'}`}>
        <div className='u-width-100-percent'>
          <div className={`c-field`}>
            <div className={"l-inline-flex"}>
              <input
                id="vin"
                type="radio"
                name="vehicleFields"
                value="vin"
                checked={showVinInput}
                onChange={()=>setShowVinInput(true)}
                className="u-margin-right-large"
              />
              <label
                htmlFor='vin'
                className="c-field__label left-padding u-padding-bottom-none u-margin-right-large"
              >
                <Text tid="vin" />
              </label>
            </div>
            <div className={"l-inline-flex"}>
              <input
                id="makeModelYear"
                type="radio"
                name="vehicleFields"
                value="makeModelYear"
                checked={!showVinInput}
                onChange={()=>setShowVinInput(false)}
                className="u-margin-right-large"
              />
              <label
                htmlFor='makeModelYear'
                className="c-field__label left-padding u-padding-bottom-none"
              >
                <Text tid="makeModelYear" />
              </label>
            </div>
          </div>

          {showVinInput ? (
            <div className={`c-field`}>
              <div>
                <label className={`c-field__label`} htmlFor='vin'>
                  <span className="u-text-error">*</span> <Text tid="vin" />:
                </label>
                { vinVerificationFailed &&
                  <label className={`c-field__label u-text-error`} htmlFor='vin'>
                    <Text tid="invalidVin" sub={{vin: vehicleVin as any}} />
                  </label>
                }
                <div className='display-flex'>
                  <input
                    type="text"
                    id="vin"
                    className={`c-input${ (vehicleVin.length > 0 && !validateVIN(vehicleVin) || vinVerificationFailed)  ? '__error' : ''}`}
                    placeholder={TextOnly('vin')+'...'}
                    value={vehicleVin}
                    onChange={handleChangeVinInput}
                  />
                </div>
              </div>
              { !vehicleVinVerified &&
                <div className="c-field get-vin-info u-margin-top-small margin-bottom-0">
                  <LoaderButton
                    disabled={vehicleVin.length <= 0 || loadingInfoVin || !validateVIN(vehicleVin)}
                    text={TextOnly("lookUp")}
                    isLoading={loadingInfoVin}
                    loadingText={TextOnly('loading')}
                    onClick={()=>handleGetVinInfo()}
                  />
                </div>
              }
              <div className={`auto-info-table-wrapper ${(
                  vehicleVinVerified
                  )
                  ? 'show' : 'hide'}`}>
                <table className='u-margin-top-large auto-info-table show'>
                  <tbody>
                    <tr>
                      <td><strong>Make:</strong></td>
                      <td>{vehicleMake}</td>
                    </tr>
                    <tr>
                      <td><strong>Model:</strong></td>
                      <td>{vehicleModel}</td>
                    </tr>
                    <tr>
                      <td><strong>Year:</strong></td>
                      <td>{vehicleYear}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <>
              <div>
                <div className={`c-field`}>
                  <label className={`c-field__label`} htmlFor='make'>
                    { !!vehicleVin ? '' :
                      <span className="u-text-error">*</span>
                    } <Text tid="make" />:
                  </label>
                  <input
                    type="text"
                    id="make"
                    className={`c-input`}
                    placeholder={TextOnly("make")+'...'}
                    value={vehicleMake}
                    onChange={e => setVehicleMake(e.target.value)}
                  />
                </div>
                <div className={`c-field`}>
                  <label className={`c-field__label`} htmlFor='model'>
                    { !!vehicleVin ? '' :
                      <span className="u-text-error">*</span>
                    } <Text tid="model" />:
                  </label>
                  <input
                    type="text"
                    id="model"
                    className={`c-input`}
                    placeholder={TextOnly("model")+'...'}
                    value={vehicleModel}
                    onChange={e => setVehicleModel(e.target.value)}
                  />
                </div>
                <div className={`c-field`}>
                  <label className={`c-field__label`} htmlFor='year'>
                    { !!vehicleVin ? '' :
                      <span className="u-text-error">*</span>
                    } <Text tid="yearCapitalized" />:
                  </label>
                  <input
                    type="text"
                    id="year"
                    className={`c-input`}
                    placeholder={TextOnly("yearCapitalized")+'...'}
                    value={vehicleYear}
                    onChange={e => setVehicleYear(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
{/*
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='vin'>
              <span className="u-text-error">*</span> <Text tid="vin" />:
            </label>
            { vinVerificationFailed &&
              <label className={`c-field__label u-text-error`} htmlFor='vin'>
                <Text tid="invalidVin" sub={{vin: vehicleVin as any}} />
              </label>
            }
            <div className='display-flex'>
              <input
                type="text"
                id="vin"
                className={`c-input${ (vehicleVin.length > 0 && !validateVIN(vehicleVin) || vinVerificationFailed)  ? '__error' : ''}`}
                placeholder={TextOnly('vin')+'...'}
                value={vehicleVin}
                onChange={handleChangeVinInput}
              />
            </div>
            { !vehicleVinVerified &&
              <div className="c-field get-vin-info u-margin-top-small margin-bottom-0">
                <LoaderButton
                  disabled={vehicleVin.length <= 0 || loadingInfoVin || !validateVIN(vehicleVin)}
                  text={TextOnly("decodeVin")}
                  isLoading={loadingInfoVin}
                  loadingText={TextOnly('loading')}
                  onClick={()=>handleGetVinInfo()}
                />
              </div>
            }
            <div className={`auto-info-table-wrapper ${(
                vehicleVinVerified
                )
                ? 'show' : 'hide'}`}>
              <div className='u-margin-top-large'>
                <span className='u-text-success'>* {TextOnly('vinHasBeenVerified')}</span>
              </div>
              <table className={`auto-info-table show`}
              >
                <tbody>
                  <tr>
                    <td><strong>Make:</strong></td>
                    <td>{vehicleMake}</td>
                  </tr>
                  <tr>
                    <td><strong>Model:</strong></td>
                    <td>{vehicleModel}</td>
                  </tr>
                  <tr>
                    <td><strong>Year:</strong></td>
                    <td>{vehicleYear}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <span className='margin-none'>{TextOnly('or')}</span>
        <div className='u-width-100-percent'>
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='make'>
              <span className="u-text-error">*</span> <Text tid="make" />:
            </label>
            <input
              type="text"
              id="make"
              className={`c-input`}
              placeholder={TextOnly("make")+'...'}
              value={vehicleMake}
              onChange={e => setVehicleMake(e.target.value)}
              disabled={vehicleVinVerified}
            />
          </div>
          <div className={`c-field`}>
            <label className={`c-field__label`} htmlFor='model'>
              <span className="u-text-error">*</span> <Text tid="model" />:
            </label>
            <input
              type="text"
              id="model"
              className={`c-input`}
              placeholder={TextOnly("model")+'...'}
              value={vehicleModel}
              onChange={e => setVehicleModel(e.target.value)}
              disabled={vehicleVinVerified}
            />
          </div>
          <div className={`c-field`}>
            <label className={`c-field__label u-text-capitalize`} htmlFor='year'>
              <span className="u-text-error">*</span> <Text tid="year" />:
            </label>
            <input
              type="text"
              id="year"
              className={`c-input u-text-capitalize`}
              placeholder={TextOnly("year")+'...'}
              value={vehicleYear}
              onChange={e => setVehicleYear(e.target.value)}
              disabled={vehicleVinVerified}
            />
          </div>
        </div>*/}
      <div className={`c-field`}>
        <label className={`c-field__label`} htmlFor='vehicleLPN'>
          <Text tid="licensePlate" />:
        </label>
        <input
          type="text"
          id="vehicleLPN"
          className={`c-input`}
          placeholder={TextOnly('licensePlate')+'...'}
          value={vehicleLicensePlate.toUpperCase()}
          onChange={(event)=>setVehicleLicensePlate(event.target.value.toUpperCase())}
        />
      </div>
      <div>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor='vehicleColor'>
            <Text tid="vehicleColor" />:
          </label>
          <input
            type="text"
            id="vehicleColor"
            className={`c-input`}
            placeholder={TextOnly('vehicleColor')+'...'}
            value={vehicleColor}
            onChange={(event)=>setVehicleColor(event.target.value.toUpperCase())}
          />
        </div>
        <div className={`c-field`}>
          <label className={`c-field__label`} htmlFor='odometer'>
            <Text tid="odometer" />:
          </label>
          <input
            type="text"
            id="odometer"
            className={`c-input`}
            placeholder={TextOnly('odometer')+'...'}
            value={vehicleOdometer}
            onChange={(event)=>setVehicleOdometer(event.target.value)}
          />
        </div>
      </div>
      {!setNewVehicleInfo && (
        <div className="c-field display-flex justifycontent-center">
          <LoaderButton
            text={TextOnly('cancel')}
            onClick={(event) => {
              event.preventDefault();
              handleCloseModal();
            }}
            disabled={isLoading}
            className="c-btn-outline"
          />
          &nbsp;
          <LoaderButton
            text={TextOnly('save')}
            onClick={(event) => {
              event.preventDefault();
              handleSubmitVehicle();
            }}
            isLoading={isLoading}
            disabled={isLoading || (!validateSubmit())}
          />
        </div>
      )}
    </div>
  );
}


export default AddEditVehicleForm;
