import { useState, useEffect } from 'react';

const useScreenSize = () => {

  // Initial setup of isMobile
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = !!userAgent.match(/(mobile|android|iphone|ipad)/);

  const [isMobile, setIsMobile] = useState(isMobileDevice);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    // Update isMobile
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = !!userAgent.match(/(mobile|android|iphone|ipad)/);
    setIsMobile(isMobileDevice);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile, windowWidth, windowHeight };
}

export default useScreenSize;
