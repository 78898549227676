import React, { useEffect, useState } from "react";
import { getRegionOemToolCompatibility } from "../../libs/db-lib";
import { Loading } from '../Loading';
import { toast } from "react-toastify";
import { TextOnly, Text } from "../Text";
import LoaderButton from "../LoaderButton";

/*
  Cesar Solano:
  The first column is requested to have the same width in all tables without
  fixing an arbitrary size. We accomplish this by doing two important things:
    1. Saving the longest string among all tool names, which are printed
      in the first column. Lines 45-47. (We do this because the width of a column is set by the longest element in the column and the width of the table.)
      1.1 Setting our state variable "hideElement" to the longest found string. Line 52
      1.2 Adding hideElement at the end of <tbody> with a hide class
          "comp-table-hide-element" so all tables have the widest element (but it is not visible). Line 143-145
    2. Saving all OEMs (first row in <thead>). Lines 48-50.
      2.1 Adding all OEMs <thead>, making visible only the ones that belong to
          that region. Line 102-110 (We do this so all tables have the same width, making the first row have the same width among all tables.)
*/

interface CompatibleToolsProps {
  showOnlyRegion?: string;
  closeFunction?: Function;
  closeButtonText?: string;
  moveCloseButtonToTop?: boolean;
  chartData: RegionData[];
}

interface RegionData {
  [key: string]: any;
}


const CompatibleTools: React.FC<CompatibleToolsProps> = (props) => {
  const { showOnlyRegion, closeFunction, closeButtonText, moveCloseButtonToTop = false } = props;
  const chartData: RegionData[] = props.chartData;
  const [hideElement, setHideElement] = useState<string>('');
  const [allOems, setAllOems] = useState<any[]>([]);

  const processChartData = () => {
    let maxLengthStringTool = '';
    const oems:any = [];
    chartData?.forEach((regionData: RegionData) => {
      regionData.toolSupported.forEach((tool: any) => {
        if (tool.length > maxLengthStringTool.length) {
          maxLengthStringTool = tool;
        }
      })
      regionData.supportedOems.forEach((oemObj: any) => {
        if (!oems.includes(oemObj.oemName)) oems.push(oemObj.oemName);
      })
    });
    setAllOems(oems);
    setHideElement(maxLengthStringTool);
  };

  useEffect(() => {
    processChartData();
  }, [chartData]);

  const chartTable: any[] = [];
  chartData?.forEach((regionData: RegionData) => {
    const currentRegionOems:any = [];
    regionData.supportedOems.forEach((oemObj: any) => {
      currentRegionOems.push(oemObj.oemName)
    });

    if (!showOnlyRegion || showOnlyRegion === regionData.regionName) {
      chartTable.push(
        <div key={regionData.regionName} >
          <div className="h2 margin-bottom-0 u-margin-top-large">{regionData.regionName}</div>
          <table className="compatibility-table">
            <thead>
              <tr>
                <th className="h3">{TextOnly('compatibility')}</th>
                {regionData.supportedOems.sort((a: any, b: any)=>a.oemName.localeCompare(b.oemName)).map((oem: any) => (
                  <th key={oem.oemName}><div className="h2">{oem.oemName}</div></th>
                ))}
                { // Adding all oems that are not in currentRegionOems to avoid duplicated
                  allOems.map((oemName: string) => {
                    if(!currentRegionOems.includes(oemName)){
                      return <th key={oemName} className="comp-table-hide-element"><div className="h2 comp-table-hide-element">{oemName}</div></th>
                    } else {
                      return null;
                    }
                  })
                }
              </tr>
            </thead>
            <tbody>
              {regionData.toolSupported.sort((a: any, b:any) => {
                  if (a === null) return 1;
                  if (b === null) return -1;

                  const stringA = a.toLowerCase();
                  const stringB = b.toLowerCase();

                  if (stringA < stringB) return -1;
                  if (stringA > stringB) return 1;
                  return 0;
                }).map((toolSupported: any)=>{
                  return (
                    <tr key={toolSupported}>
                      <td>{toolSupported}</td>
                      {regionData.supportedOems.sort((a: any, b: any)=>a.oemName.localeCompare(b.oemName)).map((oem: any) => {
                        return (
                          <td key={oem.oemName}>
                            {(oem.supportAll || oem.toolSupported.includes(toolSupported)) ?
                            <div className="u-text-center u-width-100-percent u-text-success">
                              <i className="fa fa-check" title="fa-check" />
                            </div>
                            : ''
                            }
                          </td>
                        )
                      })}
                    </tr>
                  )
              })}
              {/* Adding the tool with the longest string to all tables but making it invisible */}
              <tr className="comp-table-hide-element no-height">
                <td className="comp-table-hide-element">{hideElement}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  })

  return (
    <div className="overflow-x-auto">
      <>
        <div>
          <span className="u-text-warning">
            <Text tid="disclaimerCompatibilityChart" />&nbsp;
          </span>
          <Text tid="genericCompability" />
        </div>
        {(moveCloseButtonToTop && closeFunction) &&
          <div className="display-flex-justify-content-center u-margin-bottom-large u-margin-top-large">
            <LoaderButton
              type="button"
              text={closeButtonText || TextOnly('close')}
              onClick={()=>closeFunction()}
            />
          </div>
        }
        { chartTable }
        {(!moveCloseButtonToTop && closeFunction) &&
          <div className="display-flex-justify-content-center">
            <LoaderButton
              type="button"
              text={closeButtonText || TextOnly('close')}
              onClick={()=>closeFunction()}
            />
          </div>
        }
      </>
    </div>
  )
}

export default CompatibleTools;