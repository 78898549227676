import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from '@fullcalendar/interaction';
import { TextOnly } from '../../../components/Text';
import ToolTip from '../../../components/ToolTip';
import { Dialog } from '@reach/dialog';
import AddEditEventForm from './AddEditEventForm';

const Calendar: React.FC<any> = (props) => {
  const {
    userLanguage,
    shopEvents,
    currentShop,
    getAllShopEvents,
    shopUserList,
    shopOrders,
    shopCustomers,
    user,
  } = props;
  const calendarDiv = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<any>(null);

  const [calendarDimensions, setCalendarDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [showModalEditEvent, setShowModalEditEvent] = useState<boolean>(false);
  const [eventData, setEventData] = useState<any>();
  const [isListView, setIsListView] = useState(false);
  const [updateShopEvents, setUpdateShopEvents] = useState<any[]>([]);
  const calendarLang = allLocales.some((obj) => obj.code === userLanguage)
    ? userLanguage
    : 'en';

  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);

  const handleCancelModal = () => {
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      setShowModalEditEvent(false);
    }, 350);
  };

  const handleEventClick = (info: any) => {
    const selectedEvent = shopEvents.find((event: any) => event.eventID === info.event.extendedProps.eventID);
    setEventData(selectedEvent);
    setShowModalEditEvent(true);
  };

  const handleDateClick = (dateObj: any) => {
    if (dateObj.view.type === 'dayGridMonth') {
      dateObj.view.calendar.changeView('timeGridDay', dateObj.dateStr);
    }
  };

  useEffect(() => {
    const getElementDimensions = () => {
      if (calendarDiv.current) {
        const { width, height } =
          calendarDiv.current.getBoundingClientRect();
        setCalendarDimensions({ width, height });
      }
    };

    // Call the function initially
    getElementDimensions();

    // Call the function whenever the window is resized
    window.addEventListener('resize', getElementDimensions);

    return () => {
      window.removeEventListener('resize', getElementDimensions);
    };
  }, []);

  const handleViewButtonClick = () => {
    // Access the FullCalendar API object using the ref
    const calendarApi = calendarRef.current.getApi();
    const newViewCal = isListView ? 'grid' : 'list'
    setIsListView(!isListView);
    updateView(calendarApi);
    updateToolbar(newViewCal, calendarApi);
  };

  useEffect(() => {
    const updatedEvents = shopEvents.map((event: any)=> {
      const startDate = event?.startDate?.split('T')[0];
      const endDate = event?.endDate?.split('T')[0];
      const isMultipleDaysEvent = !!endDate && (endDate !== startDate);
      if (isMultipleDaysEvent) {
        // Create a Date object
        const date = new Date(`${endDate}T00:00:00`);
        // Make end date inclusive
        date.setDate(date.getDate() + 1);
        // event.end = date.toISOString().split('T')[0];
        return {
          ...event,
          start: event.startDate,
          end: date.toISOString().split('T')[0],
        }
      }
      return ({
        ...event,
        start: event.startDate,
        end: event.endDate,
      });
    });

    setUpdateShopEvents(updatedEvents);

  }, [shopEvents]);

  const updateView = (calendarApi: any) => {
    // Get the current view type (dayGridMonth, timeGridWeek, timeGridDay)
    const currentView = calendarApi.view.type;

    // Map view types to their corresponding list views
    const viewMap:any = {
      dayGridMonth: 'listMonth',
      timeGridWeek: 'listWeek',
      timeGridDay: 'listDay',
      listMonth: 'dayGridMonth',
      listWeek: 'timeGridWeek',
      listDay: 'timeGridDay',
    };

    // Change the view to the corresponding list view based on the current view
    const newView = viewMap[currentView];
    if (newView) {
      calendarApi.changeView(newView);
    }
  }

  const updateToolbar = (viewType: string, calendarApi: any) => {

    const toolbarOptions = {
      left: 'prev next today',
      center: 'title',
      right: '',
    };

    if (viewType === 'list') {
      toolbarOptions.right = 'listMonth,listWeek,listDay buttonView';
    } else {
      toolbarOptions.right = 'dayGridMonth,timeGridWeek,timeGridDay buttonView';
    }

    calendarApi.setOption('headerToolbar', toolbarOptions);
  };

  const customButtons = {
    buttonView: {
      text: isListView ? TextOnly('gridView') : TextOnly('listView'),
      click: handleViewButtonClick,
    },
  }

  return (
    <div className="c-box event-calendar" ref={calendarDiv}>
      <FullCalendar
        ref={calendarRef}
        plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
        dateClick={handleDateClick}
        slotEventOverlap={false}
        eventContent={({ event, view }: any) => {
          if (view.type === 'dayGridMonth') {
            if (!event?.allDay) {
              const options = { hour12: true, hour: '2-digit' as any, minute: '2-digit' as any};
              const timeString = (new Date(event?.start)).toLocaleTimeString('en-US', options);
              return (
                <>
                  <div className="fc-daygrid-event-dot"></div>
                  <div className="fc-event-time">
                    <ToolTip
                      text={event?.extendedProps?.description}
                    >
                      {timeString}
                    </ToolTip>
                  </div>
                  <div className="fc-event-title">
                    <ToolTip
                      text={event?.extendedProps?.description}
                    >
                      {event.title}
                    </ToolTip>
                  </div>
                </>
              )
            } else {
              return (
                <ToolTip
                  text={event?.extendedProps?.description}
                >
                  <div className="u-margin-left fc-text-allday-monthview">
                    <span>{event.title}</span>
                    &nbsp;-&nbsp;
                    <span className="font-style-italic">{event?.extendedProps?.description}</span>
                  </div>
                </ToolTip>
              );
            }
          } else if (view.type === 'timeGridWeek' || view.type === 'timeGridDay') {
            return (
              <ToolTip
                text={event?.extendedProps?.description}
              >
                <div className="fc-gridweek-gridday-wrapper">
                  <div>
                    <span>{event.title}</span>
                  </div>
                  { view.type === 'timeGridDay' &&
                    <div className="u-margin-left">
                      <span>{TextOnly('description')}: </span>
                      <span className='font-style-italic'>{event?.extendedProps?.description}</span>
                    </div>
                  }
                </div>
              </ToolTip>
            );
          } else if (view.type === 'listMonth' || view.type === 'listWeek' || view.type === 'listDay') {
            return (
              <ToolTip
                text={event?.extendedProps?.description}
              >
                <div className="l-flex-between u-cursor-pointer">
                  <span>{event.title}</span>
                  <span className='u-font-weight-500'>{TextOnly(event.extendedProps.eventStatus)}</span>
                </div>
              </ToolTip>
            );
          } else {
            return null;
          }
        }}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay buttonView'
        }}
        views={{
          listDay: { buttonText: TextOnly('day') }, // Configuration for listDay view
          listWeek: { buttonText: TextOnly('week') }, // Configuration for listWeek view
          listMonth: { buttonText: TextOnly('month') } // Configuration for listMonth view
        }}
        customButtons={customButtons}
        events={updateShopEvents}
        locales={allLocales}
        locale={calendarLang}
        eventClick={handleEventClick}
        aspectRatio={
          (calendarDimensions.width + 100) /
          calendarDimensions.height
        }
        slotMinTime={'05:00:00'}
        slotMaxTime={'21:00:00'}
      />
      <Dialog
        isOpen={showModalEditEvent}
        className="c-modal-slider"
        id={`editEvent`}
        aria-label={'editEvent'}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={()=>handleCancelModal()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          {eventData?.eventType === "APPOINTMENT" ? TextOnly('changeAppointment') : TextOnly('changeEmployeeTimeOff')}
        </h1>
        <AddEditEventForm
          currentShop={currentShop}
          onSubmit={getAllShopEvents}
          isLoading={isLoadingModal}
          setIsLoading={setIsLoadingModal}
          handleCloseModal={() => setShowModalEditEvent(false)}
          eventData={eventData}
          setEventData={getAllShopEvents}
          shopUserList={shopUserList}
          shopOrderList={shopOrders}
          isAppointment={eventData?.eventType === "APPOINTMENT"}
          shopCustomerList={shopCustomers}
          user={user}
        />
      </Dialog>
    </div>
  );
};

export default Calendar;
