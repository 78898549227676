import React, { useEffect, useState } from 'react';
import { formatDateTime } from '../../libs/utils';
import {
  deleteContent,
  getThreadSingle,
  createPost,
  createVote,
  admoFunction,
  admoTreeLock,
} from '../../libs/db-lib';
import loading from '../../assets/icons/loading.svg';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import './Thread.css';
import { Loading } from '../../components/Loading';
import LoaderButton from '../../components/LoaderButton';
import { Header } from '../../components/Header';
import { Dialog } from '@reach/dialog';
import { Thread, Post, POST_BODY_MAX_LENGTH, REPORT_BODY_MAX_LENGTH, REPORT_BODY_MIN_LENGTH, POST_BODY_MIN_LENGTH, CHECKBOX_MIN_LENGTH } from './types';
import { PostComponent } from './Post';
import { toast } from 'react-toastify';
import { TextOnly } from '../../components/Text';
import { ThreadComponent } from './Thread';
import OffensiveContentModal from './OffensiveContentModal';
import GoBackArrow from './GoBackArrow';
import './MessageBoard.css';

interface RouteParams {
  threadID: string;
}

type Checkbox = {
  label: string;
  checked: boolean;
};


const ThreadDetail = (props: any) => {
  const shopID = props.currentShop.shopID;
  // #region Variables
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const postID = queryParams.get('postID');
  const user = props.user;
  const userID = user.userID;
  const userVotes = props.userVotes;
  const setUserVotes = props.setUserVotes;
  const userRole = user.application.MESSAGE_BOARD.mbRole;
  const { threadID } = useParams<RouteParams>();

  const {
    mainNotedThreadList,
    setMainNotedThreadList,
    lekNoted,
    setLekNoted,
    mainNewestThreadList,
    setMainNewestThreadList,
    lekNewest,
    setLekNewest,
    mainRatedThreadList,
    setMainRatedThreadList,
    lekRated,
    setLekRated,
  } = props.threadFeat;

  const {
    refetchThreadList,
    setRefetchThreadList,
    threadList,
    setThreadList,
    threadQuery,
    setThreadQuery,
    lekQuery,
    setLekQuery,
    isLoadingThread,
    setIsLoadingThread,
    isSyncing,
    setIsSyncing,
    isDeleting,
    setIsDeleting,
  } = props.stateVar;

  const initialCheckboxes: Checkbox[] = [
    { label: TextOnly('mbSpam'), checked: false },
    { label: TextOnly('mbOffensive'), checked: false },
    { label: TextOnly('mbViolation'), checked: false },
    { label: TextOnly('mbTrolling'), checked: false },
    { label: TextOnly('mbMisinformation'), checked: false },
    { label: TextOnly('mbInappropriate'), checked: false },
    { label: TextOnly('mbDuplicate'), checked: false },
    { label: TextOnly('mbPrivacy'), checked: false },
    { label: TextOnly('mbOther'), checked: false },
  ];
  // #endregion

  // #region stateVariables
  const [checkboxes, setCheckboxes] = useState<Checkbox[]>(initialCheckboxes);
  const [threadLocal, setThreadLocal] = useState<Thread | null>();
  const [threadPosts, setThreadPosts] = useState<Post[]>([]);
  const [postBody, setPostBody] = useState<string>('');
  const [reportBody, setReportBody] = useState<string>('');
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingFetch, setLoadingFetch] = useState<boolean>(true);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(isDeleting);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalReport, setShowModalReport] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingVote, setIsLoadingVote] = useState<boolean>(false);
  const [showConfirmedModal, setShowConfirmedModal] = useState<boolean>(false);
  const [showConfirmedReport, setShowConfirmedReport] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [deleteReason, setDeleteReason] = useState<string>('');
  const [showOriginal, setShowOriginal] = useState<boolean>(false);
  const [showOffensiveModal, setShowOffensiveModal] = useState<boolean>(false);
  // #endregion

  // #region Ref Variables
  // #endregion

  // #region handle functions
  const handleUpdateThreadLists = () =>{
    if(threadLocal){
    const threadID = threadLocal.threadID;
      setThreadPosts(threadLocal.postCollection ?? []);
      // [mainNewestThreadList, setMainNewestThreadList],
      if(mainNewestThreadList.some((obj: Thread) => obj.threadID === threadID)){
        const auxList = [];
        for (const threadAux of mainNewestThreadList) {
          if(threadAux.threadID===threadLocal.threadID){
            if ( !(threadLocal.deleted&&threadLocal.replies===0&&threadLocal.deletedBy==='BY_OWNER') ) {
              auxList.push(threadLocal);
            }
          } else {
            auxList.push(threadAux);
          }
        }
        setMainNewestThreadList(auxList);
        if(threadQuery==='time') setThreadList(auxList);
      }

      // [mainRatedThreadList, setMainRatedThreadList],
      if(mainRatedThreadList.some((obj: Thread) => obj.threadID === threadID)){
        const auxList =[];
        for (const threadAux of mainRatedThreadList) {
          if(threadAux.threadID===threadLocal.threadID){
            if ( !(threadLocal.deleted&&threadLocal.replies===0&&threadLocal.deletedBy==='BY_OWNER') ) {
              auxList.push(threadLocal);
            }
          } else {
            auxList.push(threadAux);
          }
        }
        setMainRatedThreadList(auxList);
        if(threadQuery==='likes') setThreadList(auxList);
      }
    }
  }
  const handleUpdateThreadPosts = (newPost:any, removePost: boolean = false) => {
    const threadLocalAux = threadLocal;
    const threadPostsAux = threadLocalAux?.postCollection ?? [];
    const isReply = newPost.threadID!==newPost.contentID;
    let newThreadPosts:any = [];
    if(removePost) {
      if(isReply){
        for(const comment of threadPostsAux){
          const removeAux = comment?.repliesCollection?.find(reply=>reply.postID===newPost.postID);
          if(removeAux){
            const newComment = {...comment};
            newComment.repliesCollection = comment!.repliesCollection!.filter(reply=>reply.postID!==newPost.postID);
            newThreadPosts.push(newComment);
          } else newThreadPosts.push(comment);
        }
      } else {
        newThreadPosts = threadPostsAux.filter(post=>post.postID!==newPost.postID);
      }
    } else {
      if(isReply){
        for(const comment of threadPostsAux){
          if(comment.postID===newPost.contentID){
            const newComment = {...comment};
            if(newComment.repliesCollection) newComment.repliesCollection.push(newPost);
            else newComment.repliesCollection = [newPost];
            newThreadPosts.push(newComment);
          } else newThreadPosts.push(comment);
        }
      } else {
        newThreadPosts = [newPost,...threadPostsAux];
      }
    }
    threadLocalAux!.postCollection = newThreadPosts;
    setThreadLocal({...threadLocalAux} as Thread);
  }
  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
  };
  const handleVote = async (
    contentID: string,
    userRating: boolean,
    reportAbuse: boolean
  ) => {
    if((reportAbuse===true && (!validateReportBody(reportBody) || !validateReportCheckboxes(checkboxes))) ||
       threadLocal?.deleted || userRole==='READONLY' || userRole==='MUTED' || threadLocal?.locked || isLoadingVote
    ) return;
    setIsLoadingVote(true);
    const selectedOptions = checkboxes
      .filter((checkbox: Checkbox) => checkbox.checked)
      .map((checkbox: Checkbox) => checkbox.label);
    const optionsString = selectedOptions.join('. ') + '.';
    const reportReason = reportBody ? optionsString + '\n User Description: ' + reportBody : undefined;
    const response = await createVote(
      contentID,
      undefined, //We dont need postID if we voting in a Thread because to get/update thread we just need thread
      userRating,
      reportAbuse,
      reportReason,
      'thread',
      shopID,
    );
    if (!response?.error) {
      setShowModalReport(false);
      setReportBody('');
      if(reportAbuse) setShowConfirmedReport(true);
      if (threadLocal) {
        const threadLocalAux = { ...threadLocal };
        threadLocalAux.likes = response.Attributes.likes;
        threadLocalAux.dislikes = response.Attributes.dislikes;
        setThreadLocal(threadLocalAux);
      }
      const aux = { ...userVotes };
      if (
        aux[contentID] &&
        aux[contentID].userRating === userRating &&
        aux[contentID].reportAbuse === reportAbuse
      )
        delete aux[contentID];
      else aux[contentID] = { contentID, userRating, reportAbuse, userID };
      aux.list = [];
      Object.keys(aux).forEach((voteKey) => aux.list.push(aux[voteKey]));
      setUserVotes(aux);
    } else {
      if(response.error==='offensiveContent'){
        setShowOffensiveModal(true);
      } else {
        toast.error(
          response.error,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
    setIsLoadingVote(false);
  };
  const handleDeleteThread = async (threadID: string) => {
    setLoadingDelete(true);
    const response = await deleteContent(
      threadID,
      undefined,
      'thread',
      shopID,
      deleteReason,
    );
    setLoadingDelete(false);
    if (!response?.error) {
      setShowModalDelete(false);
      setShowConfirmedModal(true);
      const threadLocalAux = { ...threadLocal };
      threadLocalAux.title = `**${TextOnly('contentDeleted')}**`;
      threadLocalAux.body = `**${TextOnly('contentDeleted')}**`;
      threadLocalAux.deleted = true;
      threadLocalAux.deletedBy = threadLocalAux.userID===userID ? 'BY_OWNER' : `BY_ADMO_${user.userName}`;
      if(threadLocalAux.deletedBy !== 'BY_OWNER') threadLocalAux.deleteReason = deleteReason;
      setThreadLocal(threadLocalAux as Thread);
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
  };
  const handleChangePostBody = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newPostBody = event.target.value;
    if(newPostBody.length > POST_BODY_MAX_LENGTH) return
    setPostBody(newPostBody);
  };
  const handleChangeDeleteReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newDeleteReason = event.target.value;
    if(newDeleteReason.length > POST_BODY_MAX_LENGTH) return
    setDeleteReason(newDeleteReason);
  };
  const handleChangeReportBody = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newReportBody = event.target.value;
    if(newReportBody.length > REPORT_BODY_MAX_LENGTH) return
    setReportBody(newReportBody);
  };
  const validateReportBody = (body: any) =>{
    return (typeof(body) === 'string' &&
      body.length >= REPORT_BODY_MIN_LENGTH &&
      body.length <= REPORT_BODY_MAX_LENGTH);
  }
  const validateReportCheckboxes = (checkboxes: any) =>{
    if(!Array.isArray(checkboxes)) return false;
    const areAllCheckboxes = checkboxes.every(
      (checkbox): checkbox is Checkbox => {
        return (
          typeof checkbox === 'object' &&
          'label' in checkbox &&
          'checked' in checkbox
        );
      }
    );
    if(!areAllCheckboxes) return false;
    const selectedOptions = checkboxes
      .filter((checkbox: Checkbox) => checkbox.checked)
      .map((checkbox: Checkbox) => checkbox.label);
    return selectedOptions.length >= CHECKBOX_MIN_LENGTH;
  }
  const handleCancelReport = () =>{
    setShowModalReport(false);
    setReportBody('');
  }
  const validatePostBody = (body: any) =>{
    return (typeof(body) === 'string'
      && body.length >= POST_BODY_MIN_LENGTH
      && body.length <= POST_BODY_MAX_LENGTH);
  }
  const handleCreatePost = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!threadLocal || !validatePostBody(postBody)) return;
    setLoadingCreate(true);
    const response = await createPost(
      postBody,
      threadID,
      undefined, //We dont need postID if we are creating a post in a Thread because to get/update thread we just need threadID
      threadID,
      'thread',
      shopID,
    );
    if (!response?.error) {
      handleClearForm();
      setShowForm(false);
      handleUpdateThreadPosts(response.post);
    } else {
      if(response.error==='offensiveContent'){
        setShowOffensiveModal(true);
      } else {
        toast.error(
          response.error,
          {
            containerId: 'standard',
            autoClose: false,
          }
        );
      }
    }
    setLoadingCreate(false);
  };
  const handleClearForm = () => {
    setPostBody('');
  };
  const handleGoBack = () => {
    window.scrollTo(0, 0);
    window.location.hash = '';
    history.goBack();
    window.scrollTo(0, 0);
    window.location.hash = '';
  };
  const handleCloseModal = () => {
    setIsDeleting(false);
    setShowModalDelete(false);
  };
  const handleAdmoFunction = async (functionType:string, newValue:boolean) => {
    if(!threadLocal) return
    setIsLoading(true);
    const response = await admoFunction(
      threadLocal.threadID,
      undefined,
      'thread',
      functionType,
      shopID,
      newValue,
    );
    if (!response?.error) {
      handleUpdate(response.Attributes);
      const toastMessage = `Thread ${
        functionType === 'BLOCK' ?
          response.Attributes.blocked ? 'blocked' : 'unblocked'
        : response.Attributes.locked ? 'locked' : 'unlocked'
      }`
      toast.success(toastMessage,
      {
        containerId: 'standard',
      });
    } else {
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setIsLoading(false);
  }
  // const handleTreeLock = async () => {
  //   if(!threadLocal) return
  //   setIsLoading(true)
  //   const body = {
  //     contentID: threadLocal.threadID,
  //     contentType: 'thread',
  //     blocked: threadLocal.blocked,
  //     shopID,
  //   }
  //   const response = await admoTreeLock(body);
  //   if (!response?.error) {
  //     handleUpdate(response.Attributes);
  //     toast.success('Tree locked successfully',
  //     {
  //       containerId: 'standard',
  //     });
  //   } else toast.error('An error occurred. '
  //    + response.error, {
  //     containerId: 'standard',
  //     autoClose: false,
  //   });
  //   setIsLoading(false);
  // }
  const handleUpdate = (content:any) =>{
    setThreadLocal(content)
  }
  // #endregion

  // #region UseEffect

  //! fetchThreadSingle
  //If Thread is not passed as prop
  const fetchThreadSingle = async () => {
    setLoadingFetch(true);
    const response = await getThreadSingle(threadID, shopID);
    if (!response?.error) {
      setThreadLocal(response.threadDetail);
    } else {
      setThreadLocal(null);
      toast.error(
        response.error,
        {
          containerId: 'standard',
          autoClose: false,
        }
      );
    }
    setLoadingFetch(false);
  };
  useEffect(() => {
    fetchThreadSingle();
  }, []);

  //Everytime we update the thread we update threadLists
  useEffect(() => {
    handleUpdateThreadLists()
  }, [threadLocal]);
  // #endregion

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loadingFetch || threadLocal===undefined) //Still loading or state updating
    return (
      <>
        <Header title={TextOnly('threadDetails')} comp={<GoBackArrow />} />
        <Loading />
      </>
    );
  else if (threadLocal===null) { //An error occurred
    return (
      <>
        <Header title={TextOnly('threadDetails')} comp={<GoBackArrow />} />
        <div className="c-box">
          <div className="">
            <h1>{TextOnly('threadDoesNotExist')}</h1>
          </div>
        </div>
      </>
    );
  }
  else return (
    <>
      <Header title={TextOnly('threadDetails')} comp={<GoBackArrow />} />
      <div
        className={`c-box ${windowWidth < 650 ? 'smaller-screen-padding' :''} ${threadLocal.locked ? 'locked hoverable' : ''}`}
        data-hover-text={TextOnly("lockedThreadMessage")}>
        <div className="l-flex flex-space-between">
          <div className='mb-title color-aa-darkblue'>
            { threadLocal.locked &&
              <span
                className="c-btn__icon color-lock fas fa-lock"
                title={TextOnly('lockedContent')}
              />
            }
            <span className='prewrap-breakword'>
              {threadLocal?.deleted ? '**'+TextOnly('contentDeleted')+'**' : threadLocal?.title}
            </span>
          </div>
          <div className="thread-info">
            <div className="l-flex">
              <div className="thread-views hoverable-title padding-sides-8"
                data-hover-text={TextOnly('views')}
              >
                <div className="c-btn__inner" >
                  <span
                    className="c-btn__icon fas fa-eye u-padding-none"
                  />
                </div>
                <div className="mb-count">
                  {threadLocal?.views || 1}
                </div>
              </div>
              { isLoadingVote ?
                <div
                >
                  <img src={loading} alt="Loading"
                    style={{height: '25px', width: '86px'}}
                  />
                </div>
                :
                <>
                  <div className="thread-likes hoverable-title"
                    data-hover-text={TextOnly('likes')}
                  >
                    <button className="mb-btn-icon c-btn__inner padding-sides-8" >
                      <span
                        className={`c-btn__icon u-padding-none ${
                          userVotes[threadID]?.userRating === true ? 'fas' : 'fal'
                        } fa-thumbs-up`}
                        style={{
                          cursor: (threadLocal?.deleted || userRole==='READONLY' || userRole==='MUTED') ? 'auto' : 'pointer',
                        }}
                        onClick={() =>
                          (threadLocal?.deleted || userRole==='READONLY' || userRole==='MUTED')
                            ? null
                            : handleVote(threadLocal?.threadID, true, false)
                        }
                      />
                    </button>
                    <div className="mb-count">
                      {threadLocal?.likes}
                    </div>
                  </div>
                  <div className="thread-dislikes hoverable-title"
                    data-hover-text={TextOnly('dislikes')}
                  >
                    <button className="mb-btn-icon c-btn__inner padding-sides-8" >
                      <span
                        className={`c-btn__icon u-padding-none ${
                          userVotes[threadID]?.userRating === false ? 'fas' : 'fal'
                        } fa-thumbs-down`}
                        style={{
                          cursor: (threadLocal?.deleted || userRole==='READONLY' || userRole==='MUTED') ? 'auto' : 'pointer',
                        }}
                        onClick={() =>
                          (threadLocal?.deleted || userRole==='READONLY' || userRole==='MUTED')
                            ? null
                            : handleVote(threadLocal?.threadID, false, false)
                        }
                      />
                    </button>
                    <div className="mb-count">
                      {threadLocal?.dislikes}
                    </div>
                  </div>
                  <div className="thread-report hoverable-title"
                    data-hover-text={TextOnly('report')}
                  >
                    <button
                      className="mb-btn-icon c-btn__inner padding-sides-8"
                      onClick={() =>
                        (threadLocal?.locked || userRole==='READONLY' || userRole==='MUTED')
                          ? null
                          : setShowModalReport(true)} >
                      <span
                        className={`color-red fas fa-exclamation`}
                        style={{
                          cursor: (threadLocal?.locked || userRole==='READONLY' || userRole==='MUTED') ? 'auto' : 'pointer',
                        }}
                      />
                    </button>
                  </div>
                </>
              }
              <div className="thread-posts hoverable-title"
                data-hover-text={TextOnly('comments')}
              >
                <div className="c-btn__inner padding-sides-8" >
                  <span
                    className={`c-btn__icon u-padding-none fal fa-reply-all`}
                  />
                </div>
                <div className="mb-count">
                  {threadLocal?.replies}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='prewrap-breakword'>
          {threadLocal?.deleted ? '**'+TextOnly('contentDeleted')+'**' : threadLocal?.body?.split('\n')?.map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index < threadLocal?.body.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
        <div className="thread-footer">
          <div
            className="thread-time-by"
          >
            {formatDateTime(threadLocal?.createdOn)} by{' '}
            <Link
              to={`/messageBoard/userName/${threadLocal.userName}`}
              className="hoverable-title"
              data-hover-text={threadLocal?.role === 'MODERATOR' ? TextOnly('moderator') : threadLocal?.role === 'ADMIN' ? TextOnly('admin') : ''}
            >
              {threadLocal?.role && (
                <span
                  className={`c-btn__icon threaddet-role fas fa-check${
                    threadLocal?.role === 'ADMIN' ? '-double' : ''
                  }`}
                />
              )}
              {threadLocal?.userName}
            </Link>
          </div>
          {(threadLocal?.userID === userID &&
            !(threadLocal?.deletedBy==='BY_OWNER') ) ? (
            <button
              className="c-btn-icon-compact"
              onClick={() => setShowModalDelete(true)}
            >
              <div className="c-btn__inner hoverable-title"
                data-hover-text={TextOnly('delete')}
              >
                <span
                  className="c-btn__icon color-red fal fa-trash-alt"
                />
              </div>
            </button>
          ) : null}
          {(userRole === 'MODERATOR' || userRole === 'ADMIN') ? (
            <button
              className='red-btn'
              onClick={() => setShowModal(true)}
            >
              <span
                className="c-btn__icon u-padding-none marginright-5px fa fa-gear"
              />{TextOnly('modifyThread')}
            </button>
          ) : null}
        </div>
        { (threadLocal?.userID === userID || userRole === 'MODERATOR' || userRole === 'ADMIN') && (threadLocal.deleteReason) &&
          <>
            <div className='delete-reason'>
              <h1>Delete Reason: <span className='color-aagray'>{threadLocal.deleteReason}</span></h1>
              { (userRole === 'MODERATOR' || userRole === 'ADMIN') &&
                <h2 className='color-aagray'>Deleted by:
                  <Link
                    to={`/messageBoard/userName/${threadLocal?.deletedBy?.split('BY_ADMO_')[1]}`}
                  >
                    {' '+threadLocal?.deletedBy?.split('BY_ADMO_')[1]}
                  </Link>
                </h2>
              }
            </div>
            <button
              className='c-btn'
              onClick={()=>setShowOriginal(true)}
            >{TextOnly('showOriginalContent')}</button>
          </>

        }
        <div className="thread-detail-info">
          <h2
            className="scroll-container display-inline maxwidth-60"
          >
            <span className='color-aa-red'>
              {threadLocal?.tags?.length === 1 ? TextOnly('tag') : TextOnly('tags')}:
            </span>{' '}
            {threadLocal?.tags?.length === 0
              ? ''
              : threadLocal?.tags
                  .map((tag) => {
                    return tag?.name ?? null;
                  })
                  .join(', ')}
          </h2>
        </div>
        {(!threadLocal?.deleted && !threadLocal.locked && userRole!=='READONLY' && userRole!=='MUTED') ? (
          <>
            <LoaderButton
              type="submit"
              isLoading={false}
              disabled={false}
              text={TextOnly(showForm ? 'cancelComment' : 'addComment')}
              loadingText=''
              onClick={() => setShowForm((prev) => !prev)}
              className='white-space-nowrap'
            />
              <div className={`form-thread ${showForm ? 'show' : 'hide'}`}>
                <form className="thread-form display-flex flex-flow-column" onSubmit={handleCreatePost}>
                  <textarea
                    className="text-area-detail maxwidth-100"
                    id="post"
                    name="post"
                    value={postBody}
                    onChange={handleChangePostBody}
                    placeholder={TextOnly('addComment')}
                    rows={5}
                    cols={40}
                    required
                  />
                  <div>
                    <LoaderButton
                      type="submit"
                      isLoading={loadingCreate}
                      disabled={!validatePostBody(postBody)}
                      text={TextOnly('comment')+'!'}
                      loadingText={TextOnly('loading')}
                      />
                  </div>
                </form>
              </div>
            </>
        ) : null}
        <div>
          <ul className="post-list">
            {threadPosts?.length > 0 ? (
              !postID ?
                threadPosts
                  ?.sort((a: Post, b: Post) => {
                    return (
                      new Date(b.createdOn).getTime() -
                      new Date(a.createdOn).getTime()
                    );
                  })
                  ?.map((post: Post) => (
                    <li key={post.postID}>
                      <PostComponent
                        post={post}
                        user={user}
                        userVotes={userVotes}
                        setUserVotes={setUserVotes}
                        currentShop={props.currentShop}
                        handleUpdateThreadPosts={handleUpdateThreadPosts}
                      />
                    </li>
                  ))
              :
                threadPosts
                  ?.sort((a: Post, b: Post) => {
                    if (a.postID === postID) return -1;
                    else if (b.postID === postID) return 1;
                    else return (
                      new Date(b.createdOn).getTime() -
                      new Date(a.createdOn).getTime()
                    );
                  })
                  ?.map((post: Post) => (
                    <li key={post.postID}>
                      <PostComponent
                        post={post}
                        user={user}
                        userVotes={userVotes}
                        setUserVotes={setUserVotes}
                        currentShop={props.currentShop}
                        isNotification={postID===post.postID}
                        handleUpdateThreadPosts={handleUpdateThreadPosts}
                      />
                    </li>
                  ))
            ) : (
              <div className="no-posts-message">
                {TextOnly('threadNoComments')}
              </div>
            )}
          </ul>
        </div>

        <LoaderButton
          type="submit"
          isLoading={false}
          disabled={false}
          text={TextOnly('goBack')}
          loadingText=''
          className="go-back-btn"
          onClick={() => handleGoBack()}
        />
      </div>
      <Dialog
        isOpen={showModalDelete}
        className="c-modal"
        aria-label="confirmDelete"
        onDismiss={() => handleCloseModal()}
      >
        <h1>{TextOnly('confirm')}</h1>
        <h2 className="c-modal__heading">
          {TextOnly('confirmDeleteContent', {content: TextOnly('thread')})}
        </h2>
        { (userRole === 'MODERATOR' || userRole === 'ADMIN') &&
          <div
            className="c-modal__body display-flex-justify-content-center"
          >
            <textarea
              className="text-area-detail maxwidth-100"
              value={deleteReason}
              onChange={handleChangeDeleteReason}
              placeholder={TextOnly('deleteReason')}
              rows={5}
              cols={40}
            />
          </div>
        }
        <div
          className="c-modal__body display-flex-justify-content-center"
        >
          <LoaderButton
            type="submit"
            isLoading={false}
            disabled={loadingDelete}
            text={TextOnly('cancel')}
            loadingText=''
            onClick={() => handleCloseModal()}
          />
          <LoaderButton
            type="submit"
            isLoading={loadingDelete}
            disabled={loadingDelete ||
              ((userRole === 'MODERATOR' || userRole === 'ADMIN') && deleteReason.length===0)}
            text={TextOnly('confirm')}
            loadingText={TextOnly('loading')}
            onClick={() => handleDeleteThread(threadLocal!.threadID)}
            className="confirm-del-thread"
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={showOriginal}
        className="c-modal background-color-transparent"
        aria-label="showOriginal"
        onDismiss={() => setShowOriginal(false)}
      >
        <div
          className={`c-box ${windowWidth < 650 ? 'smaller-screen-padding small-top' :''} ${threadLocal.locked ? 'locked hoverable' : ''}`}
        >
          <div className="l-flex">
            <button
              className="c-btn-icon c-modal-x-close"
              onClick={() => setShowOriginal(false)}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <div className='mb-title color-aa-darkblue'>
              <span className='prewrap-breakword'>
                {threadLocal?.title}
              </span>
            </div>
          </div>
          <div className='prewrap-breakword'>
            {threadLocal?.body?.split('\n')?.map((part, index) => (
              <React.Fragment key={index}>
                {part}
                {index < threadLocal?.body.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
          <div className="thread-detail-info">
            <h2
              className="scroll-container display-inline maxwidth-60"
            >
              <span className='color-aa-red'>
                {threadLocal?.tags?.length === 1 ? TextOnly('tag') : TextOnly('tags')}:
              </span>{' '}
              {threadLocal?.tags?.length === 0
                ? ''
                : threadLocal?.tags
                    .map((tag) => {
                      return tag?.name ?? null;
                    })
                    .join(', ')}
            </h2>
          </div>
          <div className='display-flex-justify-content-space-around'>
            <LoaderButton
              type="submit"
              isLoading={false}
              disabled={false}
              text={TextOnly('close')}
              loadingText=''
              onClick={() => setShowOriginal(false)}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={showConfirmedModal}
        className="c-modal"
        aria-label="confirmedDelete"
      >
        <h2 className="c-modal__heading">{TextOnly('threadDeleted')}</h2>
        <div
          className="c-modal__body display-flex-justify-content-center"
        >
          <LoaderButton
            type="submit"
            isLoading={false}
            disabled={false}
            text={TextOnly('confirm')}
            loadingText=''
            onClick={() => {
              setIsDeleting(false);
              setShowConfirmedModal(false);
              if(threadLocal.replies===0) handleGoBack();
            }}
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={showConfirmedReport}
        className="c-modal"
        aria-label="confirmedReport"
      >
        <h2 className="c-modal__heading">{TextOnly('threadReported')}</h2>
        <div
          className="c-modal__body display-flex-justify-content-center"
        >
          <LoaderButton
            type="submit"
            isLoading={false}
            disabled={false}
            text={TextOnly('confirm')}
            loadingText=''
            onClick={() => setShowConfirmedReport(false)}
          />
        </div>
      </Dialog>
      <Dialog
        isOpen={showModal}
        className="c-modal"
        aria-label="ama"
        onDismiss={()=>setShowModal(false)}
      >
        { isLoading ?
          <div className='height-200px'><Loading /></div> :
          <>
            <button
              className="c-btn-icon c-modal__close-aux"
              onClick={()=>setShowModal(false)}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-times" />
              </div>
            </button>
            <h1 className="c-modal__heading">
              {TextOnly('thread')}:
            </h1>
            <div className="c-modal__body">
              <ThreadComponent
                thread={threadLocal}
                user={user}
                userVote={userVotes[threadLocal.threadID]}
                userRole={props.userRole}
                setIsDeleting={setIsDeleting}
                currentShop={props.currentShop}
                showBody={true}
              />
              <br/>
              <div className='display-flex-justify-content-space-around'>
                <LoaderButton
                  type="button"
                  disabled={!!threadLocal?.deleted}
                  text={TextOnly('delete')}
                  isLoading={false}
                  loadingText=''
                  onClick={() => setShowModalDelete(true)}
                />
                <LoaderButton
                  type="button"
                  text={
                      threadLocal?.blocked !== true ?
                        TextOnly('block')
                        : TextOnly('unblock')
                  }
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleAdmoFunction('BLOCK', !threadLocal?.blocked)}
                />
                <LoaderButton
                  type="button"
                  text={
                    threadLocal?.locked !== true ?
                      TextOnly('lock')
                      : TextOnly('unlock')
                  }
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleAdmoFunction('LOCK', !threadLocal?.locked)}
                />
                {/* <LoaderButton
                  type="button"
                  text={'Tree lock'}
                  isLoading={false}
                  loadingText=''
                  onClick={()=>handleTreeLock()}
                /> */}
              </div>
            </div>
          </>
        }
      </Dialog>
      <Dialog
        isOpen={showModalReport}
        className="c-modal"
        aria-label="report"
        onDismiss={()=>handleCancelReport()}
      >
        <button
          className="c-btn-icon c-modal__close-aux"
          onClick={()=>handleCancelReport()}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fal fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          {TextOnly('report')}:
        </h1>
        <div className={`c-modal__body paddingtop-0 ${isLoadingVote ? 'height-200px' :''}`}
        >
        { (userVotes[threadID]?.reportReason?.length > 0) ?
        <>
        <h2>{TextOnly('postAlreadyReported')}</h2>
        <div className='display-flex-justify-content-space-around u-width-100-percent alignitems-baseline'>
          <div>
            <LoaderButton
              isLoading={false}
              disabled={false}
              text={TextOnly('confirm')}
              loadingText=''
              onClick={(e)=>{
                e.preventDefault()
                handleCancelReport()
              }}
            />
          </div>
        </div>
        </>
        : isLoadingVote ? <Loading/> :
          <>
            <h2>{TextOnly('contentReportReason', {content: TextOnly('thread')})}</h2>
            <form className="thread-form margintop-0 paddingtop-0" onSubmit={()=>null}>
              { !validateReportCheckboxes(checkboxes) &&
                <div className='c-field__error marginbottom-10'>
                  <span>* {TextOnly('selectOneCheckboxError')}</span>
                </div>
              }
              <div
                className='c-field'
              >
                {checkboxes.map((checkbox, index) => (
                  <div key={index}>
                    <label className="tag-checkbox mb-label">
                      <input
                        type="checkbox"
                        checked={checkbox.checked}
                        onChange={() => handleCheckboxChange(index)}
                        style={{ marginRight: '10px', width: '18x', height: '18x' }}
                      />
                      {checkbox.label}
                    </label>
                  </div>
                ))}
              </div>
              { !validateReportBody(reportBody) &&
                <div className='c-field__error marginbottom-10'>
                  <span>* {TextOnly('reportValidateError', {num: REPORT_BODY_MIN_LENGTH.toString()})}</span>
                </div>
              }
              <div
                className='c-field'
              >
                <textarea
                  className={`text-area-detail c-input ${validateReportBody(reportBody) ? 'error' : ''}`}
                  id="body"
                  name="body"
                  placeholder={TextOnly('describeReport')}
                  value={reportBody}
                  onChange={handleChangeReportBody}
                  rows={5}
                  cols={40}
                  required
                />
              </div>
              <div className='display-flex-justify-content-space-around u-width-100-percent alignitems-baseline'>
                <div>
                  <LoaderButton
                    isLoading={false}
                    disabled={!validateReportBody(reportBody) || !validateReportCheckboxes(checkboxes)}
                    text={TextOnly('report')}
                    loadingText=''
                    onClick={(e: any) => {
                      e.preventDefault()
                      handleVote(threadLocal?.threadID, false, true)
                    }}
                    className='red-btn'
                  />
                </div>
                <div>
                  <LoaderButton
                    isLoading={false}
                    disabled={false}
                    text={TextOnly('cancel')}
                    loadingText=''
                    onClick={(e)=>{
                      e.preventDefault()
                      handleCancelReport()
                    }}
                  />
                </div>
              </div>
            </form>
          </>
        }
        </div>
      </Dialog>
      <OffensiveContentModal {...{showOffensiveModal, setShowOffensiveModal}}/>
    </>
  );
};

export default ThreadDetail;
